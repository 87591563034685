import { greenColor } from '../../../../../assets/jss/material-dashboard-pro-react';

const loginPageStyles = {
  container: {
    height: '100vh',
  },
  formCard: {
    backgroundColor: 'white',
    padding: '3rem',
    borderRadius: '0.5rem',
    marginBottom: '1.5rem',
  },
  form: {
    width: '100%',
  },
  logoImg: {
    width: '10rem',
  },
  loginBtn: {
    marginTop: '1.5rem',
    marginBottom: '1rem',
    padding: '0.8rem 3rem',
    backgroundColor: 'red',
  },
  subText: {
    textAlign: 'left',
    color: greenColor[3],
  },
  snackbarContainer: {
    width: '100%',
  },
};

export default loginPageStyles;
