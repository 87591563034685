import React,{useState,useEffect} from 'react'
import { useAlert } from 'react-alert';
import DataTableContainerComponent from '../../../components/DataTableSortingContainer.component';
import { getSpecifications,updateSpecification } from '../services/serverAPI.service'
import CreateSpecification from '../components/CreateSpecification'
import SwitchComponent from '../../../components/Switch.component'

import GridContainer from '../../../components/material-dashboard-pro-react/Grid/GridContainer';
import Card from '../../../components/material-dashboard-pro-react/Card/Card';
import CardHeader from '../../../components/material-dashboard-pro-react/Card/CardHeader';
import CardBody from '../../../components/material-dashboard-pro-react/Card/CardBody';
import Button from '../../../components/material-dashboard-pro-react/CustomButtons/Button';
import { Grid, Box, Divider } from '@material-ui/core'

export default function Specification() {
    const alert = useAlert();
    const id = localStorage.getItem('storeId')
    const [resultsData, setResultsData] = useState({
        total: 0,
        items: [],
        tableItems: [],
    });
    const [isLoading, setIsLoading] = useState(false);
    const [page, setPage] = useState(0)
    let numberOfRows = [10, 50, 100];
    const [rowsPerPage, setRowsPerPage] = useState(numberOfRows[0])

    const updateSpecificationFromSwitch=(key,value, item)=>{
        item[key] = value,
        updateSpecification(item)
            .then(() => {
                alert.show('specification updated', {
                    type: 'success',
                });
                loadCategoryList()
            })
            .catch((error) => {
                alert.show(error.message, {
                    type: 'error',
                });
            })
    }
    const loadSpecificationList = (skip,limit) => {
        setIsLoading(true);
        getSpecifications(id,skip,limit)
            .then(({ data }) => {
                setResultsData({
                    total: data.total,
                    items: data.specifications.map((item, index) => {
                        return {
                            ...item,
                            name: item.name||'--',
                            type: item.type?item.type:'--',
                            priceconfig: item.priceconfig?item.priceconfig:'--',
                            maxChoices: item.maxChoices?item.maxChoices:'--',
                            open: 
                                <SwitchComponent
                                onChange={(e,val)=>console.log()}
                                // onChange={(value) => updateSpecificationFromSwitch('open',value, item)}
                                value="checked"
                                checked={item.open}
                                label="open"
                                />,
                            required: 
                                <SwitchComponent
                                onChange={(e,val)=>console.log()}
                                // onChange={(value) => updateSpecificationFromSwitch('required',value, item)}
                                value="checked"
                                checked={item.required}
                                label="required"
                                />,
                            list:item.list.length > 0&&item.list.map(itemFromList=>(
                                <p>{itemFromList.name}( {itemFromList.price} dh)</p>
                            ))
                            

                        };
                    }),
                    
                });
            })
            .catch(error => console.log(error))
            .finally(() => {
                setIsLoading(false);
            });
    };
    const handlePaginate = (val) => {
        console.log(val,'val');
        console.log(val.pageSelect, val.numberOfRows);
        setResultsData({
          items: [],
          tableItems: [],
        });
        loadSpecificationList(val.pageSelect, val.numberOfRows);
      };
    useEffect(() => {
        loadSpecificationList(page, rowsPerPage)
    },[page, rowsPerPage])
    return (
        <GridContainer className='bordGlobalContainer'>
            <Card>
                <CardHeader className='bordCardTableHeader'>
                    <div className='bordTableTitleDivider'>
                        <span className='bordStoreTableTitle'>
                            Specifications List
                        </span>
                        <Divider className='lineTitle' />
                    </div>
                </CardHeader>
                <CardBody>
                <DataTableContainerComponent
                total={resultsData.total}
                numberOfRowsProps={numberOfRows}
                resultsData={resultsData}
                initData={resultsData.items}
                onPaginate={handlePaginate}
                isPaginate={true}
                page={page + 1}
                columns={[
                    {
                        Header: 'Name',
                        accessor: 'name',
                    },
                    {
                        Header: 'Type',
                        accessor: 'type',
                    },
                    {
                        Header: 'Price config',
                        accessor: 'priceconfig',
                    },
                    {
                        Header: 'Max Choice',
                        accessor: 'maxChoices',
                    },
                    {
                        Header: 'List',
                        accessor: 'list',
                    },
                ]}
                // title="Specifications List"
                isLoading={isLoading}
                loadItemsList={loadSpecificationList}
                // CreateItemFormComponent={CreateSpecification}
                />
                </CardBody>
            </Card>
        </GridContainer>
    )
}
