export function generateRandomString(length) {
  let result = '';
  const characters =
    'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
  const charactersLength = characters.length;
  for (let i = 0; i < length; i += 1) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
  }
  return result;
}

export function formatTime(time){
  time = new Date(time)
  const timeFormat = `${time.getDate()}/${(time.getMonth()+1)}/${time.getFullYear()} (${time.getHours()}:${time.getMinutes()})`
  return timeFormat
}
export function formatTimeForOrder(time){
  time = new Date(time)
  // const timeFormat = `${time.getHours()}:${time.getMinutes()}:${time.getSeconds()}(${time.getDate()}/${(time.getMonth()+1)})`
  const timeFormat = `${time.getHours()}:${time.getMinutes()}`
  return timeFormat
}
export function calcDuration(time){
  var  diff;
  // setInterval(() => {
    // calcDuration(item.createdAt)
  
  // time = new Date(time)
  time = new Date(time).getTime()
  // console.log(time,new Date(time));
  const timeNow = new Date().getTime();
  // console.log(timeNow,new Date(timeNow));
   diff = Math.ceil(((timeNow - time)/1000)/60)
  // console.log(diff);
  // con
  // console.log(diff.);
  // const timeFormat = `${time.getHours()}:${time.getMinutes()}:${time.getSeconds()}(${time.getDate()}/${(time.getMonth()+1)})`
  // const timeFormat =  (${time.getHours()}:${time.getMinutes()}:${time.getSeconds()})</span><span> ${time.getDate()}/${(time.getMonth()+1)}</span>
// }, 60000)
return diff
}
