import React,{useEffect, useState} from 'react'
import PropTypes from 'prop-types'
import { useSelector, useDispatch } from 'react-redux';
import {useAlert} from 'react-alert'
import {useFormik} from 'formik'
import * as yup from 'yup'
import {Box} from '@material-ui/core'
import Switch from '@material-ui/core/Switch';
import Button from '../../../components/material-dashboard-pro-react/CustomButtons/Button'
import CustomInput from '../../../components/material-dashboard-pro-react/CustomInput/CustomInput'
import {defaultImagesAction} from '../../../redux/uploadRedux/upload.action'
import SwitchComponent from '../../../components/Switch.component'
import ImageUpload from '../../../components/material-dashboard-pro-react/CustomUpload/ImageUpload';
import {updateItem,getSpecification,getCategory} from '../services/serverAPI.service'
import SelectBoxComponent from '../../../components/SelectBox.component';

const validationSchema = yup.object({
    
    price: yup.number().typeError('value is number').required(),
   
});
export default function EditePriceItem({data,index,close,onItemEdited,categoryList,specifications}) {
    const alert = useAlert();
    // console.log(props);
    const dataProps = data
    // console.log(resultsData);
  const storeId = localStorage.getItem('storeId')
  const [isLoading, setIsLoading] = useState(false);
  const [specification, setSpecification] = useState([]);
    const [products, setProducts] = useState([]);
  const formik = useFormik({
    initialValues: {
        price:'',    
    },
    validationSchema,
});

const setValue=()=>{
    if (data) {
        formik.setValues({
            price:data?.price,
      })}
    
  }
// console.log(formik.values);
  const onEdit=()=>{
    data.price = formik.values.price
    setIsLoading(true);
    updateItem(data)
    
        .then(({data}) => {
            // console.log(data);
            alert.show('item updated', {
                type: 'success',
            });
            close()
            onItemEdited(0, 10)
            categoryList()
            specifications()
        })
        .catch((error) => {
            alert.show(error.message, {
                type: 'error',
            });
        })
        .finally(() => {
            setIsLoading(false)
        })
}

useEffect(() => {
    setValue()
    
},[data])
    return (
        <div>
     
            <CustomInput
                labelText="price"
                formControlProps={{
                    fullWidth: true,
                }}
                type="text"
                error={formik.touched.price && Boolean(formik.errors.price)}
                inputProps={{
                    value: formik.values.price,
                    onBlur: formik.handleBlur('price'),
                    onChange: formik.handleChange('price'),
                }}
            />
           
            <Box textAlign="center">
                <Button
                    round
                    type="submit"
                    color="success"
                    disabled={!formik.dirty || !formik.isValid || isLoading}
                    loading={isLoading}
                    loaderColor="white"
                    onClick={onEdit}
                >
                    Edit Price
                </Button>
            </Box>
        </div>
    )
}
EditePriceItem.propTypes = {
    data: PropTypes.any,
    index: PropTypes.any,
    close: PropTypes.any,
    onItemEdited: PropTypes.func.isRequired,
}
