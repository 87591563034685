import React, { useState } from 'react'
import { useSelector, useDispatch } from 'react-redux';
import * as yup from 'yup';
import { useFormik } from 'formik';
import { Box } from '@material-ui/core';
import PropTypes from 'prop-types';
import { useAlert } from 'react-alert';

import ImageUpload from '../../../components/material-dashboard-pro-react/CustomUpload/ImageUpload'
import CustomInput from '../../../components/material-dashboard-pro-react/CustomInput/CustomInput'
import Button from '../../../components/material-dashboard-pro-react/CustomButtons/Button'
import SwitchComponent from '../../../components/Switch.component'

import { createCategoryList } from '../services/serverAPI.service'

const validationSchema = yup.object({
  name: yup.string().required(),
  description: yup.string().required(),
  storeId: yup.string().required(),
  inStock: yup.bool().required(),
  imageUrl: yup.string(),
});

export default function CreateCategoryList({ onItemCreated, close }) {
  const alert = useAlert();
  const storeId = localStorage.getItem('storeId')
  const upload = useSelector((state) => state.uploadReducer)
  const { iconUrl, error, loading } = upload
  // console.log(iconUrl, error, loading);
  const [isLoading, setIsLoading] = useState(false);
  const formik = useFormik({
    initialValues: {
      name: '',
      description: '',
      storeId: storeId,
      inStock: true,
      imageUrl: '',
    },
    validationSchema,
  });

  const onCreate = () => {
    setIsLoading(true);
    iconUrl && formik.setFieldValue('imageUrl', iconUrl)
    Object.values(formik.values).every(x => x != "") ?
      createCategoryList(formik.values)
        .then(() => {
          alert.show('Category List has been created', {
            type: 'success',
          });
          formik.resetForm();
          close()
          onItemCreated();
        })
        .catch((error) => {
          alert.show(error.message, {
            type: 'error',
          });
        })
        .finally(() => {
          setIsLoading(false);
        })
      :
      setIsLoading(false)
      


  };
  return (
    <div>
      <Box display="flex" justifyContent="center">
        <ImageUpload
          label="Select icon"
          folder="service"
          avatar
        />
      </Box>

      <CustomInput
        labelText="Name"
        formControlProps={{
          fullWidth: true,
        }}
        type="text"
        error={formik.touched.name && Boolean(formik.errors.name)}
        inputProps={{
          value: formik.values.name,
          onBlur: formik.handleBlur('name'),
          onChange: formik.handleChange('name'),
        }}
      />
      <CustomInput
        labelText="Description"
        formControlProps={{
          fullWidth: true,
        }}
        type="text"
        error={formik.touched.description && Boolean(formik.errors.description)}
        inputProps={{
          value: formik.values.description,
          onBlur: formik.handleBlur('description'),
          onChange: formik.handleChange('description'),
        }}
      />
      <SwitchComponent
        onChange={(value) => formik.setFieldValue('inStock', value)}
        checked={formik.values.inStock}
        error={formik.touched.inStock && Boolean(formik.errors.inStock)}
        label="inStock"
      />
      <Box textAlign="center">
        <Button
          round
          type="submit"
          color="success"
          disabled={!formik.dirty || !formik.isValid || isLoading}
          loading={isLoading}
          loaderColor="white"
          onClick={onCreate}
        >
          Create
        </Button>
      </Box>
    </div>
  )
}

CreateCategoryList.propTypes = {
  onItemCreated: PropTypes.func.isRequired,
  close: PropTypes.func.isRequired,
};

