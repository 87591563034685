import { SET_ADMIN_DATA } from '../actions/auth.action';

const initialState = {
  adminData: null,
};

export default function authReducer(state = initialState, action) {
  switch (action.type) {
    case SET_ADMIN_DATA:
      return {
        ...state,
        adminData: action.payload,
      };
    default:
      return state;
  }
}
