import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';

import { Grid, Box, Divider } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';

import GridContainer from '../../../components/material-dashboard-pro-react/Grid/GridContainer';
import Card from '../../../components/material-dashboard-pro-react/Card/Card';
import CardHeader from '../../../components/material-dashboard-pro-react/Card/CardHeader';
import CardBody from '../../../components/material-dashboard-pro-react/Card/CardBody';
import Button from '../../../components/material-dashboard-pro-react/CustomButtons/Button'
import StoreEditPreparationComponent from '../components/StorePreparation.component'
import ModalComponent from '../../../components/Modal.component';
import { daysName } from '../mock/index'

import { getStoreRequestAction } from '../redux/Actions/store.config.actions';
import { getStoreState } from '../redux/selectore'
import { getTag } from '../services/serverAPI.service'
import imageStore from '../../../assets/img/logo-resto.png';
import StoreEditPreparationComponentMoyenne  from '../components/StorePreparationMoyenne.component'

const useconfigStoreStyles = makeStyles({
  storeName: {
    fontSize: '18px',
    marginTop: '20px',

  },
  imgBox: {
    padding: '10px'
  },
  aBox: {
    // display: 'inline-block',
    // width: '240px',
    // textAlign: 'center',
  },

  imgContainer: {
    //   height: "230px",
    //   height: "130px",
    //   width: "100px",
    //   width: "200px",
    //   overflow: 'hidden',
    //   borderRadius: '0px 0px 20px 20px',
    //   borderRadius: '50%',
    padding: '10px 0 0 0',
    display: 'inline-block',
  },
  imgContainerImg: {
    //   transform: 'skew(0deg, -13deg)',
    //   height: '250px',
    //   margin: '-35px 0px 0px -70px',
    height: '120px',
    width: '120px',
    borderRadius: '50%'
  },

  innerSkew: {
    //   display: 'inline-block',
    //   borderRadius: "20px",
    //   borderRadius: "20%",
    //   overflow: 'hidden',
    //   padding: '0px',
    // //   transform: 'skew(0deg, 13deg)',
    //   fontSize: '0px',
    //   margin: '30px 0px 0px 0px',
    //   background: '#c8c2c2',
    //   height: '250px',
    //   width: '200px',
  },
  textContainer: {
    // boxShadow: '0px 0px 10px 0px rgba(0,0,0,0.2)',
    padding: ' 120px 20px 20px 20px',
    borderRadius: '20px',
    background: '#fff',
    margin: '-120px 0px 0px 0px',
    lineHeight: '19px',
    fontSize: ' 14px',
  },

  textContainerH3: {
    margin: '20px 0px 10px 0px',
    color: '#04bcff',
    color: 'grey',
    fontSize: "18px",
  },
  timming: {
    borderTop: '1px solid grey',
    paddingTop: '10px',
  },
  divider: {
    width: '7%',
    borderBottom: '1px solid #4caf50',
  },
  textTiming: {
    color: 'grey',
    fontSize: "16px",
  }

});


export default function StoreConfig() {
  const classes = useconfigStoreStyles();
  const storeId = localStorage.getItem('storeId');
  const dispatch = useDispatch()
  const store = useSelector(getStoreState);
  // console.log('store selectore', store);
  const [tags, setTags] = useState([])
  const [openModal, setOpenModal] = useState('');

  const handleOpenModal = (type) => {
    // console.log('type');

    setOpenModal(type);
  };
  const closeModal = () => {
    setOpenModal('');
  };
  useEffect(() => {
    dispatch(getStoreRequestAction(storeId))
    getTag()
      .then(({ data }) => setTags(data))
      .catch((error) => console.log(error))
  }, [])
  // console.log(tags);
  return (
    <GridContainer className='bordGlobalContainer'>
      {store.store &&
      <Card>
        <CardHeader className='bordCardTableHeader'>
          <div className='bordTableTitleDivider'>
            <span className='bordStoreTableTitle'>
              Store Config
            </span>
            <Divider className='lineTitle' />
          </div>
        </CardHeader>
        <CardBody>
          <Card>
            <CardBody>
              <div className='storeConfigHeader'>
                <div>
                  <div className='storeInfo'>
                    <img 
                    className='storeImageConfig'
                    src={store?.store?.imageUrl}
                    />
                    <div className='storeInfoDetails'>
                      <span className='storeConfigName'>
                        {store?.store?.name}
                      </span>
                      <span className='storeConfigAdd'>
                        {store?.store?.address}
                      </span>
                    </div>
                  </div>
                </div>
                <div className='storeConfigStatus'>
                  <Divider orientation='vertical' className='verticalLine' />
                  <Button fullWidth className='storeButtonStatus' color={store.store.open ? "success" : 'google'}>{store.store.open ? "open" : 'close'}</Button>
                </div>
              </div>
            </CardBody>
          </Card>
        </CardBody>
        <div className='storeConfigSecond'>
          <div className='storeConfigSecondHours'>
            <CardHeader>
              <span className='storeConfigSubTitle'>Jours et heurs d'ouverture</span>
            </CardHeader>
            <CardBody>
              {/* <Card> */}
              <div style={{
                display: 'flex',
                flexDirection: 'column',
              }}>
                <Box 
                className='storeBoxDays' direction='column' component={Grid} boxShadow={3}  item xs={12} container spacing={1} >
                {store.store.timing && store.store.timing.map((day, index) => (
                  <Grid item xs={12}>
                    <h3 className={classes.textContainerH3}>{daysName[index]}</h3>
                    <span className={classes.textTiming}>de {day.day_time[0]?.openTime} à {day.day_time[0]?.closeTime}</span>
                  </Grid>
                ))}
              </Box>
                </div>
              {/* </Card> */}
            </CardBody>
          </div>
          <div className='storeConfigSecondTime'>
          <CardHeader>
              <span className='storeConfigSubTitle'>Temps moyenne </span>
            </CardHeader>
            <CardBody>
              <div className='storeTimePreparation'>
                <Box className='storeBoxHours' direction='column' component={Grid} boxShadow={3}  item xs={12} container >
                  <Grid item xs={12} className='storeBoxHoursGrid'>
                    <span className='tempsPreparation'>
                      Temps moyenne {store.store.meanPreparationTime?store.store.meanPreparationTime:'--'} 
                    </span>
                    <Button
                    className='storeButtonEditTime'
                    onClick={() => {
                      handleOpenModal('preparationMoyenneModal')
                    }}
                   >modifier</Button>
                  </Grid>
                </Box>
              </div>
            </CardBody>
            <CardHeader>
              <span className='storeConfigSubTitle'>Temps de preparation</span>
            </CardHeader>
            <CardBody>
              <div className='storeTimePreparation'>
                <Box className='storeBoxHours' direction='column' component={Grid} boxShadow={3}  item xs={12} container >
                  <Grid item xs={12} className='storeBoxHoursGrid'>
                    <span className='tempsPreparation'>
                      Temps de preparation entre {store.store.preparationTimeMin} et {store.store.preparationTimeMax} min {store.store.preparation}
                    </span>
                    <Button
                    className='storeButtonEditTime'
                    onClick={() => {
                      handleOpenModal('preparationModal')
                    }}
                   >modifier</Button>
                  </Grid>
                </Box>
              </div>
            </CardBody>
          </div>
        </div>
        <div>
          <CardHeader>
            <span className='storeConfigSubTitle'>Mes tags</span>
          </CardHeader>
          <CardBody>
            <div>
              <Box component={Grid}  item xs={12} boxShadow={3} className='storeTagBox'>
                {store&&tags && tags.length > 0 && tags.map((item) => (
                  item.tags.length > 0 && item.tags.map((tag) => (
                    <>
                      <button className='storeTagButton'
                      style={{
                        backgroundColor: store.store.tags.includes(tag.name) ? '#28B873' : '#fff',
                        color: store.store.tags.includes(tag.name) ? '#fff' : '#28B873',
                      }}
                      >
                        {store.store.tags.includes(tag.name) 
                        // && <CheckCircleIcon />
                        }{tag.name}
                      </button>
                    </>
                  ))
                ))}
              </Box>
            </div>
          </CardBody>
        </div>
      </Card>
      }

      {/*  */}
      {/* {store.store &&
        <Card>
          <CardHeader>

            Store Config
          </CardHeader>
          <CardBody>
            <Grid container alignItems="stretch" spacing={3}>
              <Grid container spacing={2} direction="row"
                justifyContent="center"
              >
                <Grid item xs={12} md={12}>
                  <Button fullWidth color={store.store.open ? "success" : 'google'}>{store.store.open ? "open" : 'close'}</Button>
                </Grid>
                <Box component={Grid}  item md={7} xs={12} boxShadow={3}>
                  <Box >
                    <h3 className={classes.textContainerH3}>Mes Tags :</h3>
                    <div className={classes.divider}></div>
                  </Box >
                  <Box >
                    {store&&tags && tags.length > 0 && tags.map((item) => (
                      item.tags.length > 0 && item.tags.map((tag) => (
                        <Button size="sm" color={(store.store.tags||[]).includes(tag.name) && "info"}>
                          {store.store.tags.includes(tag.name) && <CheckCircleIcon />}{tag.name}
                        </Button>
                      ))

                    ))}
                  </Box >
                </Box> 
                <Grid item md={0.5}></Grid>
                <Box component={Grid} item xs={12} md={4} boxShadow={3} textAlign="center">
                    <div className="a-box">
                      <div className={classes.imgContainer}>
                        <div className={classes.imgInner}>
                          <div className={classes.innerSkew}>
                            <img src={store.store.imageUrl} className={classes.imgContainerImg} />
                          </div>
                        </div>
                      </div>
                      <div className={classes.textContainer}>
                        <h3 className={classes.textContainerH3}>{store.store.name}</h3>
                        <div className={classes.timming}>
                          Temps de preparation entre {store.store.preparationTimeMin} et {store.store.preparationTimeMax} min {store.store.preparation}<br />
                          <Box textAlign="center">
                            <Button
                              round
                              type="submit"
                              color="success"
                              loaderColor="white"
                              onClick={() => {
                                handleOpenModal('preparationModal');
                              }}
                            >
                              modifier
                            </Button>
                          </Box>
                        </div>
                      </div>
                    </div>
                </Box>
              </Grid>
              <Box component={Grid} boxShadow={3}  item xs={12} container spacing={1} style={{ marginTop: '40px' }}>
                {store.store.timing && store.store.timing.map((day, index) => (
                  <Grid item xs={3}>
                    <h3 className={classes.textContainerH3}>{daysName[index]}</h3>
                    <div className={classes.divider}></div>
                    <span className={classes.textTiming}>de {day.day_time[0].openTime} à {day.day_time[0].closeTime}</span>
                  </Grid>
                ))}
              </Box>
            </Grid>
          </CardBody>
        </Card>} */}
      {openModal && (
        <ModalComponent
          maxWidth={'sm'}
          close={closeModal}
          visible={openModal === 'preparationModal'}
          title="preparation"
        >
          <StoreEditPreparationComponent
            store={store.store}
            close={closeModal}
          />
        </ModalComponent>
      )}
       {openModal && (
        <ModalComponent
          maxWidth={'sm'}
          close={closeModal}
          visible={openModal === 'preparationMoyenneModal'}
          title="preparation"
        >
          <StoreEditPreparationComponentMoyenne
            store={store.store}
            close={closeModal}
          />
        </ModalComponent>
      )}
    </GridContainer>
  )
}
