import React, { useEffect, useState } from 'react';

import { useSelector, useDispatch } from 'react-redux';

import { getOrderRequestAction } from '../redux/actions/order.actions';
import { getOrdersDataState } from '../redux/selectors';

import {
  Grid,
  Table,
  TableBody,
  TableContainer,
  TableRow,
  TableHead,
  TableCell,
  Collapse,
  Box,
} from '@material-ui/core';

import DataTableContainerComponent from '../../../components/DataTableSortingContainer.component';
import GridContainer from '../../../components/material-dashboard-pro-react/Grid/GridContainer';
import Card from '../../../components/material-dashboard-pro-react/Card/Card';
import CardHeader from '../../../components/material-dashboard-pro-react/Card/CardHeader';
import CardBody from '../../../components/material-dashboard-pro-react/Card/CardBody';
import ChangeDurationComponents from '../components/ChangeDuration.components';
import EndPreparationComponent from '../components/EndPreparation.component';
import ModalComponent from '../../../components/Modal.component';
import {
  formatTimeForOrder,
  calcDuration,
  formatTime,
} from '../../../helpers/index';
// import { ToastContainer, toast } from 'react-toastify';
// import 'react-toastify/dist/ReactToastify.css';
import { Warning } from '@material-ui/icons';
const style = {
  titleColor: {
    color: 'balck',
    fontWeight: 'bold',
    fontSize: '15px',
    textAlign: 'center',
  },
};
export default function orderInProgressPage() {
  const orders = useSelector(getOrdersDataState);
  const { acceptedOrders, error, loading } = orders;
  const [resultsData, setResultsData] = useState({
    items: [],
  });
  const [order, setOrder] = useState([]);
  const [indexStore, setIndexStore] = useState(-1);
  const [openModal, setOpenModal] = useState('');
  const [openCollapse, setOpenCollapse] = useState({});

  // toast.configure();
  // console.log(resultsData);
  // const storeId= localStorage.getItem('storeId')
  // const cityId= localStorage.getItem('cityId')
  useEffect(() => {
    let newState = { ...openCollapse };
    acceptedOrders?.map((item, index) => (newState[index] = false));
    setOpenCollapse(newState);
  }, []);
  const setOrderToState = (data) => {
    data &&
      setResultsData({
        // total: data.total,
        items: data.map((item, index) => {
          return {
            ...item,
            time: (
              <div>
                <span>{formatTimeForOrder(item.createdAt)}</span>
                <br />
                <span>{calcDuration(item.createdAt)} min</span>
              </div>
            ),
          };
        }),
      });
  };
  useEffect(() => {
    // dispatch(getOrderRequestAction(cityId,storeId))
    // console.log(orders?.acceptedOrders);
    acceptedOrders && setOrderToState(acceptedOrders);
    // toast("Order ID 12341234 est annulée", {
    //     position: 'top-right',
    //     style: {
    //         backgroundColor: 'red',
    //         color: 'white',
    //     }
    // })
    // toast.warning('Order ID 12341234 est annulée', {
    //     position: "top-right",
    //     autoClose: 5000,
    //     hideProgressBar: false,
    //     closeOnClick: true,
    //     pauseOnHover: true,
    //     draggable: true,
    //     progress: undefined,
    // });
  }, []);
  // console.log(loading);
  const handleOpenModal = (item, index, type) => {
    setOrder(item);
    setIndexStore(index);
    setOpenModal(type);
  };
  const closeModal = () => {
    setOpenModal('');
  };

  return (
    <GridContainer className="bordGlobalContainer">
      <Grid container className="bordHeader">
        <span className="bordPageTitle">Encours de préparation</span>
        {/* <div className="bordToastNotification">
          <Warning
            style={{
              fontSize: '40px',
              margin: '0px 10px 0px 0px',
            }}
          />
          <span className="bordToastSpanNotification">
            Order ID 12341234 est annulée
          </span>
        </div> */}
        {/* <ToastContainer
                position="top-left"
                autoClose={5000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
                /> */}
      </Grid>
      <Card>
        <CardBody className="bordCardBody">
          <TableContainer
            style={{
              maxHeight: '500px',
              overflowY: 'auto',
            }}
          >
            <Table
              sx={{ minWidth: 650 }}
              aria-label="simple table"
              stickyHeader
              style={{
                border: 'none',
                borderCollapse: 'separate',
                borderSpacing: '0px 10px',
                maxHeight: '100px',
                overflowY: 'auto',
              }}
            >
              <TableHead>
                <TableRow>
                  <TableCell className="bordTableCell" align="center">
                    Transaction ID
                  </TableCell>
                  <TableCell className="bordTableCell" align="center">
                    Total de la commande
                  </TableCell>
                  <TableCell className="bordTableCell" align="center">
                    Prix apres commission
                  </TableCell>
                  <TableCell className="bordTableCell" align="center">
                    Commission
                  </TableCell>
                  <TableCell className="bordTableCell" align="center">
                    Date
                  </TableCell>
                  <TableCell className="bordTableCell" align="center">
                    Paiement
                  </TableCell>
                  <TableCell className="bordTableCell" align="center">
                    Détails
                  </TableCell>
                  <TableCell className="bordTableCell" align="center">
                    Action
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {acceptedOrders &&
                  acceptedOrders.length > 0 &&
                  acceptedOrders.map((order, index) => (
                    <TableRow
                      className="rowTableCellWait"
                      style={{
                        backgroundColor: '#ffc179',
                        height: '80px',
                      }}
                      key={order?.name}
                      sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                    >
                      <TableCell
                        align="center"
                        className="bordTableCell"
                        component="th"
                        scope="row"
                      >
                        {order.shortId}
                      </TableCell>
                      <TableCell align="center" className="bordTableCell">
                        {order?.payment?.originalOrderPrice} Dhs
                      </TableCell>
                      <TableCell align="center" className="bordTableCell">
                        {order?.payment?.storeCommissionType === 'SOURCE'
                          ? (
                              order?.payment?.originalOrderPrice -
                              (order?.payment?.originalOrderPrice *
                                order?.payment?.storeCommission) /
                                100
                            ).toFixed(2)
                          : (order?.payment?.originalOrderPrice).toFixed(
                              2,
                            )}{' '}
                        Dhs
                      </TableCell>
                      <TableCell>
                        {(
                          (order?.payment?.originalOrderPrice *
                            order?.payment?.storeCommission) /
                          100
                        ).toFixed(2)}{' '}
                        Dhs
                      </TableCell>
                      <TableCell align="center" className="bordTableCell">
                        {formatTimeForOrder(order?.createdAt)} /{' '}
                        <ChangeDurationComponents time={order?.createdAt} />
                      </TableCell>
                      <TableCell
                        style={{
                          color:
                            order?.payment?.shouldDeliveryManPayStore === true
                              ? '#2bbd77'
                              : 'red',
                          fontSize: 'medium',
                        }}
                        className="bordTableCell"
                        align="center"
                      >
                        {/* {order?.payment?.shouldDeliveryManPayStore === true
                          ? 'à Encaisser'
                          : 'Ne Pas Encaisse'} */}
                        {(order.payment?.payments || []).find(
                          (x) => x.gateway == 'Card' && x.amount > 0,
                        )
                          ? 'Card'
                          : 'Cash'}
                      </TableCell>
                      <TableCell align="center" className="bordTableCell">
                        <Collapse
                          in={openCollapse[index]}
                          timeout="auto"
                          unmountOnExit
                        >
                          <div>
                            <Grid item xs={12} container>
                              {order?.payment?.cartDetails.length > 0 &&
                                order?.payment?.cartDetails.map((element) => (
                                  <Grid item xs={12} container spacing={2}>
                                    <Grid
                                      item
                                      xs={12}
                                      style={{ ...style.titleColor }}
                                    >
                                      {element.name}
                                    </Grid>
                                    <Grid item xs={12}>
                                      {element.items.length > 0 &&
                                        element.items.map((item) => (
                                          <Box
                                            display="flex"
                                            justifyContent="center"
                                          >
                                            <Grid
                                              container
                                              className="contentinProcess"
                                              spacing={2}
                                              style={{
                                                display: 'flex',
                                                alignItems: 'center',
                                              }}
                                            >
                                              <Grid
                                                item
                                                xs={12}
                                                container
                                                style={{
                                                  display: 'flex',
                                                  alignItems: 'center',
                                                }}
                                                className="mt-3"
                                                spacing={2}
                                              >
                                                <Grid
                                                  item
                                                  xs={6}
                                                  style={{
                                                    ...style.titleColor,
                                                    marginLeft: '20px',
                                                  }}
                                                >
                                                  {`${item.name} ( x ${item.quantity} )`}
                                                </Grid>
                                                <Grid item xs={2}>
                                                  <div
                                                    style={{
                                                      borderBottom:
                                                        '1px solid black',
                                                      width: '100%',
                                                    }}
                                                  ></div>
                                                </Grid>
                                                <Grid
                                                  item
                                                  xs={3}
                                                  style={{
                                                    ...style.titleColor,
                                                  }}
                                                >
                                                  <strong>
                                                    {item.itemTotalPrice +
                                                      ' dh'}
                                                  </strong>
                                                </Grid>

                                                <Grid
                                                  item
                                                  xs={12}
                                                  container
                                                  spacing={2}
                                                >
                                                  <Grid
                                                    item
                                                    xs={12}
                                                    style={{
                                                      ...style.titleColor,
                                                      marginBottom: '14px',
                                                      color: 'red',
                                                    }}
                                                  >
                                                    {item?.note == ''
                                                      ? ''
                                                      : `Notes ----------- ${item.note}`}
                                                  </Grid>
                                                </Grid>
                                              </Grid>
                                              <Grid container>
                                                {item.specifications.length >
                                                  0 &&
                                                  item.specifications.map(
                                                    (specification) => (
                                                      <Grid
                                                        container
                                                        spacing={2}
                                                        style={{
                                                          display: 'flex',
                                                          alignItems: 'center',
                                                          marginLeft: '40px',
                                                        }}
                                                      >
                                                        <Grid item xs={12}>
                                                          {specification.name +
                                                            ' :'}
                                                        </Grid>
                                                        <Grid item xs={12}>
                                                          {specification.list
                                                            .length > 0 &&
                                                            specification.list.map(
                                                              (content) => (
                                                                <Grid
                                                                  container
                                                                  spacing={2}
                                                                  style={{
                                                                    display:
                                                                      'flex',
                                                                    alignItems:
                                                                      'center',
                                                                  }}
                                                                >
                                                                  <Grid
                                                                    item
                                                                    xs={4}
                                                                  >
                                                                    {
                                                                      content.name
                                                                    }
                                                                  </Grid>
                                                                  <Grid
                                                                    item
                                                                    xs={5}
                                                                  >
                                                                    <div
                                                                      style={{
                                                                        borderBottom:
                                                                          '1px solid black',
                                                                        width:
                                                                          '100%',
                                                                      }}
                                                                    ></div>
                                                                  </Grid>
                                                                  <Grid
                                                                    item
                                                                    xs={3}
                                                                  >
                                                                    {content.price +
                                                                      ' dh'}
                                                                  </Grid>
                                                                </Grid>
                                                              ),
                                                            )}
                                                        </Grid>
                                                      </Grid>
                                                    ),
                                                  )}
                                              </Grid>
                                            </Grid>
                                          </Box>
                                        ))}
                                    </Grid>
                                  </Grid>
                                ))}
                            </Grid>
                          </div>
                        </Collapse>
                        <button
                          style={{
                            border: 'none',
                            background: 'none',
                            color: '#0971f1',
                            fontSize: 'medium',
                          }}
                          onClick={() =>
                            setOpenCollapse({
                              ...openCollapse,
                              [index]: !openCollapse[index],
                            })
                          }
                        >
                          {openCollapse[index]
                            ? '... voir moins '
                            : '... voir plus'}
                        </button>
                      </TableCell>
                      <TableCell align="center" className="bordTableCell">
                        <button
                          className="actionButton"
                          onClick={() =>
                            handleOpenModal(order, index, 'orderDetails')
                          }
                        >
                          <span className="statusText">Fin Preparation</span>
                        </button>
                      </TableCell>
                    </TableRow>
                  ))}
              </TableBody>
            </Table>
          </TableContainer>
        </CardBody>
      </Card>
      {/* <Card>
                <CardHeader>

                Préparation des commandes
                </CardHeader>
                <CardBody>
                    {acceptedOrders && acceptedOrders.length > 0 && acceptedOrders.map((order,index) => (
                        <Card background={'#4caf50'} color={'#fff'} onClick={()=>handleOpenModal(order,index,'orderDetails')}>
                            <CardBody>
                                <Grid container spacing={3}>
                                    <Grid item xs={3}>
                                        {order?.shortId}
                                    </Grid>
                                    <Grid item xs={3}>
                                        {order?.payment?.orderPrice} dh
                                    </Grid>
                                    <Grid item xs={3}>
                                        <div>
                                            <span>{formatTimeForOrder(order.createdAt)}</span><br />
                                            <ChangeDurationComponents time={order.createdAt} />
                                        </div>
                                    </Grid>
                                    <Grid item xs={3}>
                                        <div>
                                            <span>{order?.payment?.paymentGateway}</span><br />
                                        </div>
                                    </Grid>
                                </Grid>
                            </CardBody>
                        </Card>

                    ))}
                </CardBody>
            </Card>
            */}
      {openModal && (
        <ModalComponent
          maxWidth={'sm'}
          close={closeModal}
          visible={openModal === 'orderDetails'}
          subTitle="La Préparation est Terminée"
        >
          <EndPreparationComponent
            order={order}
            close={closeModal}
            index={indexStore}
            // stores={stores}
            // setStores={setStores}
          />
        </ModalComponent>
      )}
    </GridContainer>
  );
}
