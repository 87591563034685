import React,{useState,useEffect} from 'react'
import { useAlert } from 'react-alert';
import DataTableContainerComponent from '../../../components/DataTableSortingContainer.component';
import { getItem,updateItem,getCategory,getSpecification } from '../services/serverAPI.service'
import SwitchComponent from '../../../components/Switch.component'

import GridContainer from '../../../components/material-dashboard-pro-react/Grid/GridContainer';
import Card from '../../../components/material-dashboard-pro-react/Card/Card';
import CardHeader from '../../../components/material-dashboard-pro-react/Card/CardHeader';
import CardBody from '../../../components/material-dashboard-pro-react/Card/CardBody';
import ModalComponent from '../../../components/Modal.component';
import Button from '../../../components/material-dashboard-pro-react/CustomButtons/Button';
import { Grid, Box, Divider } from '@material-ui/core'
import EditPrice from '../components/EditePriceItem'
import SelectBoxComponent from '../../../components/SelectBox.component';
import CustomButton from '../../../components/material-dashboard-pro-react/CustomButtons/Button';

export default function Item() {
    const alert = useAlert();
    const id = localStorage.getItem('storeId')
    const [category,setCategory] = useState([])
    const [allSpecifications,setAllSpecifications] = useState([])
    var categoryList = []
    const [resultsData, setResultsData] = useState({
        total: 0,
        items: [],
        tableItems: [],
    });
    const [isLoading, setIsLoading] = useState(false);
    const [page, setPage] = useState(0)
    const pages = [100, 250, 500];
    let numberOfRows = [10, 50, 100];
    const [rowsPerPage, setRowsPerPage] = useState(numberOfRows[0])
    const [openModal, setOpenModal] = useState('');
    const [item, setItem] = useState([]);
  const [IndexItem, setIndexItem] = useState(-1);
  const [selectedCategory, setSelectedCategory] = useState(null)
    const getAllSpecification = () => {
        getSpecification(id)
        .then(({data})=>setAllSpecifications(data))
        .catch((error) =>console.log(error))
    }
    // console.log('allSpecifications',allSpecifications);
  const closeModal = () => {
    setOpenModal('');
  };
    const handlePaginate = (val) => {
        setResultsData({
          items: [],
          tableItems: [],
        });
        loadItemList(val.pageSelect, val.numberOfRows);
        setPage(val.pageSelect);
        setRowsPerPage(val.numberOfRows);
        getAllCategory()
        getAllSpecification()
      };

      const SearchCategory = () => {
        return (
          <Grid container  justify="center"  alignItems= "center" >
         
         <Grid item xs={3}>
                <SelectBoxComponent
                  items={category.map((item) => ({
                    value: item._id,
                    label: item.name,
                  }))}
                  inputLabel="Category"
                  onValueChange={(item) => {
                    setSelectedCategory(item);
                  }}
                  value={selectedCategory}
                />
              </Grid>
             
    
            <Grid item xs={3} container justify="flex-start">
              <Box textAlign="left">
                <CustomButton
                  round
                  type="submit"
                  color="success"
                  size="sm"
                  loaderColor="white"
                  onClick={() => {
                    page > 0 && setPage(0);
                    loadItemList(
                      page> 0 ? 0 : page,
                      rowsPerPage,
                      selectedCategory,
                      
                    );
                    getAllCategory()
                    getAllSpecification()
                  }}
                >
                  find Category
                </CustomButton>
              </Box>
              <Box textAlign="left">
                <CustomButton
                  round
                  type="submit"
                  color="google"
                  size="sm"
                  loaderColor="white"
                  onClick={() => {
                      setSelectedCategory(null)
                      page > 0 && setPage(0);
                      loadItemList(
                      page > 0 ? 0 : page,
                      rowsPerPage,
                      '',
                    );
                    getAllCategory()
                    getAllSpecification()
                  }}
                >
                  clear
                </CustomButton>
              </Box>
            </Grid>
            
          </Grid>
        );
      };

    const updateIemFromSwitch=(key,key1,value, item)=>{
        
        key=='promoSetting'?item[key][key1] = value:item[key1]=value
        updateItem(item)
            .then(() => {
                alert.show('item updated', {
                    type: 'success',
                });
                loadItemList(page, rowsPerPage)
            })
            .catch((error) => {
                alert.show(error.message, {
                    type: 'error',
                });
            })
    }
    const handleOpenModal = (item, index, type) => {
        setItem(item);
        setIndexItem(index);
        setOpenModal(type);
      };
    const loadItemList = (skip,limit,selectedCategory) => {
        setIsLoading(true);
        getItem(id,skip,limit,selectedCategory)
            .then(({ data }) => {
                // console.log(data);
                setResultsData({
                    total: data.total,
                    items: data.items.map((item, index) => {
                        return {
                            ...item,
                            index:index,
                            name: item.name||'--',
                            description: item.description?item.description:'--',
                            price:( 
                            <div 
                            style={{cursor:'pointer'}} 
                             onClick={() => {
                                handleOpenModal(item, index,'EditPrice')
                              }}>
                                {item.price?item.price:'--'}
                                </div>
                                 ),
                            imageUrl: item.imageUrl?<img src={item.imageUrl} style={{width:'50px',height:'50px'}}/>:'--',
                            
                            inStock: 
                                <SwitchComponent
                                onChange={(value) => updateIemFromSwitch('inStock','inStock',value, item)}
                                value="checked"
                                checked={item.inStock}
                                label="inStock"
                                />,
                            specification:(
                                <div
                                style={{cursor:'pointer'}}
                                variant="outlined"
                                onClick={() => {
                                  handleOpenModal(item, index, 'specification');
                                }}>
                                {item.specifications?item.specifications.length:0}
                                </div>
                                ),
                            type:(
                                <div
                                    style={{cursor:'pointer'}}
                                    onClick={() => {
                                    handleOpenModal(item, index, 'EditPromo');
                                    }}
                                >
                                {item.promoSetting?.type?item.promoSetting.type:'--'}
                                </div>
                            ),
                            productList:(categoryList.find((element)=>element._id==item.productId)?.name),
                            enabled:<SwitchComponent
                            onChange={()=>{}}
                            value="checked"
                            checked={item.promoSetting.enabled}
                            label="enabled"
                            />,
                            

                        };
                    }),
                    
                });
                
            })
            .catch(error => console.log(error))
            .finally(() => {
                setIsLoading(false);
            });
    };
    const getAllCategory = () =>{
        getCategory(id)
            .then(({ data }) => {
                categoryList=data;
                setCategory(data)
            })
            .catch(error => console.log(error))
    }
    useEffect(() => {
        getAllCategory()
        getAllSpecification()
        // loadItemList(page, rowsPerPage)
    },[page, rowsPerPage])
    useEffect(() => {
        // categoryList.length> 0&&
        loadItemList(page, rowsPerPage)
    },[])
    const customColumnStyle = {
        maxWidth: "250px",
        minWidth: "150px",
      };
    return (
        <GridContainer className='bordGlobalContainer'>
            <Card>
                <CardHeader className='bordCardTableHeader'>
                    <div className='bordTableTitleDivider'>
                        <span className='bordStoreTableTitle'>
                            Item List
                        </span>
                        <Divider className='lineTitle' />
                    </div>
                </CardHeader>
                <CardBody>
                    <DataTableContainerComponent 
                    total={resultsData.total}
                    numberOfRowsProps={numberOfRows}
                    resultsData={resultsData}
                    initData={resultsData.items}
                    page={page + 1}
                    onPaginate={handlePaginate}
                    isPaginate={true}
                    searchInp={true}
                    SearchComponent={SearchCategory}
                    columns={[
                        {
                            Header: 'Image',
                            accessor: 'imageUrl'
                        },
                        {
                            Header: 'Name',
                            accessor: 'name'
                        },
                        {
                            Header: 'Description',
                            accessor: 'description'
                        },
                        {
                            Header: 'Price',
                            accessor: 'price'
                        },
                        {
                            Header: 'Category',
                            accessor: 'productList'
                        },
                        {
                            Header: 'InStock',
                            accessor: 'inStock'
                        },
                        {
                            Header: 'Specification',
                            accessor: 'specification'
                        },
                        {
                            Header: 'Promo (enable)',
                            accessor: 'enabled'
                        },
                    ]}
                    isLoading={isLoading}
                    loadItemsList={loadItemList}
                    />
                </CardBody>
            </Card>
        {/* <DataTableContainerComponent
        total={resultsData.total}
            numberOfRowsProps={numberOfRows}
            resultsData={resultsData}
            initData={resultsData.items}
            columns={[
                {
                    Header: 'Name',
                    accessor: 'name',
                },
                {
                    Header: 'Description',
                    accessor: 'description',
                },
                {
                    Header: 'Price',
                    accessor: 'price',
                },
                {
                    Header: 'productList',
                    accessor: 'productList',
                },
                {
                    Header: 'Image',
                    accessor: 'imageUrl',
                },
                {
                    Header: 'inStock',
                    accessor: 'inStockCol',
                },
                {
                    Header: 'specification',
                    accessor: 'specification',
                },
                {
                    Header: 'promo(type)',
                    accessor: 'type',
                },
                // {
                //     Header: 'promo(enabled)',
                //     accessor: 'enabled',
                // },
            ]}
            onPaginate={handlePaginate}
            isPaginate={false}
            title="Item List"
            isLoading={isLoading}
            loadItemsList={loadItemList}
        /> */}

{openModal && (
        <ModalComponent
          maxWidth={'sm'}
          close={closeModal}
          visible={openModal === 'EditPrice'}
          title="Edit Price"
        >
         <EditPrice 
         data={item}
         index= {IndexItem}
         close={closeModal}
         onItemEdited={loadItemList}
         categoryList = {getAllCategory}
         specifications = {getAllSpecification}

         />
        </ModalComponent>
      )}
       
          </GridContainer>
    )
}
