import axios from 'axios';
import io from 'socket.io-client'
import {
  backendServerConfig,
  backendSocketConfig,
  SOCKET_URL,
  MICROSERVICE_BASE_URL,
} from '../../../config/backendServer.config';

export function socketConnect(id) {
  return io(backendSocketConfig.appUrl, {
    path: '/api/v1/sockets/connect',
    query: { token: 'Bearer ' + id },
    transports: ['websocket'],
  })
}

export function updateOrder(storeId, orderId, status, preparationTime, timeToDispatchConfig) {
  return axios.put(`${MICROSERVICE_BASE_URL.DISPATCHING}/order/${orderId}/store/status`, { storeId, status, preparationTime, timeToDispatchConfig })
}
export function updatePreparationTime(orderId, preparationTime) {
  return axios.put(`${MICROSERVICE_BASE_URL.DISPATCHING}/order/${orderId}/preparationTime`, { preparationTime })
}

export function getHistoryOrders(skip, limit, paged, storeId, startDate, endDate, useDate, shortId) {
  return axios.get(
    `${MICROSERVICE_BASE_URL.ORDERS}/order/completed?page=${skip || 0}&limit=${limit || 5
    }&paged=${paged || false}&storeId=${storeId}&${shortId && `&shortId=${shortId || ''}`}&${useDate && `startDate=${startDate || ''}&endDate=${endDate || ''}`}`,
  );
}
export function getCronConfig() {
  return axios.get(
    `${MICROSERVICE_BASE_URL.CRON}/setting`,
  );
}