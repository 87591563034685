import { all, fork } from 'redux-saga/effects'
import multiUpload from './multiUploadRedux/saga'
import singleUpload from './uploadRedux/saga'
import orderSaga from '../features/commades/redux/saga'
import settingSaga from '../features/commades/redux/setting.saga'
import storeSaga from '../features/dashboardStore/redux/saga'

export default function* rootSaga() {
    yield all([
        fork(singleUpload),
        fork(multiUpload),
        fork(orderSaga),
        fork(storeSaga),
        fork(settingSaga),
    ]);
}