import React, { useEffect, useState } from 'react';

import { Grid, Box, Divider, Typography } from '@material-ui/core';

import { formatTimeForOrder } from '../../../helpers/index';
import ChangeDurationComponents from '../components/ChangeDuration.components';
import Button from '../../../components/material-dashboard-pro-react/CustomButtons/Button';
import { updateOrder } from '../services/serverApi.service';
import { preparationDuration } from '../mock/index';
import { useSelector } from 'react-redux';
import { getSettingDataState } from '../redux/selectors';

const style = {
  titleColor: {  color: 'balck',fontWeight: 'bold',fontSize: '15px' },
};
export default function OrderDetailsComponenet({ order, close,store }) {
  const settingData = useSelector(getSettingDataState);
  const { setting, error, loading } = settingData;
  // const [selectedIndex, setSelectedIndex] = useState(
  //   preparationDuration.findIndex((item) => item ==  order?.preparationTime),
  // );
    const [selectedIndex, setSelectedIndex] = useState(
    preparationDuration.findIndex((item) => store?.preparationTimeRequired ? 0 : item == order?.preparationTime),
  );
  const [preparationTime, setPreparationTime] = useState(
    store?.preparationTimeRequired? false:order?.preparationTime,
  );
  const acceptOrder = () => {
    updateOrder(
      order.store._id,
      order._id,
      'ACCEPTED',
      preparationTime,
      setting?.timeToDispatchConfig,
    )
      .then(() => close())
      .catch((error) => console.log(error));
  };
  const changeDuration = (item, index) => {
    setSelectedIndex(index);
    setPreparationTime(item);
  };
  return (
    <div>
      <Grid container spacing={3}>
        <Grid item xs={12} style={{fontFamily:'auto'}}>
          Commande ID :{order.shortId}
          <br />
          Temps de préparation :<br />
          <div style={{margin:'auto',textAlign:'center'}}>
          {preparationDuration &&
            preparationDuration.map((time, index) => (
              
              <Button
                size="sm"
                color={index == selectedIndex && 'success'}
                onClick={() => changeDuration(time, index)}
              >
                {time}
              </Button>
             
            ))}
             </div>
          {!preparationTime && (
            <>
              <br />
              <span style={{ color: 'red' }}>
                le temps de préparation est requis, veuillez sélectionner un
              </span>
            </>
          )}
        </Grid>
        <Grid item xs={4}>
          Commande crée le :{formatTimeForOrder(order.createdAt)}
          <br />
          <Grid container spacing={0}>
            <Grid item xs={4}>
              Temps écoulé :
            </Grid>
            <Grid item xs={4}>
              <ChangeDurationComponents time={order.createdAt} />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      {/* <Box display="flex" justifyContent="center"> */}
      <Grid container spacing={2}>
        <Grid item xs={12} container style={{background:'#f1f1f1',borderRadius:'20px',marginTop:'30px'}}>
          {order?.payment?.cartDetails.length > 0 &&
            order?.payment?.cartDetails.map((element) => (
              <Grid item xs={12} container spacing={2}>
                <Grid item xs={12} style={{ ...style.titleColor }}>
                  {element.name}
                </Grid>
                <Grid item xs={12}>
                  {element.items.length > 0 &&
                    element.items.map((item) => (
                      <Box display="flex" justifyContent="center">
                        <Grid
                          container
                          spacing={2}
                          style={{ display: 'flex', alignItems: 'center' }}
                        >
                          <Grid
                            item
                            xs={12}
                            container
                            // style={{ display: 'flex', alignItems: 'center' }}
                            className="mt-3"
                            spacing={2}
                          >
                            <Grid
                              item
                              xs={5}
                              style={{
                                ...style.titleColor,
                                marginLeft: '20px',
                                textAlign:'center'
                              }}
                            >
                              {`${item.name} ( x ${item.quantity} )`}
                            </Grid>
                            <Grid item xs={4} style={{alignSelf: 'center'}}>
                              <div
                                style={{
                                  borderBottom: '1px solid black',
                                  width: '100%', 
                                }}
                              ></div>
                            </Grid>
                            <Grid item xs={2}  style={{ ...style.titleColor }}>
                              <strong>{item.itemTotalPrice + ' dh'}</strong>
                            </Grid>
                            <Grid xs={12} style={{ ...style.titleColor,textAlign:'center',marginBottom:'10px' }} >
                            <Grid item xs={12} style={{ ...style.titleColor ,marginBottom:'14px',color:'red'}}>
                              {item?.note ==''? '':`Notes ------------------ ${item.note}`} 
                            </Grid>
                              </Grid>

                          </Grid>
                          {/* <Grid item xs={12} className="mt-2" container> */}
                          <Grid container>
                            {item.specifications.length > 0 &&
                              item.specifications.map((specification) => (
                                <Grid
                                  container
                                  spacing={2}
                                  style={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    marginLeft: '40px',
                                  }}
                                >
                                  <Grid item xs={12}>
                                    {specification.name + ' :'}
                                  </Grid>
                                  <Grid item xs={12}>
                                    {specification.list.length > 0 &&
                                      specification.list.map((content) => (
                                        <Grid
                                          container
                                          spacing={2}
                                          style={{
                                            display: 'flex',
                                            alignItems: 'center',
                                          }}
                                        >
                                          <Grid item xs={4}>
                                            {content.name}
                                          </Grid>
                                          <Grid item xs={5}>
                                            <div
                                              style={{
                                                borderBottom: '1px solid black',
                                                width: '100%',
                                              }}
                                            ></div>
                                          </Grid>
                                          <Grid item xs={3} >
                                            {content.price + ' dh'}
                                          </Grid>
                                        </Grid>
                                      ))}
                                  </Grid>
                                </Grid>
                              ))}
                          </Grid>
                          {/* </Grid> */}
                        </Grid>
                        
                      </Box>
                    ))}
                </Grid>
              </Grid>
            ))}
        </Grid>
      </Grid>
      <Grid container spacing={3} justify="flex-end">
        <Grid item xs={4} className="mt-3">
          <Typography variant="h5" style={{fontWeight:'bold' }}>
            Total : {order?.payment.orderPrice} dh
          </Typography>
        </Grid>
      </Grid>
      <Box textAlign="center">
        <Button
          round
          type="submit"
          color="success"
          loaderColor="white"
          disabled={!preparationTime}
          onClick={acceptOrder}
        >
          accepter
        </Button>
      </Box>
    </div>
  );
}
