import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { getOrderRequestAction } from '../redux/actions/order.actions';
import { getOrdersDataState } from '../redux/selectors';

import { Grid } from '@material-ui/core';

import DataTableContainerComponent from '../../../components/DataTableSortingContainer.component';
import GridContainer from '../../../components/material-dashboard-pro-react/Grid/GridContainer';
import Card from '../../../components/material-dashboard-pro-react/Card/Card';
import CardHeader from '../../../components/material-dashboard-pro-react/Card/CardHeader';
import CardBody from '../../../components/material-dashboard-pro-react/Card/CardBody';
import ChangeDurationComponents from '../components/ChangeDuration.components';
import OrderDetailsComponenet from '../components/OrderDetails.componenet';
import ModalComponent from '../../../components/Modal.component';
import { formatTimeForOrder, calcDuration } from '../../../helpers/index';
import { getStoreRequestAction } from '../../dashboardStore/redux/Actions/store.config.actions';
import { getStoreState } from '../../dashboardStore/redux/selectore';
import StoreEditPreparationComponentMoyenne from '../../dashboardStore/components/StorePreparationMoyenne.component';
import moment from 'moment';

// import TableContainer from '@mui/material/TableContainer';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { Warning, CalendarToday } from '@material-ui/icons';

export default function OrdersWaitForAccept() {
  const dispatch = useDispatch();
  const orders = useSelector(getOrdersDataState);
  const { waitingForAccept, error, loading } = orders;
  const [resultsData, setResultsData] = useState({
    items: [],
  });
  const [order, setOrder] = useState([]);
  const [indexStore, setIndexStore] = useState(-1);
  const [openModal, setOpenModal] = useState('');
  const storeId = localStorage.getItem('storeId');
  const store = useSelector(getStoreState);
  // console.log(orders);
  // const storeId= localStorage.getItem('storeId')
  // const cityId= localStorage.getItem('cityId')
  // console.log(waitingForAccept);

  const setOrderToState = (data) => {
    console.log(data);

    data &&
      setResultsData({
        // total: data.total,
        items: data.map((item, index) => {
          return {
            ...item,
            time: (
              <div>
                <span>{formatTimeForOrder(item.createdAt)}</span>
                <br />
                <span>{calcDuration(item.createdAt)} min</span>
              </div>
            ),
          };
        }),
      });
  };

  useEffect(() => {
    // dispatch(getOrderRequestAction(cityId,storeId))
    // console.log("heeeeeeeeeeeeyy",orders?.waitingForAccept);
    dispatch(getStoreRequestAction(storeId));
    waitingForAccept && setOrderToState(waitingForAccept);
  }, []);
  // console.log(loading);
  const handleOpenModal = (item, index, type) => {
    setOrder(item);
    setIndexStore(index);
    setOpenModal(type);
  };
  const closeModal = () => {
    setOpenModal('');
  };

  console.log('waitingForAccept', waitingForAccept);

  return (
    <GridContainer className="bordGlobalContainer">
      <Grid container className="bordHeader">
        <span className="bordPageTitle">Nouvelle commande</span>
        <div>
          <button
            style={{
              width: 'none',
              border: 'none',
              borderRadius: '20px',
              padding: '8px 10px',
              backgroundColor: '#28B873',
            }}
            onClick={() => {
              handleOpenModal('', '', 'preparationMoyenneModal');
            }}
          >
            <span className="statusText">Temps de preparation</span>
          </button>
        </div>
        {/* <div 
                className='bordToastNotification'
                >
                    
                    <Warning style={{
                        fontSize: '40px',
                        margin: '0px 10px 0px 0px'
                    }} />
                    <span className='bordToastSpanNotification'>Order ID 12341234 est annulée</span> 
                </div> */}
      </Grid>
      <Card className="bordCardBody">
        <CardBody className="bordCardBody">
          <TableContainer
            style={{
              maxHeight: '500px',
              overflowY: 'auto',
            }}
          >
            <Table
              sx={{ minWidth: 650 }}
              aria-label="simple table"
              stickyHeader
              className="bordTableGlobal"
              style={{
                border: 'none',
                borderCollapse: 'separate',
                borderSpacing: '0px 10px',
              }}
            >
              <TableHead>
                <TableRow>
                  <TableCell className="bordTableCell" align="center">
                    Transaction ID
                  </TableCell>
                  <TableCell className="bordTableCell" align="center">
                    Total de la commande
                  </TableCell>
                  <TableCell className="bordTableCell" align="center">
                    Prix apres commission
                  </TableCell>
                  <TableCell className="bordTableCell" align="center">
                    Commission
                  </TableCell>
                  <TableCell className="bordTableCell" align="center">
                    Date Création
                  </TableCell>
                  <TableCell className="bordTableCell" align="center">
                    Commande programmer
                  </TableCell>
                  <TableCell className="bordTableCell" align="center">
                    Paiement
                  </TableCell>
                  <TableCell className="bordTableCell" align="center">
                    Action
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {waitingForAccept &&
                  waitingForAccept.length > 0 &&
                  waitingForAccept.map((order, index) => (
                    <TableRow
                      className="rowTableCellWait"
                      style={{
                        backgroundColor: '#ffefea',
                        height: '80px',
                      }}
                      key={order?.name}
                      sx={{
                        '&:last-child td, &:last-child th': { border: 0 },
                      }}
                      // style={{

                      //     backgroundColor: row.status === 'Livré' ? '#d2f8d2'
                      //     : row.status === 'Annulée' ? '#ffefea' : '#fff1d8',
                      //  }}
                    >
                      <TableCell
                        align="center"
                        className="bordTableCell"
                        component="th"
                        scope="row"
                      >
                        {order?.shortId}
                      </TableCell>
                      <TableCell align="center" className="bordTableCell">
                        {order?.payment?.originalOrderPrice} Dhs
                      </TableCell>
                      <TableCell align="center" className="bordTableCell">
                        {order?.payment?.storeCommissionType === 'SOURCE'
                          ? (
                              order?.payment?.originalOrderPrice -
                              (order?.payment?.originalOrderPrice *
                                order?.payment?.storeCommission) /
                                100
                            ).toFixed(2)
                          : (order?.payment?.originalOrderPrice).toFixed(
                              2,
                            )}{' '}
                        Dhs
                        {/* {order?.payment?.storeCommissionType === "SOURCE" ? order?.payment?.originalOrderPrice * order?.payment?.storeCommission / 100 : null} Dhs */}
                      </TableCell>
                      <TableCell>
                        {(
                          (order?.payment?.originalOrderPrice *
                            order?.payment?.storeCommission) /
                          100
                        ).toFixed(2)}{' '}
                        Dhs
                      </TableCell>
                      <TableCell align="center" className="bordTableCell">
                        {' '}
                        {formatTimeForOrder(order?.createdAt)} /{' '}
                        <ChangeDurationComponents time={order?.createdAt} />
                      </TableCell>
                      <TableCell align="center" className="bordTableCell">
                        {order.payment.scheduled &&
                        order.payment.shceduleDate !== null ? (
                          <div>
                            <span style={{ color: 'red' }}>
                              <CalendarToday />{' '}
                            </span>
                            <span>
                              {moment(order.payment.shceduleDate).format(
                                'DD/MM/Y',
                              )}{' '}
                            </span>
                            <br />
                            <span>
                              à{' '}
                              {moment(order.payment.shceduleDate).format(
                                'HH:mm',
                              )}{' '}
                            </span>
                            <br />
                          </div>
                        ) : (
                          '-'
                        )}
                      </TableCell>
                      <TableCell
                        style={{
                          color:
                            order?.payment?.shouldDeliveryManPayStore === true
                              ? '#2bbd77'
                              : 'red',
                          fontSize: 'medium',
                        }}
                        align="center"
                        className="bordTableCell"
                      >
                        {/* {order?.payment?.shouldDeliveryManPayStore === true ?'à Encaisser':'Ne Pas Encaisse'} */}
                        {(order.payment?.payments || []).find(
                          (x) => x.gateway == 'Card' && x.amount > 0,
                        )
                          ? 'Card'
                          : 'Cash'}
                      </TableCell>
                      <TableCell align="center" className="bordTableCell">
                        <button
                          className="actionButton"
                          onClick={() =>
                            handleOpenModal(order, index, 'orderDetails')
                          }
                        >
                          <span className="statusText">Accepter</span>
                        </button>
                      </TableCell>
                    </TableRow>
                  ))}
              </TableBody>
            </Table>
          </TableContainer>
        </CardBody>
      </Card>
      {/* <Card>
                <CardHeader>
                Nouvelles commandes
                </CardHeader>
                <CardBody>
                    {waitingForAccept && waitingForAccept.length > 0 && waitingForAccept.map((order,index) => (
                        <Card background={'#4caf50'} color={'#fff'} onClick={()=>handleOpenModal(order,index,'orderDetails')}>
                            <CardBody>
                                <Grid container spacing={3}>
                                    <Grid item xs={3}>
                                        {order?.shortId}
                                    </Grid>
                                    <Grid item xs={3}>
                                        {order?.payment?.orderPrice} dh
                                    </Grid>
                                    <Grid item xs={3}>
                                        <div>
                                            <span>{formatTimeForOrder(order.createdAt)}</span><br />
                                            <ChangeDurationComponents time={order.createdAt} />
                                        </div>
                                    </Grid>
                                    <Grid item xs={3}>
                                        <div>
                                            <span>{order?.payment?.paymentGateway}</span><br />
                                        </div>
                                    </Grid>
                                </Grid>
                            </CardBody>
                        </Card>

                    ))}
                </CardBody>
            </Card>
            */}
      {openModal && openModal == 'orderDetails' && (
        <ModalComponent
          maxWidth={false}
          close={closeModal}
          visible={openModal === 'orderDetails'}
          title="Détail de commande"
        >
          <OrderDetailsComponenet
            order={order}
            close={closeModal}
            index={indexStore}
            store={store.store}
            // stores={stores}
            // setStores={setStores}
          />
        </ModalComponent>
      )}
      {openModal && openModal == 'preparationMoyenneModal' && (
        <ModalComponent
          maxWidth={'sm'}
          close={closeModal}
          visible={openModal === 'preparationMoyenneModal'}
          title="preparation"
        >
          <StoreEditPreparationComponentMoyenne
            store={store.store}
            close={closeModal}
          />
        </ModalComponent>
      )}
    </GridContainer>
  );
}
