/*!

=========================================================
* Material Dashboard React - v1.10.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/material-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
// @material-ui/icons
import Dashboard from '@material-ui/icons/Dashboard';
import LocalShippingIcon from '@material-ui/icons/LocalShipping';
import SportsMotorsportsIcon from '@material-ui/icons/SportsMotorsports';
import StoreIcon from '@material-ui/icons/Store';
import NotificationsIcon from '@material-ui/icons/Notifications';
import HistoryIcon from '@material-ui/icons/History';
import LogoutIcon from '@mui/icons-material/Logout';
// import CoffeeMakerNotificationsIcon from '@material-ui/icons/CoffeeMaker';
import CoffeeMakerIcon from '@mui/icons-material/CoffeeMaker';
import HomeIcon from '@mui/icons-material/Home';
import TwoWheelerIcon from '@mui/icons-material/TwoWheeler';
// core components/views for Admin layout
import ProductsPage from './features/dashboardStore/pages/Products.page';
import CategoryList from './features/dashboardStore/pages/CategoryList';
import Category from './features/dashboardStore/pages/Category';
import Specifications from './features/dashboardStore/pages/Specification';
import Item from './features/dashboardStore/pages/Item';
import Promo from './features/dashboardStore/pages/Promo';
import StoreConfig from './features/dashboardStore/pages/StoreConfig';
// core components/views for RTL layout
// icons for store items :
import { Settings } from '@material-ui/icons';
import { LocalParking } from '@material-ui/icons';
import { List } from '@material-ui/icons';
import { Inventory } from '@mui/icons-material';
import { LocalOffer } from '@material-ui/icons';
const path = window.location.pathname;

import Test from 'features/dashboardStore/pages/test';

const storeRoutes = [
    {
      collapse: true,
      name: 'store Configuration',
      icon: StoreIcon,
      displayParent : false,
      state: 'seconddeliveryInfoCollapse',
      views: [
        {
            path: '/configuration',
            name: 'Configuration store',
            mini: <Settings />,
            component: StoreConfig,
            layout: '/admin',
        },
        {
          path: '/store',
          name: 'Liste des Produits',
          mini: <LocalParking />,
          component: ProductsPage,
          layout: '/admin',
        },
        {
          path: '/categories-list',
          name: 'Catégories Listes',
          mini: <List />,
          component: CategoryList,
          layout: '/admin',
        },
        {
          path: '/categories',
          name: 'Catégories',
          mini: <Inventory />,
          component: Category,
          layout: '/admin',
        },
        {
          path: '/items',
          name: 'Liste des Items',
          mini: 'IT',
          component: Item,
          layout: '/admin',
        },
        {
          path: '/specifications',
          name: 'Liste des Spécifications',
          mini: 'SP',
          component: Specifications,
          layout: '/admin',
        },
        {
          path: '/promo',
          name: 'Liste des Promos',
          mini: <LocalOffer />,
          component: Promo,
          layout: '/admin',
        },
      ],
    },
];

export default storeRoutes;