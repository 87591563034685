import { combineReducers } from 'redux';
import authReducer from '../../features/auth/redux/reducers/auth.reducer';
import uploadReducer from '../uploadRedux/upload.reducer';
import multiUploadReducer from '../multiUploadRedux/upload.reducer';
import OrdersReducer from '../../features/commades/redux/reducers/order.reducers'
import settingReducer from '../../features/commades/redux/reducers/setting.reducers'
import storeReducer from '../../features/dashboardStore/redux/Reducers/store.config.reducer'

export default combineReducers({
  authReducer: authReducer,
  uploadReducer: uploadReducer,
  multiUploadReducer: multiUploadReducer,
  OrdersReducer: OrdersReducer,
  storeReducer: storeReducer,
  settingReducer: settingReducer
});
