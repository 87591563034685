export const staticData={
    "storeId": "611e88ac636ae400229ec741",
    "products": [
        {
            "productCategoryId": "611e8965636ae400229ec76d",
            "products": [
                {
                    "items": [],
                    "productId": "611e898d636ae400229ec89f",
                    "product": {
                        "name": "Salades, Herbes, Fraîches",
                        "description": "description",
                        "inStock": true,
                        "imageUrl": "imageUrl",
                        "createdAt": "2021-08-19T16:40:45.919Z",
                        "updatedAt": "2021-08-19T16:40:45.919Z",
                        "_id": "611e898d636ae400229ec89f",
                        "productCategoryId": "611e8965636ae400229ec76d",
                        "storeId": "611e88ac636ae400229ec741",
                        "__v": 0
                    }
                }
            ],
            "productCategory": {
                "name": "Marché",
                "description": "description",
                "inStock": true,
                "imageUrl": "https://kaalixzone1.s3.amazonaws.com/images/606dc35e9fc9d956e21cdef1CLlN.jpg",
                "createdAt": "2021-08-19T16:40:05.516Z",
                "updatedAt": "2021-08-19T16:40:05.516Z",
                "_id": "611e8965636ae400229ec76d",
                "storeId": "611e88ac636ae400229ec741",
                "__v": 0
            }
        },
        {
            "productCategoryId": "611e8965636ae400229ec770",
            "products": [
                {
                    "items": [
                        {
                            "promoSetting": {
                                "value": 0,
                                "minCartPrice": 0,
                                "enabled": false
                            },
                            "description": "description",
                            "name": "Lait de croissance 1+ 400g Nido",
                            "price": 34.8,
                            "imageUrl": "https://kaalixzone1.s3.amazonaws.com/images/606dbee19fc9d956e21cdeb9PMLh.jpg",
                            "inStock": true,
                            "specifications": [],
                            "isMainPromo": false,
                            "createdAt": null,
                            "updatedAt": null,
                            "_id": "611e89aa636ae400229ec8df",
                            "storeId": "611e88ac636ae400229ec741",
                            "productId": "611e898d636ae400229ec8b5",
                            "__v": 0
                        },
                        {
                            "promoSetting": {
                                "value": 0,
                                "minCartPrice": 0,
                                "enabled": false
                            },
                            "description": "description",
                            "name": "Poudre nan Optipro 3 400g",
                            "price": 59.95,
                            "imageUrl": "https://kaalixzone1.s3.amazonaws.com/images/606dbf104a4dfb4e3f8a7701IFcV.jpg",
                            "inStock": true,
                            "specifications": [],
                            "isMainPromo": false,
                            "createdAt": null,
                            "updatedAt": null,
                            "_id": "611e89aa636ae400229ec8e5",
                            "storeId": "611e88ac636ae400229ec741",
                            "productId": "611e898d636ae400229ec8b5",
                            "__v": 0
                        },
                        {
                            "promoSetting": {
                                "value": 0,
                                "minCartPrice": 0,
                                "enabled": false
                            },
                            "description": "description",
                            "name": "Cerelac blé 250g",
                            "price": 24.95,
                            "imageUrl": "https://kaalixzone1.s3.amazonaws.com/images/606dbeee9fc9d956e21cdebaoJcs.jpg",
                            "inStock": true,
                            "specifications": [],
                            "isMainPromo": false,
                            "createdAt": null,
                            "updatedAt": null,
                            "_id": "611e89aa636ae400229ec8e7",
                            "storeId": "611e88ac636ae400229ec741",
                            "productId": "611e898d636ae400229ec8b5",
                            "__v": 0
                        },
                        {
                            "promoSetting": {
                                "value": 0,
                                "minCartPrice": 0,
                                "enabled": false
                            },
                            "description": "description",
                            "name": "Cérelac fruits 250g",
                            "price": 28.45,
                            "imageUrl": "https://kaalixzone1.s3.amazonaws.com/images/606dbf309fc9d956e21cdebcgvTB.jpg",
                            "inStock": true,
                            "specifications": [],
                            "isMainPromo": false,
                            "createdAt": null,
                            "updatedAt": null,
                            "_id": "611e89aa636ae400229ec8ee",
                            "storeId": "611e88ac636ae400229ec741",
                            "productId": "611e898d636ae400229ec8b5",
                            "__v": 0
                        }
                    ],
                    "productId": "611e898d636ae400229ec8b5",
                    "product": {
                        "name": "Repas Bébé",
                        "description": "description",
                        "inStock": true,
                        "imageUrl": "imageUrl",
                        "createdAt": "2021-08-19T16:40:45.945Z",
                        "updatedAt": "2021-08-19T16:40:45.945Z",
                        "_id": "611e898d636ae400229ec8b5",
                        "productCategoryId": "611e8965636ae400229ec770",
                        "storeId": "611e88ac636ae400229ec741",
                        "__v": 0
                    }
                },
                {
                    "items": [
                        {
                            "promoSetting": {
                                "value": 0,
                                "minCartPrice": 0,
                                "enabled": false
                            },
                            "description": "description",
                            "name": "Couches taille 5 (13-22 kg) 38Pcs",
                            "price": 67.95,
                            "imageUrl": "https://kaalixzone1.s3.amazonaws.com/images/606dbf314a4dfb4e3f8a7702Eb91.jpg",
                            "inStock": true,
                            "specifications": [],
                            "isMainPromo": false,
                            "createdAt": null,
                            "updatedAt": null,
                            "_id": "611e89aa636ae400229ec8e3",
                            "storeId": "611e88ac636ae400229ec741",
                            "productId": "611e898d636ae400229ec8b1",
                            "__v": 0
                        },
                        {
                            "promoSetting": {
                                "value": 0,
                                "minCartPrice": 0,
                                "enabled": false
                            },
                            "description": "description",
                            "name": "Couches taille 2 (3-6 kg) 64Pcs",
                            "price": 66.5,
                            "imageUrl": "https://kaalixzone1.s3.amazonaws.com/images/606dbf724a4dfb4e3f8a77092eoW.jpg",
                            "inStock": true,
                            "specifications": [],
                            "isMainPromo": false,
                            "createdAt": null,
                            "updatedAt": null,
                            "_id": "611e89aa636ae400229ec8e9",
                            "storeId": "611e88ac636ae400229ec741",
                            "productId": "611e898d636ae400229ec8b1",
                            "__v": 0
                        },
                        {
                            "promoSetting": {
                                "value": 0,
                                "minCartPrice": 0,
                                "enabled": false
                            },
                            "description": "description",
                            "name": "Couches taille 5 (11-25 kg) 30Pcs",
                            "price": 59.95,
                            "imageUrl": "https://kaalixzone1.s3.amazonaws.com/images/606dbfca9fc9d956e21cdebfovdZ.jpg",
                            "inStock": true,
                            "specifications": [],
                            "isMainPromo": false,
                            "createdAt": null,
                            "updatedAt": null,
                            "_id": "611e89aa636ae400229ec8f8",
                            "storeId": "611e88ac636ae400229ec741",
                            "productId": "611e898d636ae400229ec8b1",
                            "__v": 0
                        },
                        {
                            "promoSetting": {
                                "value": 0,
                                "minCartPrice": 0,
                                "enabled": false
                            },
                            "description": "description",
                            "name": "Couches culottes taille 4 (9-14 kg) 28Pcs",
                            "price": 63.95,
                            "imageUrl": "https://kaalixzone1.s3.amazonaws.com/images/606dbf844a4dfb4e3f8a770cPzFM.jpg",
                            "inStock": true,
                            "specifications": [],
                            "isMainPromo": false,
                            "createdAt": null,
                            "updatedAt": null,
                            "_id": "611e89aa636ae400229ec8fa",
                            "storeId": "611e88ac636ae400229ec741",
                            "productId": "611e898d636ae400229ec8b1",
                            "__v": 0
                        },
                        {
                            "promoSetting": {
                                "value": 0,
                                "minCartPrice": 0,
                                "enabled": false
                            },
                            "description": "description",
                            "name": "Couches taille 3 (5-9 kg) 52Pcs",
                            "price": 66.5,
                            "imageUrl": "https://kaalixzone1.s3.amazonaws.com/images/606dbf694a4dfb4e3f8a7708gyJP.jpg",
                            "inStock": true,
                            "specifications": [],
                            "isMainPromo": false,
                            "createdAt": null,
                            "updatedAt": null,
                            "_id": "611e89aa636ae400229ec8fc",
                            "storeId": "611e88ac636ae400229ec741",
                            "productId": "611e898d636ae400229ec8b1",
                            "__v": 0
                        },
                        {
                            "promoSetting": {
                                "value": 0,
                                "minCartPrice": 0,
                                "enabled": false
                            },
                            "description": "description",
                            "name": "Eau de cologne s/alcool 100ml  j&j",
                            "price": 19.5,
                            "imageUrl": "https://kaalixzone1.s3.amazonaws.com/images/606dc0314a4dfb4e3f8a7716AYVi.jpg",
                            "inStock": true,
                            "specifications": [],
                            "isMainPromo": false,
                            "createdAt": null,
                            "updatedAt": null,
                            "_id": "611e89aa636ae400229ec906",
                            "storeId": "611e88ac636ae400229ec741",
                            "productId": "611e898d636ae400229ec8b1",
                            "__v": 0
                        },
                        {
                            "promoSetting": {
                                "value": 0,
                                "minCartPrice": 0,
                                "enabled": false
                            },
                            "description": "description",
                            "name": "Couches taille 4 (7-16 kg) 48Pcs",
                            "price": 66.5,
                            "imageUrl": "https://kaalixzone1.s3.amazonaws.com/images/606dbf604a4dfb4e3f8a77074e7X.jpg",
                            "inStock": true,
                            "specifications": [],
                            "isMainPromo": false,
                            "createdAt": null,
                            "updatedAt": null,
                            "_id": "611e89aa636ae400229ec90e",
                            "storeId": "611e88ac636ae400229ec741",
                            "productId": "611e898d636ae400229ec8b1",
                            "__v": 0
                        },
                        {
                            "promoSetting": {
                                "value": 0,
                                "minCartPrice": 0,
                                "enabled": false
                            },
                            "description": "description",
                            "name": "Couches taille 3 (6-10 kg) 29Pcs Pampers Premium",
                            "price": 59.95,
                            "imageUrl": "https://kaalixzone1.s3.amazonaws.com/images/606dbfc09fc9d956e21cdebeQZab.jpg",
                            "inStock": true,
                            "specifications": [],
                            "isMainPromo": false,
                            "createdAt": null,
                            "updatedAt": null,
                            "_id": "611e89aa636ae400229ec915",
                            "storeId": "611e88ac636ae400229ec741",
                            "productId": "611e898d636ae400229ec8b1",
                            "__v": 0
                        },
                        {
                            "promoSetting": {
                                "value": 0,
                                "minCartPrice": 0,
                                "enabled": false
                            },
                            "description": "description",
                            "name": "Couches taille 5 (11-25 kg) 24Pcs Pampers Premium",
                            "price": 59.95,
                            "imageUrl": "https://kaalixzone1.s3.amazonaws.com/images/606dbf8e4a4dfb4e3f8a770dOlbU.jpg",
                            "inStock": true,
                            "specifications": [],
                            "isMainPromo": false,
                            "createdAt": null,
                            "updatedAt": null,
                            "_id": "611e89aa636ae400229ec91d",
                            "storeId": "611e88ac636ae400229ec741",
                            "productId": "611e898d636ae400229ec8b1",
                            "__v": 0
                        },
                        {
                            "promoSetting": {
                                "value": 0,
                                "minCartPrice": 0,
                                "enabled": false
                            },
                            "description": "description",
                            "name": "Couches culottes taille 5 (12-18 kg) 52Pcs Pampers",
                            "price": 115.2,
                            "imageUrl": "https://kaalixzone1.s3.amazonaws.com/images/606dc0bb9fc9d956e21cdecegWLT.jpg",
                            "inStock": true,
                            "specifications": [],
                            "isMainPromo": false,
                            "createdAt": null,
                            "updatedAt": null,
                            "_id": "611e89aa636ae400229ec91f",
                            "storeId": "611e88ac636ae400229ec741",
                            "productId": "611e898d636ae400229ec8b1",
                            "__v": 0
                        },
                        {
                            "promoSetting": {
                                "value": 0,
                                "minCartPrice": 0,
                                "enabled": false
                            },
                            "description": "description",
                            "name": "Couches culottes taille 5 (12-18 kg) 26Pcs",
                            "price": 64.95,
                            "imageUrl": "https://kaalixzone1.s3.amazonaws.com/images/606dbf7b4a4dfb4e3f8a770aPerm.jpg",
                            "inStock": true,
                            "specifications": [],
                            "isMainPromo": false,
                            "createdAt": null,
                            "updatedAt": null,
                            "_id": "611e89aa636ae400229ec92f",
                            "storeId": "611e88ac636ae400229ec741",
                            "productId": "611e898d636ae400229ec8b1",
                            "__v": 0
                        },
                        {
                            "promoSetting": {
                                "value": 0,
                                "minCartPrice": 0,
                                "enabled": false
                            },
                            "description": "description",
                            "name": "Couches culottes taille 3 (6-11 kg) 62Pcs Pampers",
                            "price": 115.5,
                            "imageUrl": "https://kaalixzone1.s3.amazonaws.com/images/606dc0df9fc9d956e21cded2tyeb.jpg",
                            "inStock": true,
                            "specifications": [],
                            "isMainPromo": false,
                            "createdAt": null,
                            "updatedAt": null,
                            "_id": "611e89aa636ae400229ec931",
                            "storeId": "611e88ac636ae400229ec741",
                            "productId": "611e898d636ae400229ec8b1",
                            "__v": 0
                        },
                        {
                            "promoSetting": {
                                "value": 0,
                                "minCartPrice": 0,
                                "enabled": false
                            },
                            "description": "description",
                            "name": "Coton-tiges dalaa 100u",
                            "price": 12,
                            "imageUrl": "https://kaalixzone1.s3.amazonaws.com/images/606dc0989fc9d956e21cdec8o9J7.jpg",
                            "inStock": true,
                            "specifications": [],
                            "isMainPromo": false,
                            "createdAt": null,
                            "updatedAt": null,
                            "_id": "611e89aa636ae400229ec933",
                            "storeId": "611e88ac636ae400229ec741",
                            "productId": "611e898d636ae400229ec8b1",
                            "__v": 0
                        },
                        {
                            "promoSetting": {
                                "value": 0,
                                "minCartPrice": 0,
                                "enabled": false
                            },
                            "description": "description",
                            "name": "Couches taille 6 (+13 kg) 48Pcs  Pampers",
                            "price": 97.95,
                            "imageUrl": "https://kaalixzone1.s3.amazonaws.com/images/606dc0d04a4dfb4e3f8a7720hFUr.jpg",
                            "inStock": true,
                            "specifications": [],
                            "isMainPromo": false,
                            "createdAt": null,
                            "updatedAt": null,
                            "_id": "611e89aa636ae400229ec95c",
                            "storeId": "611e88ac636ae400229ec741",
                            "productId": "611e898d636ae400229ec8b1",
                            "__v": 0
                        },
                        {
                            "promoSetting": {
                                "value": 0,
                                "minCartPrice": 0,
                                "enabled": false
                            },
                            "description": "description",
                            "name": "Couches Midi taille 3 (6-10 kg) 36Pcs",
                            "price": 54.95,
                            "imageUrl": "https://kaalixzone1.s3.amazonaws.com/images/606dbfe79fc9d956e21cdec0bsIt.jpg",
                            "inStock": true,
                            "specifications": [],
                            "isMainPromo": false,
                            "createdAt": null,
                            "updatedAt": null,
                            "_id": "611e89aa636ae400229ec975",
                            "storeId": "611e88ac636ae400229ec741",
                            "productId": "611e898d636ae400229ec8b1",
                            "__v": 0
                        },
                        {
                            "promoSetting": {
                                "value": 0,
                                "minCartPrice": 0,
                                "enabled": false
                            },
                            "description": "description",
                            "name": "Couches taille 5 (13-22 kg) 56Pcs",
                            "price": 80.95,
                            "imageUrl": "https://kaalixzone1.s3.amazonaws.com/images/606dc11a9fc9d956e21cded5YHiO.jpg",
                            "inStock": true,
                            "specifications": [],
                            "isMainPromo": false,
                            "createdAt": null,
                            "updatedAt": null,
                            "_id": "611e89aa636ae400229ec977",
                            "storeId": "611e88ac636ae400229ec741",
                            "productId": "611e898d636ae400229ec8b1",
                            "__v": 0
                        },
                        {
                            "promoSetting": {
                                "value": 0,
                                "minCartPrice": 0,
                                "enabled": false
                            },
                            "description": "description",
                            "name": "Bain moussant 500ml Johnson baby",
                            "price": 51.95,
                            "imageUrl": "https://kaalixzone1.s3.amazonaws.com/images/606dbfc84a4dfb4e3f8a77119Yju.jpg",
                            "inStock": true,
                            "specifications": [],
                            "isMainPromo": false,
                            "createdAt": null,
                            "updatedAt": null,
                            "_id": "611e89aa636ae400229ec979",
                            "storeId": "611e88ac636ae400229ec741",
                            "productId": "611e898d636ae400229ec8b1",
                            "__v": 0
                        },
                        {
                            "promoSetting": {
                                "value": 0,
                                "minCartPrice": 0,
                                "enabled": false
                            },
                            "description": "description",
                            "name": "Shampoing bébé 200ml johnson",
                            "price": 21.95,
                            "imageUrl": "https://kaalixzone1.s3.amazonaws.com/images/606dc0284a4dfb4e3f8a7715q3NH.jpg",
                            "inStock": true,
                            "specifications": [],
                            "isMainPromo": false,
                            "createdAt": null,
                            "updatedAt": null,
                            "_id": "611e89aa636ae400229ec97b",
                            "storeId": "611e88ac636ae400229ec741",
                            "productId": "611e898d636ae400229ec8b1",
                            "__v": 0
                        },
                        {
                            "promoSetting": {
                                "value": 0,
                                "minCartPrice": 0,
                                "enabled": false
                            },
                            "description": "description",
                            "name": "Couches Mini taille 2 (3-8 kg) 40Pcs",
                            "price": 58.9,
                            "imageUrl": "https://kaalixzone1.s3.amazonaws.com/images/606dbfa94a4dfb4e3f8a770eFaL4.jpg",
                            "inStock": true,
                            "specifications": [],
                            "isMainPromo": false,
                            "createdAt": null,
                            "updatedAt": null,
                            "_id": "611e89ab636ae400229eca9c",
                            "storeId": "611e88ac636ae400229ec741",
                            "productId": "611e898d636ae400229ec8b1",
                            "__v": 0
                        },
                        {
                            "promoSetting": {
                                "value": 0,
                                "minCartPrice": 0,
                                "enabled": false
                            },
                            "description": "description",
                            "name": "Coton 70g septona",
                            "price": 18.95,
                            "imageUrl": "https://kaalixzone1.s3.amazonaws.com/images/606dc0544a4dfb4e3f8a7718fzaJ.jpg",
                            "inStock": true,
                            "specifications": [],
                            "isMainPromo": false,
                            "createdAt": null,
                            "updatedAt": null,
                            "_id": "611e89ab636ae400229ecaa0",
                            "storeId": "611e88ac636ae400229ec741",
                            "productId": "611e898d636ae400229ec8b1",
                            "__v": 0
                        },
                        {
                            "promoSetting": {
                                "value": 0,
                                "minCartPrice": 0,
                                "enabled": false
                            },
                            "description": "description",
                            "name": "Talc bébé 100g j&j",
                            "price": 17.9,
                            "imageUrl": "https://kaalixzone1.s3.amazonaws.com/images/606dc0879fc9d956e21cdec7Nel0.jpg",
                            "inStock": true,
                            "specifications": [],
                            "isMainPromo": false,
                            "createdAt": null,
                            "updatedAt": null,
                            "_id": "611e89ab636ae400229ecaa2",
                            "storeId": "611e88ac636ae400229ec741",
                            "productId": "611e898d636ae400229ec8b1",
                            "__v": 0
                        },
                        {
                            "promoSetting": {
                                "value": 0,
                                "minCartPrice": 0,
                                "enabled": false
                            },
                            "description": "description",
                            "name": "Lait de toilette soft 100ml Johnson baby",
                            "price": 17.5,
                            "imageUrl": "https://kaalixzone1.s3.amazonaws.com/images/606dc0654a4dfb4e3f8a771aQlRx.jpg",
                            "inStock": true,
                            "specifications": [],
                            "isMainPromo": false,
                            "createdAt": null,
                            "updatedAt": null,
                            "_id": "611e89ab636ae400229ecaa8",
                            "storeId": "611e88ac636ae400229ec741",
                            "productId": "611e898d636ae400229ec8b1",
                            "__v": 0
                        },
                        {
                            "promoSetting": {
                                "value": 0,
                                "minCartPrice": 0,
                                "enabled": false
                            },
                            "description": "description",
                            "name": "Couches extra large taille 6 (15+ kg) 36Pcs Molfix",
                            "price": 82,
                            "imageUrl": "https://kaalixzone1.s3.amazonaws.com/images/606dc1049fc9d956e21cded4SxO9.jpg",
                            "inStock": true,
                            "specifications": [],
                            "isMainPromo": false,
                            "createdAt": null,
                            "updatedAt": null,
                            "_id": "611e89ac636ae400229ecb66",
                            "storeId": "611e88ac636ae400229ec741",
                            "productId": "611e898d636ae400229ec8b1",
                            "__v": 0
                        },
                        {
                            "promoSetting": {
                                "value": 0,
                                "minCartPrice": 0,
                                "enabled": false
                            },
                            "description": "description",
                            "name": "Baby set crème 40g",
                            "price": 11.95,
                            "imageUrl": "https://kaalixzone1.s3.amazonaws.com/images/606dc0a19fc9d956e21cdecbgqpD.jpg",
                            "inStock": true,
                            "specifications": [],
                            "isMainPromo": false,
                            "createdAt": null,
                            "updatedAt": null,
                            "_id": "611e89ac636ae400229ecb68",
                            "storeId": "611e88ac636ae400229ec741",
                            "productId": "611e898d636ae400229ec8b1",
                            "__v": 0
                        },
                        {
                            "promoSetting": {
                                "value": 0,
                                "minCartPrice": 0,
                                "enabled": false
                            },
                            "description": "description",
                            "name": "Lingettes bébé complète Clean x64",
                            "price": 12.5,
                            "imageUrl": "https://kaalixzone1.s3.amazonaws.com/images/606dc1044a4dfb4e3f8a7724TD7k.jpg",
                            "inStock": true,
                            "specifications": [],
                            "isMainPromo": false,
                            "createdAt": null,
                            "updatedAt": null,
                            "_id": "611e89ac636ae400229ecb7c",
                            "storeId": "611e88ac636ae400229ec741",
                            "productId": "611e898d636ae400229ec8b1",
                            "__v": 0
                        },
                        {
                            "promoSetting": {
                                "value": 0,
                                "minCartPrice": 0,
                                "enabled": false
                            },
                            "description": "description",
                            "name": "Lingette bébé PH neutre x64",
                            "price": 9.95,
                            "imageUrl": "https://kaalixzone1.s3.amazonaws.com/images/606dc0b09fc9d956e21cdecc4HKF.jpg",
                            "inStock": true,
                            "specifications": [],
                            "isMainPromo": false,
                            "createdAt": null,
                            "updatedAt": null,
                            "_id": "611e89ac636ae400229ecb7e",
                            "storeId": "611e88ac636ae400229ec741",
                            "productId": "611e898d636ae400229ec8b1",
                            "__v": 0
                        },
                        {
                            "promoSetting": {
                                "value": 0,
                                "minCartPrice": 0,
                                "enabled": false
                            },
                            "description": "description",
                            "name": "Couches culottes taille 4 (9-14 kg) 56Pcs Pampers",
                            "price": 115.5,
                            "imageUrl": "https://kaalixzone1.s3.amazonaws.com/images/606dc0f29fc9d956e21cded3OX4a.jpg",
                            "inStock": true,
                            "specifications": [],
                            "isMainPromo": false,
                            "createdAt": null,
                            "updatedAt": null,
                            "_id": "611e89ad636ae400229ecda5",
                            "storeId": "611e88ac636ae400229ec741",
                            "productId": "611e898d636ae400229ec8b1",
                            "__v": 0
                        },
                        {
                            "promoSetting": {
                                "value": 0,
                                "minCartPrice": 0,
                                "enabled": false
                            },
                            "description": "description",
                            "name": "Couches Maxi taille 4 (9-18 kg) 32Pcs",
                            "price": 54.95,
                            "imageUrl": "https://kaalixzone1.s3.amazonaws.com/images/606dbfb24a4dfb4e3f8a770fPTyf.jpg",
                            "inStock": true,
                            "specifications": [],
                            "isMainPromo": false,
                            "createdAt": null,
                            "updatedAt": null,
                            "_id": "611e89ad636ae400229ecdec",
                            "storeId": "611e88ac636ae400229ec741",
                            "productId": "611e898d636ae400229ec8b1",
                            "__v": 0
                        }
                    ],
                    "productId": "611e898d636ae400229ec8b1",
                    "product": {
                        "name": "Couches, Toilette",
                        "description": "description",
                        "inStock": true,
                        "imageUrl": "imageUrl",
                        "createdAt": "2021-08-19T16:40:45.936Z",
                        "updatedAt": "2021-08-19T16:40:45.936Z",
                        "_id": "611e898d636ae400229ec8b1",
                        "productCategoryId": "611e8965636ae400229ec770",
                        "storeId": "611e88ac636ae400229ec741",
                        "__v": 0
                    }
                }
            ],
            "productCategory": {
                "name": "Univers Bébé",
                "description": "description",
                "inStock": true,
                "imageUrl": "https://kaalixzone1.s3.amazonaws.com/images/607017952c0d5e5d594f9ca6UI8v.jpg",
                "createdAt": "2021-08-19T16:40:05.522Z",
                "updatedAt": "2021-08-19T16:40:05.522Z",
                "_id": "611e8965636ae400229ec770",
                "storeId": "611e88ac636ae400229ec741",
                "__v": 0
            }
        },
        {
            "productCategoryId": "611e8965636ae400229ec788",
            "products": [
                {
                    "items": [],
                    "productId": "611e898d636ae400229ec87d",
                    "product": {
                        "name": "Beurre, Margarine",
                        "description": "description",
                        "inStock": true,
                        "imageUrl": "imageUrl",
                        "createdAt": "2021-08-19T16:40:45.884Z",
                        "updatedAt": "2021-08-19T16:40:45.884Z",
                        "_id": "611e898d636ae400229ec87d",
                        "productCategoryId": "611e8965636ae400229ec788",
                        "storeId": "611e88ac636ae400229ec741",
                        "__v": 0
                    }
                },
                {
                    "items": [],
                    "productId": "611e898d636ae400229ec883",
                    "product": {
                        "name": "Crèmerie,  Œuf",
                        "description": "description",
                        "inStock": true,
                        "imageUrl": "imageUrl",
                        "createdAt": "2021-08-19T16:40:45.888Z",
                        "updatedAt": "2021-08-19T16:40:45.888Z",
                        "_id": "611e898d636ae400229ec883",
                        "productCategoryId": "611e8965636ae400229ec788",
                        "storeId": "611e88ac636ae400229ec741",
                        "__v": 0
                    }
                }
            ],
            "productCategory": {
                "name": "Produits Laitiers",
                "description": "description",
                "inStock": true,
                "imageUrl": "https://kaalixzone1.s3.amazonaws.com/images/606dbdc94a4dfb4e3f8a76f8zAsD.jpg",
                "createdAt": "2021-08-19T16:40:05.574Z",
                "updatedAt": "2021-08-19T16:40:05.574Z",
                "_id": "611e8965636ae400229ec788",
                "storeId": "611e88ac636ae400229ec741",
                "__v": 0
            }
        },
        {
            "productCategoryId": "611e8965636ae400229ec78e",
            "products": [
                {
                    "items": [
                        {
                            "promoSetting": {
                                "value": 0,
                                "minCartPrice": 0,
                                "enabled": false
                            },
                            "description": "description",
                            "name": "Mortadelle Nature Toro 600g",
                            "price": 17.95,
                            "imageUrl": "https://kaalixzone1.s3.amazonaws.com/images/606dc4919fc9d956e21cdefevGnQ.jpg",
                            "inStock": true,
                            "specifications": [],
                            "isMainPromo": false,
                            "createdAt": null,
                            "updatedAt": null,
                            "_id": "611e89ab636ae400229ecaaa",
                            "storeId": "611e88ac636ae400229ec741",
                            "productId": "611e898d636ae400229ec7d3",
                            "__v": 0
                        },
                        {
                            "promoSetting": {
                                "value": 0,
                                "minCartPrice": 0,
                                "enabled": false
                            },
                            "description": "description",
                            "name": "Saucisson strasbourg 250 g Benna",
                            "price": 12.95,
                            "imageUrl": "https://kaalixzone1.s3.amazonaws.com/images/606dc6584a4dfb4e3f8a77626pZA.jpg",
                            "inStock": true,
                            "specifications": [],
                            "isMainPromo": false,
                            "createdAt": null,
                            "updatedAt": null,
                            "_id": "611e89ad636ae400229ecd3b",
                            "storeId": "611e88ac636ae400229ec741",
                            "productId": "611e898d636ae400229ec7d3",
                            "__v": 0
                        },
                        {
                            "promoSetting": {
                                "value": 0,
                                "minCartPrice": 0,
                                "enabled": false
                            },
                            "description": "description",
                            "name": "Luncheon de dinde 350g Koutoubia'",
                            "price": 16.5,
                            "imageUrl": "https://kaalixzone1.s3.amazonaws.com/images/606dc5959fc9d956e21cdf0bCcDO.jpg",
                            "inStock": true,
                            "specifications": [],
                            "isMainPromo": false,
                            "createdAt": null,
                            "updatedAt": null,
                            "_id": "611e89ad636ae400229ecda7",
                            "storeId": "611e88ac636ae400229ec741",
                            "productId": "611e898d636ae400229ec7d3",
                            "__v": 0
                        },
                        {
                            "promoSetting": {
                                "value": 0,
                                "minCartPrice": 0,
                                "enabled": false
                            },
                            "description": "description",
                            "name": "Saucisson hot dog 250 g Benna",
                            "price": 13.5,
                            "imageUrl": "imageUrl",
                            "inStock": true,
                            "specifications": [],
                            "isMainPromo": false,
                            "createdAt": null,
                            "updatedAt": null,
                            "_id": "611e89ad636ae400229ecedc",
                            "storeId": "611e88ac636ae400229ec741",
                            "productId": "611e898d636ae400229ec7d3",
                            "__v": 0
                        },
                        {
                            "promoSetting": {
                                "value": 0,
                                "minCartPrice": 0,
                                "enabled": false
                            },
                            "description": "description",
                            "name": "Cuisse de dinde fumée 80g Koutoubia",
                            "price": 10.95,
                            "imageUrl": "https://kaalixzone1.s3.amazonaws.com/images/606dc5984a4dfb4e3f8a77536YDF.jpg",
                            "inStock": true,
                            "specifications": [],
                            "isMainPromo": false,
                            "createdAt": null,
                            "updatedAt": null,
                            "_id": "611e89ad636ae400229ecede",
                            "storeId": "611e88ac636ae400229ec741",
                            "productId": "611e898d636ae400229ec7d3",
                            "__v": 0
                        },
                        {
                            "promoSetting": {
                                "value": 0,
                                "minCartPrice": 0,
                                "enabled": false
                            },
                            "description": "description",
                            "name": "Luncheon  à base de volaille et bœuf 300G Dindy",
                            "price": 11.5,
                            "imageUrl": "https://kaalixzone1.s3.amazonaws.com/images/606dc4879fc9d956e21cdefdVOOX.jpg",
                            "inStock": true,
                            "specifications": [],
                            "isMainPromo": false,
                            "createdAt": null,
                            "updatedAt": null,
                            "_id": "611e89ae636ae400229ed062",
                            "storeId": "611e88ac636ae400229ec741",
                            "productId": "611e898d636ae400229ec7d3",
                            "__v": 0
                        },
                        {
                            "promoSetting": {
                                "value": 0,
                                "minCartPrice": 0,
                                "enabled": false
                            },
                            "description": "description",
                            "name": "Luncheon de dinde 350g Koutoubia",
                            "price": 12.5,
                            "imageUrl": "https://kaalixzone1.s3.amazonaws.com/images/606dc49b9fc9d956e21cdeffvLQR.jpg",
                            "inStock": true,
                            "specifications": [],
                            "isMainPromo": false,
                            "createdAt": null,
                            "updatedAt": null,
                            "_id": "611e89ae636ae400229ed076",
                            "storeId": "611e88ac636ae400229ec741",
                            "productId": "611e898d636ae400229ec7d3",
                            "__v": 0
                        },
                        {
                            "promoSetting": {
                                "value": 0,
                                "minCartPrice": 0,
                                "enabled": false
                            },
                            "description": "description",
                            "name": "Jambon de dinde fumé 80g Koutoubia",
                            "price": 11.5,
                            "imageUrl": "https://kaalixzone1.s3.amazonaws.com/images/606dc5b34a4dfb4e3f8a7755oAyK.jpg",
                            "inStock": true,
                            "specifications": [],
                            "isMainPromo": false,
                            "createdAt": null,
                            "updatedAt": null,
                            "_id": "611e89ae636ae400229ed106",
                            "storeId": "611e88ac636ae400229ec741",
                            "productId": "611e898d636ae400229ec7d3",
                            "__v": 0
                        },
                        {
                            "promoSetting": {
                                "value": 0,
                                "minCartPrice": 0,
                                "enabled": false
                            },
                            "description": "description",
                            "name": "Filet de dinde cuit 80g Koutoubia",
                            "price": 11.75,
                            "imageUrl": "https://kaalixzone1.s3.amazonaws.com/images/606dc63a4a4dfb4e3f8a775eY5Qx.jpg",
                            "inStock": true,
                            "specifications": [],
                            "isMainPromo": false,
                            "createdAt": null,
                            "updatedAt": null,
                            "_id": "611e89ae636ae400229ed22b",
                            "storeId": "611e88ac636ae400229ec741",
                            "productId": "611e898d636ae400229ec7d3",
                            "__v": 0
                        },
                        {
                            "promoSetting": {
                                "value": 0,
                                "minCartPrice": 0,
                                "enabled": false
                            },
                            "description": "description",
                            "name": "Blanc de dinde light en tranche 80G",
                            "price": 10.5,
                            "imageUrl": "https://kaalixzone1.s3.amazonaws.com/images/606dc6474a4dfb4e3f8a7760YN7t.jpg",
                            "inStock": true,
                            "specifications": [],
                            "isMainPromo": false,
                            "createdAt": null,
                            "updatedAt": null,
                            "_id": "611e89ae636ae400229ed233",
                            "storeId": "611e88ac636ae400229ec741",
                            "productId": "611e898d636ae400229ec7d3",
                            "__v": 0
                        },
                        {
                            "promoSetting": {
                                "value": 0,
                                "minCartPrice": 0,
                                "enabled": false
                            },
                            "description": "description",
                            "name": "Luncheon de poulet 350g Koutoubia",
                            "price": 16.5,
                            "imageUrl": "https://kaalixzone1.s3.amazonaws.com/images/606dc4b29fc9d956e21cdf01LylN.jpg",
                            "inStock": true,
                            "specifications": [],
                            "isMainPromo": false,
                            "createdAt": null,
                            "updatedAt": null,
                            "_id": "611e89ae636ae400229ed272",
                            "storeId": "611e88ac636ae400229ec741",
                            "productId": "611e898d636ae400229ec7d3",
                            "__v": 0
                        },
                        {
                            "promoSetting": {
                                "value": 0,
                                "minCartPrice": 0,
                                "enabled": false
                            },
                            "description": "description",
                            "name": "Jambon de dinde fumé 80g Dindy",
                            "price": 9.95,
                            "imageUrl": "https://kaalixzone1.s3.amazonaws.com/images/606dc4a79fc9d956e21cdf00UcSa.jpg",
                            "inStock": true,
                            "specifications": [],
                            "isMainPromo": false,
                            "createdAt": null,
                            "updatedAt": null,
                            "_id": "611e89ae636ae400229ed282",
                            "storeId": "611e88ac636ae400229ec741",
                            "productId": "611e898d636ae400229ec7d3",
                            "__v": 0
                        },
                        {
                            "promoSetting": {
                                "value": 0,
                                "minCartPrice": 0,
                                "enabled": false
                            },
                            "description": "description",
                            "name": "Filet de dinde fumé 80g Koutoubia",
                            "price": 11.5,
                            "imageUrl": "https://kaalixzone1.s3.amazonaws.com/images/606dc5b89fc9d956e21cdf0c3dXx.jpg",
                            "inStock": true,
                            "specifications": [],
                            "isMainPromo": false,
                            "createdAt": null,
                            "updatedAt": null,
                            "_id": "611e89af636ae400229ed519",
                            "storeId": "611e88ac636ae400229ec741",
                            "productId": "611e898d636ae400229ec7d3",
                            "__v": 0
                        }
                    ],
                    "productId": "611e898d636ae400229ec7d3",
                    "product": {
                        "name": "Charcuterie",
                        "description": "description",
                        "inStock": true,
                        "imageUrl": "imageUrl",
                        "createdAt": "2021-08-19T16:40:45.607Z",
                        "updatedAt": "2021-08-19T16:40:45.607Z",
                        "_id": "611e898d636ae400229ec7d3",
                        "productCategoryId": "611e8965636ae400229ec78e",
                        "storeId": "611e88ac636ae400229ec741",
                        "__v": 0
                    }
                },
                {
                    "items": [
                        {
                            "promoSetting": {
                                "value": 0,
                                "minCartPrice": 0,
                                "enabled": false
                            },
                            "description": "description",
                            "name": "Plaquette de saumon fumé de Norvège 100G",
                            "price": 54.95,
                            "imageUrl": "https://kaalixzone1.s3.amazonaws.com/images/606dc4074a4dfb4e3f8a7745RbDJ.jpg",
                            "inStock": true,
                            "specifications": [],
                            "isMainPromo": false,
                            "createdAt": null,
                            "updatedAt": null,
                            "_id": "611e89ab636ae400229eca9e",
                            "storeId": "611e88ac636ae400229ec741",
                            "productId": "611e898d636ae400229ec7d7",
                            "__v": 0
                        },
                        {
                            "promoSetting": {
                                "value": 0,
                                "minCartPrice": 0,
                                "enabled": false
                            },
                            "description": "description",
                            "name": "Filet de saumon fumé à froid 100G",
                            "price": 58.65,
                            "imageUrl": "https://kaalixzone1.s3.amazonaws.com/images/606dc3ab4a4dfb4e3f8a773eQHnZ.jpg",
                            "inStock": true,
                            "specifications": [],
                            "isMainPromo": false,
                            "createdAt": null,
                            "updatedAt": null,
                            "_id": "611e89ab636ae400229ecaa6",
                            "storeId": "611e88ac636ae400229ec741",
                            "productId": "611e898d636ae400229ec7d7",
                            "__v": 0
                        },
                        {
                            "promoSetting": {
                                "value": 0,
                                "minCartPrice": 0,
                                "enabled": false
                            },
                            "description": "description",
                            "name": "Feuille de Pastilla 200g",
                            "price": 14.95,
                            "imageUrl": "https://kaalixzone1.s3.amazonaws.com/images/606dc3fb4a4dfb4e3f8a7744rhJW.jpg",
                            "inStock": true,
                            "specifications": [],
                            "isMainPromo": false,
                            "createdAt": null,
                            "updatedAt": null,
                            "_id": "611e89ad636ae400229ecd3f",
                            "storeId": "611e88ac636ae400229ec741",
                            "productId": "611e898d636ae400229ec7d7",
                            "__v": 0
                        },
                        {
                            "promoSetting": {
                                "value": 0,
                                "minCartPrice": 0,
                                "enabled": false
                            },
                            "description": "description",
                            "name": "Miette de saumon de Norvège 150g",
                            "price": 52.9,
                            "imageUrl": "https://kaalixzone1.s3.amazonaws.com/images/606dc4134a4dfb4e3f8a7746Ohkt.jpg",
                            "inStock": true,
                            "specifications": [],
                            "isMainPromo": false,
                            "createdAt": null,
                            "updatedAt": null,
                            "_id": "611e89ad636ae400229ecdb8",
                            "storeId": "611e88ac636ae400229ec741",
                            "productId": "611e898d636ae400229ec7d7",
                            "__v": 0
                        },
                        {
                            "promoSetting": {
                                "value": 0,
                                "minCartPrice": 0,
                                "enabled": false
                            },
                            "description": "description",
                            "name": "Bâtonnets surimi saveur crabe 200G Saint Malo",
                            "price": 16.95,
                            "imageUrl": "https://kaalixzone1.s3.amazonaws.com/images/606dc3e64a4dfb4e3f8a7741VQ10.jpg",
                            "inStock": true,
                            "specifications": [],
                            "isMainPromo": false,
                            "createdAt": null,
                            "updatedAt": null,
                            "_id": "611e89ad636ae400229ecee0",
                            "storeId": "611e88ac636ae400229ec741",
                            "productId": "611e898d636ae400229ec7d7",
                            "__v": 0
                        },
                        {
                            "promoSetting": {
                                "value": 0,
                                "minCartPrice": 0,
                                "enabled": false
                            },
                            "description": "description",
                            "name": "Pâte à Pastilla 470g",
                            "price": 20.95,
                            "imageUrl": "https://kaalixzone1.s3.amazonaws.com/images/606dc41d4a4dfb4e3f8a7747itd5.jpg",
                            "inStock": true,
                            "specifications": [],
                            "isMainPromo": false,
                            "createdAt": null,
                            "updatedAt": null,
                            "_id": "611e89ae636ae400229ed229",
                            "storeId": "611e88ac636ae400229ec741",
                            "productId": "611e898d636ae400229ec7d7",
                            "__v": 0
                        }
                    ],
                    "productId": "611e898d636ae400229ec7d7",
                    "product": {
                        "name": "Traiteur",
                        "description": "description",
                        "inStock": true,
                        "imageUrl": "imageUrl",
                        "createdAt": "2021-08-19T16:40:45.620Z",
                        "updatedAt": "2021-08-19T16:40:45.620Z",
                        "_id": "611e898d636ae400229ec7d7",
                        "productCategoryId": "611e8965636ae400229ec78e",
                        "storeId": "611e88ac636ae400229ec741",
                        "__v": 0
                    }
                }
            ],
            "productCategory": {
                "name": "Charcuterie, Traiteur",
                "description": "description",
                "inStock": true,
                "imageUrl": "https://kaalixzone1.s3.amazonaws.com/images/606f1fa831103653f3f1d897ZQtR.jpg",
                "createdAt": "2021-08-19T16:40:05.578Z",
                "updatedAt": "2021-08-19T16:40:05.578Z",
                "_id": "611e8965636ae400229ec78e",
                "storeId": "611e88ac636ae400229ec741",
                "__v": 0
            }
        },
        {
            "productCategoryId": "611e8965636ae400229ec796",
            "products": [
                {
                    "items": [
                        {
                            "promoSetting": {
                                "value": 0,
                                "minCartPrice": 0,
                                "enabled": false
                            },
                            "description": "description",
                            "name": "Viande hachée au kg",
                            "price": 89.95,
                            "imageUrl": "https://kaalixzone1.s3.amazonaws.com/images/606dc7749fc9d956e21cdf1dTJsI.jpg",
                            "inStock": true,
                            "specifications": [],
                            "isMainPromo": false,
                            "createdAt": null,
                            "updatedAt": null,
                            "_id": "611e89ac636ae400229ecb62",
                            "storeId": "611e88ac636ae400229ec741",
                            "productId": "611e898d636ae400229ec7da",
                            "__v": 0
                        },
                        {
                            "promoSetting": {
                                "value": 0,
                                "minCartPrice": 0,
                                "enabled": false
                            },
                            "description": "description",
                            "name": "Tagine extra de Bœuf avec os au kg",
                            "price": 89.95,
                            "imageUrl": "https://kaalixzone1.s3.amazonaws.com/images/606dc7344a4dfb4e3f8a77717uMh.jpg",
                            "inStock": true,
                            "specifications": [],
                            "isMainPromo": false,
                            "createdAt": null,
                            "updatedAt": null,
                            "_id": "611e89ad636ae400229ecdad",
                            "storeId": "611e88ac636ae400229ec741",
                            "productId": "611e898d636ae400229ec7da",
                            "__v": 0
                        },
                        {
                            "promoSetting": {
                                "value": 0,
                                "minCartPrice": 0,
                                "enabled": false
                            },
                            "description": "description",
                            "name": "Tagine de Bœuf sans os au kg",
                            "price": 119.95,
                            "imageUrl": "https://kaalixzone1.s3.amazonaws.com/images/606dc7264a4dfb4e3f8a776d5Xm5.jpg",
                            "inStock": true,
                            "specifications": [],
                            "isMainPromo": false,
                            "createdAt": null,
                            "updatedAt": null,
                            "_id": "611e89ae636ae400229ed078",
                            "storeId": "611e88ac636ae400229ec741",
                            "productId": "611e898d636ae400229ec7da",
                            "__v": 0
                        },
                        {
                            "promoSetting": {
                                "value": 0,
                                "minCartPrice": 0,
                                "enabled": false
                            },
                            "description": "description",
                            "name": "Entrecôte de Bœuf au kg",
                            "price": 134.95,
                            "imageUrl": "https://kaalixzone1.s3.amazonaws.com/images/606dc70c4a4dfb4e3f8a776a7zyB.jpg",
                            "inStock": true,
                            "specifications": [],
                            "isMainPromo": false,
                            "createdAt": null,
                            "updatedAt": null,
                            "_id": "611e89ae636ae400229ed108",
                            "storeId": "611e88ac636ae400229ec741",
                            "productId": "611e898d636ae400229ec7da",
                            "__v": 0
                        },
                        {
                            "promoSetting": {
                                "value": 0,
                                "minCartPrice": 0,
                                "enabled": false
                            },
                            "description": "description",
                            "name": "Viande hachée maigre au kg",
                            "price": 109.95,
                            "imageUrl": "https://kaalixzone1.s3.amazonaws.com/images/606dc7414a4dfb4e3f8a77735JKQ.jpg",
                            "inStock": true,
                            "specifications": [],
                            "isMainPromo": false,
                            "createdAt": null,
                            "updatedAt": null,
                            "_id": "611e89ae636ae400229ed102",
                            "storeId": "611e88ac636ae400229ec741",
                            "productId": "611e898d636ae400229ec7da",
                            "__v": 0
                        },
                        {
                            "promoSetting": {
                                "value": 0,
                                "minCartPrice": 0,
                                "enabled": false
                            },
                            "description": "description",
                            "name": "Faux filet de Bœuf au kg",
                            "price": 134.95,
                            "imageUrl": "https://kaalixzone1.s3.amazonaws.com/images/606dc7194a4dfb4e3f8a776ceYVG.jpg",
                            "inStock": true,
                            "specifications": [],
                            "isMainPromo": false,
                            "createdAt": null,
                            "updatedAt": null,
                            "_id": "611e89ae636ae400229ed231",
                            "storeId": "611e88ac636ae400229ec741",
                            "productId": "611e898d636ae400229ec7da",
                            "__v": 0
                        },
                        {
                            "promoSetting": {
                                "value": 0,
                                "minCartPrice": 0,
                                "enabled": false
                            },
                            "description": "description",
                            "name": "Saucisse nature de Boeuf au kg",
                            "price": 99.95,
                            "imageUrl": "https://kaalixzone1.s3.amazonaws.com/images/606dc7544a4dfb4e3f8a77740ZRe.jpg",
                            "inStock": true,
                            "specifications": [],
                            "isMainPromo": false,
                            "createdAt": null,
                            "updatedAt": null,
                            "_id": "611e89af636ae400229ed513",
                            "storeId": "611e88ac636ae400229ec741",
                            "productId": "611e898d636ae400229ec7da",
                            "__v": 0
                        }
                    ],
                    "productId": "611e898d636ae400229ec7da",
                    "product": {
                        "name": "Boucherie",
                        "description": "description",
                        "inStock": true,
                        "imageUrl": "imageUrl",
                        "createdAt": "2021-08-19T16:40:45.637Z",
                        "updatedAt": "2021-08-19T16:40:45.637Z",
                        "_id": "611e898d636ae400229ec7da",
                        "productCategoryId": "611e8965636ae400229ec796",
                        "storeId": "611e88ac636ae400229ec741",
                        "__v": 0
                    }
                },
                {
                    "items": [
                        {
                            "promoSetting": {
                                "value": 0,
                                "minCartPrice": 0,
                                "enabled": false
                            },
                            "description": "description",
                            "name": "Filet de dinde au kg",
                            "price": 45.95,
                            "imageUrl": "https://kaalixzone1.s3.amazonaws.com/images/606dc7c89fc9d956e21cdf204cIr.jpg",
                            "inStock": true,
                            "specifications": [],
                            "isMainPromo": false,
                            "createdAt": null,
                            "updatedAt": null,
                            "_id": "611e89ad636ae400229ecdfd",
                            "storeId": "611e88ac636ae400229ec741",
                            "productId": "611e898d636ae400229ec85a",
                            "__v": 0
                        },
                        {
                            "promoSetting": {
                                "value": 0,
                                "minCartPrice": 0,
                                "enabled": false
                            },
                            "description": "description",
                            "name": "Haut de cuisse de poulet au kg",
                            "price": 28.95,
                            "imageUrl": "https://kaalixzone1.s3.amazonaws.com/images/606dc8004a4dfb4e3f8a7781egsS.jpg",
                            "inStock": true,
                            "specifications": [],
                            "isMainPromo": false,
                            "createdAt": null,
                            "updatedAt": null,
                            "_id": "611e89ad636ae400229eceda",
                            "storeId": "611e88ac636ae400229ec741",
                            "productId": "611e898d636ae400229ec85a",
                            "__v": 0
                        },
                        {
                            "promoSetting": {
                                "value": 0,
                                "minCartPrice": 0,
                                "enabled": false
                            },
                            "description": "description",
                            "name": "Pilon de poulet au kg",
                            "price": 39.95,
                            "imageUrl": "https://kaalixzone1.s3.amazonaws.com/images/606dc7874a4dfb4e3f8a7777JGWO.jpg",
                            "inStock": true,
                            "specifications": [],
                            "isMainPromo": false,
                            "createdAt": null,
                            "updatedAt": null,
                            "_id": "611e89ae636ae400229ed0f8",
                            "storeId": "611e88ac636ae400229ec741",
                            "productId": "611e898d636ae400229ec85a",
                            "__v": 0
                        },
                        {
                            "promoSetting": {
                                "value": 0,
                                "minCartPrice": 0,
                                "enabled": false
                            },
                            "description": "description",
                            "name": "Poulet entier au kg",
                            "price": 29.95,
                            "imageUrl": "https://kaalixzone1.s3.amazonaws.com/images/606dc7774a4dfb4e3f8a7776TQx7.jpg",
                            "inStock": true,
                            "specifications": [],
                            "isMainPromo": false,
                            "createdAt": null,
                            "updatedAt": null,
                            "_id": "611e89ae636ae400229ed235",
                            "storeId": "611e88ac636ae400229ec741",
                            "productId": "611e898d636ae400229ec85a",
                            "__v": 0
                        },
                        {
                            "promoSetting": {
                                "value": 0,
                                "minCartPrice": 0,
                                "enabled": false
                            },
                            "description": "description",
                            "name": "Osso bucco de dinde au kg",
                            "price": 28.95,
                            "imageUrl": "https://kaalixzone1.s3.amazonaws.com/images/606dc7a84a4dfb4e3f8a777a4HHZ.jpg",
                            "inStock": true,
                            "specifications": [],
                            "isMainPromo": false,
                            "createdAt": null,
                            "updatedAt": null,
                            "_id": "611e89ae636ae400229ed280",
                            "storeId": "611e88ac636ae400229ec741",
                            "productId": "611e898d636ae400229ec85a",
                            "__v": 0
                        },
                        {
                            "promoSetting": {
                                "value": 0,
                                "minCartPrice": 0,
                                "enabled": false
                            },
                            "description": "description",
                            "name": "Burger nature frais au kg",
                            "price": 50.95,
                            "imageUrl": "https://kaalixzone1.s3.amazonaws.com/images/606dc7b84a4dfb4e3f8a777cCNvE.jpg",
                            "inStock": true,
                            "specifications": [],
                            "isMainPromo": false,
                            "createdAt": null,
                            "updatedAt": null,
                            "_id": "611e89ae636ae400229ed2be",
                            "storeId": "611e88ac636ae400229ec741",
                            "productId": "611e898d636ae400229ec85a",
                            "__v": 0
                        },
                        {
                            "promoSetting": {
                                "value": 0,
                                "minCartPrice": 0,
                                "enabled": false
                            },
                            "description": "description",
                            "name": "Cordon bleu au kg",
                            "price": 67.95,
                            "imageUrl": "https://kaalixzone1.s3.amazonaws.com/images/606dc7c64a4dfb4e3f8a777ei0Pq.jpg",
                            "inStock": true,
                            "specifications": [],
                            "isMainPromo": false,
                            "createdAt": null,
                            "updatedAt": null,
                            "_id": "611e89ae636ae400229ed2c0",
                            "storeId": "611e88ac636ae400229ec741",
                            "productId": "611e898d636ae400229ec85a",
                            "__v": 0
                        },
                        {
                            "promoSetting": {
                                "value": 0,
                                "minCartPrice": 0,
                                "enabled": false
                            },
                            "description": "description",
                            "name": "Nugget au kg",
                            "price": 52.95,
                            "imageUrl": "https://kaalixzone1.s3.amazonaws.com/images/606dc7d34a4dfb4e3f8a777fSXg0.jpg",
                            "inStock": true,
                            "specifications": [],
                            "isMainPromo": false,
                            "createdAt": null,
                            "updatedAt": null,
                            "_id": "611e89af636ae400229ed3f5",
                            "storeId": "611e88ac636ae400229ec741",
                            "productId": "611e898d636ae400229ec85a",
                            "__v": 0
                        },
                        {
                            "promoSetting": {
                                "value": 0,
                                "minCartPrice": 0,
                                "enabled": false
                            },
                            "description": "description",
                            "name": "Filet de poulet au kg",
                            "price": 55.95,
                            "imageUrl": "https://kaalixzone1.s3.amazonaws.com/images/606dc7954a4dfb4e3f8a7778cOAw.jpg",
                            "inStock": true,
                            "specifications": [],
                            "isMainPromo": false,
                            "createdAt": null,
                            "updatedAt": null,
                            "_id": "611e89af636ae400229ed524",
                            "storeId": "611e88ac636ae400229ec741",
                            "productId": "611e898d636ae400229ec85a",
                            "__v": 0
                        }
                    ],
                    "productId": "611e898d636ae400229ec85a",
                    "product": {
                        "name": "Volaille",
                        "description": "description",
                        "inStock": true,
                        "imageUrl": "imageUrl",
                        "createdAt": "2021-08-19T16:40:45.843Z",
                        "updatedAt": "2021-08-19T16:40:45.843Z",
                        "_id": "611e898d636ae400229ec85a",
                        "productCategoryId": "611e8965636ae400229ec796",
                        "storeId": "611e88ac636ae400229ec741",
                        "__v": 0
                    }
                }
            ],
            "productCategory": {
                "name": "Boucherie",
                "description": "description",
                "inStock": true,
                "imageUrl": "https://kaalixzone1.s3.amazonaws.com/images/606f0a278ad00d53b34a4e34jWXn.jpg",
                "createdAt": "2021-08-19T16:40:05.589Z",
                "updatedAt": "2021-08-19T16:40:05.589Z",
                "_id": "611e8965636ae400229ec796",
                "storeId": "611e88ac636ae400229ec741",
                "__v": 0
            }
        },
        {
            "productCategoryId": "611e8965636ae400229ec78c",
            "products": [
                {
                    "items": [
                        {
                            "promoSetting": {
                                "value": 0,
                                "minCartPrice": 0,
                                "enabled": false
                            },
                            "description": "description",
                            "name": "Serviettes hygiéniques douceur de coton think long x8 Always",
                            "price": 8.95,
                            "imageUrl": "https://kaalixzone1.s3.amazonaws.com/images/606dcd7a4a4dfb4e3f8a77acNjlx.jpg",
                            "inStock": true,
                            "specifications": [],
                            "isMainPromo": false,
                            "createdAt": null,
                            "updatedAt": null,
                            "_id": "611e89ae636ae400229ed270",
                            "storeId": "611e88ac636ae400229ec741",
                            "productId": "611e898d636ae400229ec7cd",
                            "__v": 0
                        },
                        {
                            "promoSetting": {
                                "value": 0,
                                "minCartPrice": 0,
                                "enabled": false
                            },
                            "description": "description",
                            "name": "Serviettes hygiéniques maxi long x9 Mia",
                            "price": 8.95,
                            "imageUrl": "https://kaalixzone1.s3.amazonaws.com/images/606dcdbd9fc9d956e21cdf57LH9F.jpg",
                            "inStock": true,
                            "specifications": [],
                            "isMainPromo": false,
                            "createdAt": null,
                            "updatedAt": null,
                            "_id": "611e89ae636ae400229ed27c",
                            "storeId": "611e88ac636ae400229ec741",
                            "productId": "611e898d636ae400229ec7cd",
                            "__v": 0
                        },
                        {
                            "promoSetting": {
                                "value": 0,
                                "minCartPrice": 0,
                                "enabled": false
                            },
                            "description": "description",
                            "name": "Serviettes hygiéniques maxi normal x10 Mia",
                            "price": 8.95,
                            "imageUrl": "https://kaalixzone1.s3.amazonaws.com/images/606dcdb19fc9d956e21cdf56gDG1.jpg",
                            "inStock": true,
                            "specifications": [],
                            "isMainPromo": false,
                            "createdAt": null,
                            "updatedAt": null,
                            "_id": "611e89af636ae400229ed3f1",
                            "storeId": "611e88ac636ae400229ec741",
                            "productId": "611e898d636ae400229ec7cd",
                            "__v": 0
                        },
                        {
                            "promoSetting": {
                                "value": 0,
                                "minCartPrice": 0,
                                "enabled": false
                            },
                            "description": "description",
                            "name": "Tampons sans applicateur normal x16 OB",
                            "price": 48.95,
                            "imageUrl": "https://kaalixzone1.s3.amazonaws.com/images/606dcbdb4a4dfb4e3f8a77a2MKs7.jpg",
                            "inStock": true,
                            "specifications": [],
                            "isMainPromo": false,
                            "createdAt": null,
                            "updatedAt": null,
                            "_id": "611e89af636ae400229ed511",
                            "storeId": "611e88ac636ae400229ec741",
                            "productId": "611e898d636ae400229ec7cd",
                            "__v": 0
                        },
                        {
                            "promoSetting": {
                                "value": 0,
                                "minCartPrice": 0,
                                "enabled": false
                            },
                            "description": "description",
                            "name": "Protège-slips normal en coton x20 Carefree",
                            "price": 26.5,
                            "imageUrl": "https://kaalixzone1.s3.amazonaws.com/images/606dcde99fc9d956e21cdf5anE1O.jpg",
                            "inStock": true,
                            "specifications": [],
                            "isMainPromo": false,
                            "createdAt": null,
                            "updatedAt": null,
                            "_id": "611e89af636ae400229ed517",
                            "storeId": "611e88ac636ae400229ec741",
                            "productId": "611e898d636ae400229ec7cd",
                            "__v": 0
                        },
                        {
                            "promoSetting": {
                                "value": 0,
                                "minCartPrice": 0,
                                "enabled": false
                            },
                            "description": "description",
                            "name": "Tampons sans applicateur super x16 OB",
                            "price": 46.5,
                            "imageUrl": "https://kaalixzone1.s3.amazonaws.com/images/606dcbe74a4dfb4e3f8a77a3iTpi.jpg",
                            "inStock": true,
                            "specifications": [],
                            "isMainPromo": false,
                            "createdAt": null,
                            "updatedAt": null,
                            "_id": "611e89af636ae400229ed522",
                            "storeId": "611e88ac636ae400229ec741",
                            "productId": "611e898d636ae400229ec7cd",
                            "__v": 0
                        },
                        {
                            "promoSetting": {
                                "value": 0,
                                "minCartPrice": 0,
                                "enabled": false
                            },
                            "description": "description",
                            "name": "Serviettes hygiéniques maxi extra nuit Nana x8",
                            "price": 12.95,
                            "imageUrl": "https://kaalixzone1.s3.amazonaws.com/images/606dcdfc9fc9d956e21cdf5b59E3.jpg",
                            "inStock": true,
                            "specifications": [],
                            "isMainPromo": false,
                            "createdAt": null,
                            "updatedAt": null,
                            "_id": "611e89af636ae400229ed526",
                            "storeId": "611e88ac636ae400229ec741",
                            "productId": "611e898d636ae400229ec7cd",
                            "__v": 0
                        },
                        {
                            "promoSetting": {
                                "value": 0,
                                "minCartPrice": 0,
                                "enabled": false
                            },
                            "description": "description",
                            "name": "Serviettes hygiéniques Maxi long x9 Always",
                            "price": 12.5,
                            "imageUrl": "https://kaalixzone1.s3.amazonaws.com/images/606dce034a4dfb4e3f8a77b1EP0O.jpg",
                            "inStock": true,
                            "specifications": [],
                            "isMainPromo": false,
                            "createdAt": null,
                            "updatedAt": null,
                            "_id": "611e89af636ae400229ed55b",
                            "storeId": "611e88ac636ae400229ec741",
                            "productId": "611e898d636ae400229ec7cd",
                            "__v": 0
                        },
                        {
                            "promoSetting": {
                                "value": 0,
                                "minCartPrice": 0,
                                "enabled": false
                            },
                            "description": "description",
                            "name": "Serviettes hygiéniques maxi plus super x9 Nana",
                            "price": 12.15,
                            "imageUrl": "https://kaalixzone1.s3.amazonaws.com/images/606dce559fc9d956e21cdf5fuDOy.jpg",
                            "inStock": true,
                            "specifications": [],
                            "isMainPromo": false,
                            "createdAt": null,
                            "updatedAt": null,
                            "_id": "611e89af636ae400229ed55d",
                            "storeId": "611e88ac636ae400229ec741",
                            "productId": "611e898d636ae400229ec7cd",
                            "__v": 0
                        },
                        {
                            "promoSetting": {
                                "value": 0,
                                "minCartPrice": 0,
                                "enabled": false
                            },
                            "description": "description",
                            "name": "Serviettes hygiéniques maxi nuit x8 Mia",
                            "price": 8.95,
                            "imageUrl": "https://kaalixzone1.s3.amazonaws.com/images/606dcda04a4dfb4e3f8a77adFtDu.jpg",
                            "inStock": true,
                            "specifications": [],
                            "isMainPromo": false,
                            "createdAt": null,
                            "updatedAt": null,
                            "_id": "611e89af636ae400229ed55f",
                            "storeId": "611e88ac636ae400229ec741",
                            "productId": "611e898d636ae400229ec7cd",
                            "__v": 0
                        },
                        {
                            "promoSetting": {
                                "value": 0,
                                "minCartPrice": 0,
                                "enabled": false
                            },
                            "description": "description",
                            "name": "Serviettes hygiéniques maxi extra long x8 Always",
                            "price": 13.9,
                            "imageUrl": "https://kaalixzone1.s3.amazonaws.com/images/606dce1d9fc9d956e21cdf5eSACq.jpg",
                            "inStock": true,
                            "specifications": [],
                            "isMainPromo": false,
                            "createdAt": null,
                            "updatedAt": null,
                            "_id": "611e89af636ae400229ed56d",
                            "storeId": "611e88ac636ae400229ec741",
                            "productId": "611e898d636ae400229ec7cd",
                            "__v": 0
                        },
                        {
                            "promoSetting": {
                                "value": 0,
                                "minCartPrice": 0,
                                "enabled": false
                            },
                            "description": "description",
                            "name": "Serviettes hygiéniques maternité Periodex x15",
                            "price": 17.5,
                            "imageUrl": "https://kaalixzone1.s3.amazonaws.com/images/606dce0c9fc9d956e21cdf5dQhKN.jpg",
                            "inStock": true,
                            "specifications": [],
                            "isMainPromo": false,
                            "createdAt": null,
                            "updatedAt": null,
                            "_id": "611e89af636ae400229ed582",
                            "storeId": "611e88ac636ae400229ec741",
                            "productId": "611e898d636ae400229ec7cd",
                            "__v": 0
                        },
                        {
                            "promoSetting": {
                                "value": 0,
                                "minCartPrice": 0,
                                "enabled": false
                            },
                            "description": "description",
                            "name": "Protège-slips douceur extrême normal x20 Nana",
                            "price": 12.95,
                            "imageUrl": "https://kaalixzone1.s3.amazonaws.com/images/606dce4d4a4dfb4e3f8a77b3FsnD.jpg",
                            "inStock": true,
                            "specifications": [],
                            "isMainPromo": false,
                            "createdAt": null,
                            "updatedAt": null,
                            "_id": "611e89b0636ae400229ed5b9",
                            "storeId": "611e88ac636ae400229ec741",
                            "productId": "611e898d636ae400229ec7cd",
                            "__v": 0
                        },
                        {
                            "promoSetting": {
                                "value": 0,
                                "minCartPrice": 0,
                                "enabled": false
                            },
                            "description": "description",
                            "name": "Serviettes hygiéniques maxi normal x10 Nana",
                            "price": 12.15,
                            "imageUrl": "https://kaalixzone1.s3.amazonaws.com/images/606dce1d4a4dfb4e3f8a77b2jT2r.jpg",
                            "inStock": true,
                            "specifications": [],
                            "isMainPromo": false,
                            "createdAt": null,
                            "updatedAt": null,
                            "_id": "611e89b0636ae400229ed5c5",
                            "storeId": "611e88ac636ae400229ec741",
                            "productId": "611e898d636ae400229ec7cd",
                            "__v": 0
                        }
                    ],
                    "productId": "611e898d636ae400229ec7cd",
                    "product": {
                        "name": "Hygiène Féminine",
                        "description": "description",
                        "inStock": true,
                        "imageUrl": "imageUrl",
                        "createdAt": "2021-08-19T16:40:45.601Z",
                        "updatedAt": "2021-08-19T16:40:45.601Z",
                        "_id": "611e898d636ae400229ec7cd",
                        "productCategoryId": "611e8965636ae400229ec78c",
                        "storeId": "611e88ac636ae400229ec741",
                        "__v": 0
                    }
                },
                {
                    "items": [
                        {
                            "promoSetting": {
                                "value": 0,
                                "minCartPrice": 0,
                                "enabled": false
                            },
                            "description": "description",
                            "name": "BB crème medium 50ml",
                            "price": 84.95,
                            "imageUrl": "https://kaalixzone1.s3.amazonaws.com/images/606dd5489fc9d956e21cdf86mOn1.jpg",
                            "inStock": true,
                            "specifications": [],
                            "isMainPromo": false,
                            "createdAt": null,
                            "updatedAt": null,
                            "_id": "611e89b0636ae400229ed778",
                            "storeId": "611e88ac636ae400229ec741",
                            "productId": "611e898d636ae400229ec7d1",
                            "__v": 0
                        }
                    ],
                    "productId": "611e898d636ae400229ec7d1",
                    "product": {
                        "name": "Maquillage",
                        "description": "description",
                        "inStock": true,
                        "imageUrl": "imageUrl",
                        "createdAt": "2021-08-19T16:40:45.606Z",
                        "updatedAt": "2021-08-19T16:40:45.606Z",
                        "_id": "611e898d636ae400229ec7d1",
                        "productCategoryId": "611e8965636ae400229ec78c",
                        "storeId": "611e88ac636ae400229ec741",
                        "__v": 0
                    }
                },
                {
                    "items": [
                        {
                            "promoSetting": {
                                "value": 0,
                                "minCartPrice": 0,
                                "enabled": false
                            },
                            "description": "description",
                            "name": "Crème nourrissante pour visage et corps 200ml Nivea",
                            "price": 50.95,
                            "imageUrl": "https://kaalixzone1.s3.amazonaws.com/images/606dcb189fc9d956e21cdf47sJFt.jpg",
                            "inStock": true,
                            "specifications": [],
                            "isMainPromo": false,
                            "createdAt": null,
                            "updatedAt": null,
                            "_id": "611e89ae636ae400229ed10a",
                            "storeId": "611e88ac636ae400229ec741",
                            "productId": "611e898d636ae400229ec7cb",
                            "__v": 0
                        },
                        {
                            "promoSetting": {
                                "value": 0,
                                "minCartPrice": 0,
                                "enabled": false
                            },
                            "description": "description",
                            "name": "Crème hydratante Soft 50ml Nivea",
                            "price": 13.5,
                            "imageUrl": "https://kaalixzone1.s3.amazonaws.com/images/606dcb494a4dfb4e3f8a77a0B4au.jpg",
                            "inStock": true,
                            "specifications": [],
                            "isMainPromo": false,
                            "createdAt": null,
                            "updatedAt": null,
                            "_id": "611e89ae636ae400229ed22d",
                            "storeId": "611e88ac636ae400229ec741",
                            "productId": "611e898d636ae400229ec7cb",
                            "__v": 0
                        },
                        {
                            "promoSetting": {
                                "value": 0,
                                "minCartPrice": 0,
                                "enabled": false
                            },
                            "description": "description",
                            "name": "Gel vaseline enrichi en beurre de cacao 100ml",
                            "price": 22.95,
                            "imageUrl": "https://kaalixzone1.s3.amazonaws.com/images/606dcb2e4a4dfb4e3f8a779fkA7C.jpg",
                            "inStock": true,
                            "specifications": [],
                            "isMainPromo": false,
                            "createdAt": null,
                            "updatedAt": null,
                            "_id": "611e89ae636ae400229ed239",
                            "storeId": "611e88ac636ae400229ec741",
                            "productId": "611e898d636ae400229ec7cb",
                            "__v": 0
                        },
                        {
                            "promoSetting": {
                                "value": 0,
                                "minCartPrice": 0,
                                "enabled": false
                            },
                            "description": "description",
                            "name": "Gel vaseline original 100ml",
                            "price": 22.95,
                            "imageUrl": "https://kaalixzone1.s3.amazonaws.com/images/606dcb214a4dfb4e3f8a779eJuTW.jpg",
                            "inStock": true,
                            "specifications": [],
                            "isMainPromo": false,
                            "createdAt": null,
                            "updatedAt": null,
                            "_id": "611e89ae636ae400229ed252",
                            "storeId": "611e88ac636ae400229ec741",
                            "productId": "611e898d636ae400229ec7cb",
                            "__v": 0
                        },
                        {
                            "promoSetting": {
                                "value": 0,
                                "minCartPrice": 0,
                                "enabled": false
                            },
                            "description": "description",
                            "name": "Lait de toilette très doux 250ml Mixa",
                            "price": 37.95,
                            "imageUrl": "https://kaalixzone1.s3.amazonaws.com/images/606dcabc9fc9d956e21cdf43DRNG.jpg",
                            "inStock": true,
                            "specifications": [],
                            "isMainPromo": false,
                            "createdAt": null,
                            "updatedAt": null,
                            "_id": "611e89ae636ae400229ed274",
                            "storeId": "611e88ac636ae400229ec741",
                            "productId": "611e898d636ae400229ec7cb",
                            "__v": 0
                        },
                        {
                            "promoSetting": {
                                "value": 0,
                                "minCartPrice": 0,
                                "enabled": false
                            },
                            "description": "description",
                            "name": "Crème de soin hydratante Soft 200ml Nivea",
                            "price": 39.95,
                            "imageUrl": "https://kaalixzone1.s3.amazonaws.com/images/606dc9d44a4dfb4e3f8a7792HDDc.jpg",
                            "inStock": true,
                            "specifications": [],
                            "isMainPromo": false,
                            "createdAt": null,
                            "updatedAt": null,
                            "_id": "611e89ae636ae400229ed276",
                            "storeId": "611e88ac636ae400229ec741",
                            "productId": "611e898d636ae400229ec7cb",
                            "__v": 0
                        },
                        {
                            "promoSetting": {
                                "value": 0,
                                "minCartPrice": 0,
                                "enabled": false
                            },
                            "description": "description",
                            "name": "Crème de soin hydratante Nivea 75 ml",
                            "price": 21.95,
                            "imageUrl": "https://kaalixzone1.s3.amazonaws.com/images/606dc9a39fc9d956e21cdf38uwox.jpg",
                            "inStock": true,
                            "specifications": [],
                            "isMainPromo": false,
                            "createdAt": null,
                            "updatedAt": null,
                            "_id": "611e89ae636ae400229ed278",
                            "storeId": "611e88ac636ae400229ec741",
                            "productId": "611e898d636ae400229ec7cb",
                            "__v": 0
                        },
                        {
                            "promoSetting": {
                                "value": 0,
                                "minCartPrice": 0,
                                "enabled": false
                            },
                            "description": "description",
                            "name": "Lait corps à l'huile d'olive 400ml",
                            "price": 39.95,
                            "imageUrl": "https://kaalixzone1.s3.amazonaws.com/images/606dcad79fc9d956e21cdf444rJE.jpg",
                            "inStock": true,
                            "specifications": [],
                            "isMainPromo": false,
                            "createdAt": null,
                            "updatedAt": null,
                            "_id": "611e89ae636ae400229ed27a",
                            "storeId": "611e88ac636ae400229ec741",
                            "productId": "611e898d636ae400229ec7cb",
                            "__v": 0
                        },
                        {
                            "promoSetting": {
                                "value": 0,
                                "minCartPrice": 0,
                                "enabled": false
                            },
                            "description": "description",
                            "name": "Crème hydratante multi-usages 60ml Nivea",
                            "price": 13.5,
                            "imageUrl": "https://kaalixzone1.s3.amazonaws.com/images/606dcbb69fc9d956e21cdf4cM9q9.jpg",
                            "inStock": true,
                            "specifications": [],
                            "isMainPromo": false,
                            "createdAt": null,
                            "updatedAt": null,
                            "_id": "611e89ae636ae400229ed27e",
                            "storeId": "611e88ac636ae400229ec741",
                            "productId": "611e898d636ae400229ec7cb",
                            "__v": 0
                        },
                        {
                            "promoSetting": {
                                "value": 0,
                                "minCartPrice": 0,
                                "enabled": false
                            },
                            "description": "description",
                            "name": "Lait crème douceur  pour peaux sèches 250ml Nivea",
                            "price": 43.95,
                            "imageUrl": "https://kaalixzone1.s3.amazonaws.com/images/606dcb594a4dfb4e3f8a77a1NHMK.jpg",
                            "inStock": true,
                            "specifications": [],
                            "isMainPromo": false,
                            "createdAt": null,
                            "updatedAt": null,
                            "_id": "611e89ae636ae400229ed2bc",
                            "storeId": "611e88ac636ae400229ec741",
                            "productId": "611e898d636ae400229ec7cb",
                            "__v": 0
                        },
                        {
                            "promoSetting": {
                                "value": 0,
                                "minCartPrice": 0,
                                "enabled": false
                            },
                            "description": "description",
                            "name": "Huile d’argan 50ml Tiyya",
                            "price": 59.95,
                            "imageUrl": "https://kaalixzone1.s3.amazonaws.com/images/606dcbaa9fc9d956e21cdf4bnIod.jpg",
                            "inStock": true,
                            "specifications": [],
                            "isMainPromo": false,
                            "createdAt": null,
                            "updatedAt": null,
                            "_id": "611e89ae636ae400229ed2c2",
                            "storeId": "611e88ac636ae400229ec741",
                            "productId": "611e898d636ae400229ec7cb",
                            "__v": 0
                        },
                        {
                            "promoSetting": {
                                "value": 0,
                                "minCartPrice": 0,
                                "enabled": false
                            },
                            "description": "description",
                            "name": "Crème pour le corps hydratante 30ml Nivea",
                            "price": 11.75,
                            "imageUrl": "https://kaalixzone1.s3.amazonaws.com/images/606dcbc29fc9d956e21cdf4e1bwS.jpg",
                            "inStock": true,
                            "specifications": [],
                            "isMainPromo": false,
                            "createdAt": null,
                            "updatedAt": null,
                            "_id": "611e89af636ae400229ed3ed",
                            "storeId": "611e88ac636ae400229ec741",
                            "productId": "611e898d636ae400229ec7cb",
                            "__v": 0
                        },
                        {
                            "promoSetting": {
                                "value": 0,
                                "minCartPrice": 0,
                                "enabled": false
                            },
                            "description": "description",
                            "name": "Lait-huile fleur de cerisier et huile de Jojoba 200ml",
                            "price": 34.95,
                            "imageUrl": "https://kaalixzone1.s3.amazonaws.com/images/606dcaea9fc9d956e21cdf45Gxl0.jpg",
                            "inStock": true,
                            "specifications": [],
                            "isMainPromo": false,
                            "createdAt": null,
                            "updatedAt": null,
                            "_id": "611e89af636ae400229ed3f3",
                            "storeId": "611e88ac636ae400229ec741",
                            "productId": "611e898d636ae400229ec7cb",
                            "__v": 0
                        },
                        {
                            "promoSetting": {
                                "value": 0,
                                "minCartPrice": 0,
                                "enabled": false
                            },
                            "description": "description",
                            "name": "Crème nourrissante 100ml Nivea",
                            "price": 18.95,
                            "imageUrl": "https://kaalixzone1.s3.amazonaws.com/images/606dcaf79fc9d956e21cdf46nb8c.jpg",
                            "inStock": true,
                            "specifications": [],
                            "isMainPromo": false,
                            "createdAt": null,
                            "updatedAt": null,
                            "_id": "611e89af636ae400229ed515",
                            "storeId": "611e88ac636ae400229ec741",
                            "productId": "611e898d636ae400229ec7cb",
                            "__v": 0
                        },
                        {
                            "promoSetting": {
                                "value": 0,
                                "minCartPrice": 0,
                                "enabled": false
                            },
                            "description": "description",
                            "name": "Crème multi-usage Nivea Men 75ml",
                            "price": 23.95,
                            "imageUrl": "https://kaalixzone1.s3.amazonaws.com/images/606dc96c4a4dfb4e3f8a778el536.jpg",
                            "inStock": true,
                            "specifications": [],
                            "isMainPromo": false,
                            "createdAt": null,
                            "updatedAt": null,
                            "_id": "611e89af636ae400229ed528",
                            "storeId": "611e88ac636ae400229ec741",
                            "productId": "611e898d636ae400229ec7cb",
                            "__v": 0
                        },
                        {
                            "promoSetting": {
                                "value": 0,
                                "minCartPrice": 0,
                                "enabled": false
                            },
                            "description": "description",
                            "name": "Crème pour les mains 150ml Atrix",
                            "price": 33.95,
                            "imageUrl": "https://kaalixzone1.s3.amazonaws.com/images/606dcb3f9fc9d956e21cdf48WW6k.jpg",
                            "inStock": true,
                            "specifications": [],
                            "isMainPromo": false,
                            "createdAt": null,
                            "updatedAt": null,
                            "_id": "611e89af636ae400229ed52a",
                            "storeId": "611e88ac636ae400229ec741",
                            "productId": "611e898d636ae400229ec7cb",
                            "__v": 0
                        },
                        {
                            "promoSetting": {
                                "value": 0,
                                "minCartPrice": 0,
                                "enabled": false
                            },
                            "description": "description",
                            "name": "Lait crème nourrissant Peaux sensibles Nivea 250ml",
                            "price": 39.9,
                            "imageUrl": "https://kaalixzone1.s3.amazonaws.com/images/606dcb004a4dfb4e3f8a779dunuI.jpg",
                            "inStock": true,
                            "specifications": [],
                            "isMainPromo": false,
                            "createdAt": null,
                            "updatedAt": null,
                            "_id": "611e89af636ae400229ed567",
                            "storeId": "611e88ac636ae400229ec741",
                            "productId": "611e898d636ae400229ec7cb",
                            "__v": 0
                        },
                        {
                            "promoSetting": {
                                "value": 0,
                                "minCartPrice": 0,
                                "enabled": false
                            },
                            "description": "description",
                            "name": "Lait Crème Nourrissant 400ml Nivea",
                            "price": 58.95,
                            "imageUrl": "https://kaalixzone1.s3.amazonaws.com/images/606dcb909fc9d956e21cdf495a7L.jpg",
                            "inStock": true,
                            "specifications": [],
                            "isMainPromo": false,
                            "createdAt": null,
                            "updatedAt": null,
                            "_id": "611e89af636ae400229ed569",
                            "storeId": "611e88ac636ae400229ec741",
                            "productId": "611e898d636ae400229ec7cb",
                            "__v": 0
                        },
                        {
                            "promoSetting": {
                                "value": 0,
                                "minCartPrice": 0,
                                "enabled": false
                            },
                            "description": "description",
                            "name": "Lait fluide hydratant express 400ml",
                            "price": 58.95,
                            "imageUrl": "https://kaalixzone1.s3.amazonaws.com/images/606dcb9f9fc9d956e21cdf4a2Oa2.jpg",
                            "inStock": true,
                            "specifications": [],
                            "isMainPromo": false,
                            "createdAt": null,
                            "updatedAt": null,
                            "_id": "611e89af636ae400229ed56b",
                            "storeId": "611e88ac636ae400229ec741",
                            "productId": "611e898d636ae400229ec7cb",
                            "__v": 0
                        }
                    ],
                    "productId": "611e898d636ae400229ec7cb",
                    "product": {
                        "name": "Lait Hydratants, Soins du Corps",
                        "description": "description",
                        "inStock": true,
                        "imageUrl": "imageUrl",
                        "createdAt": "2021-08-19T16:40:45.598Z",
                        "updatedAt": "2021-08-19T16:40:45.598Z",
                        "_id": "611e898d636ae400229ec7cb",
                        "productCategoryId": "611e8965636ae400229ec78c",
                        "storeId": "611e88ac636ae400229ec741",
                        "__v": 0
                    }
                },
                {
                    "items": [
                        {
                            "promoSetting": {
                                "value": 0,
                                "minCartPrice": 0,
                                "enabled": false
                            },
                            "description": "description",
                            "name": "Déodorant double effect Nivea 50ml",
                            "price": 31.95,
                            "imageUrl": "https://kaalixzone1.s3.amazonaws.com/images/606ec339df68d85aa5c94eaeTVWH.jpg",
                            "inStock": true,
                            "specifications": [],
                            "isMainPromo": false,
                            "createdAt": null,
                            "updatedAt": null,
                            "_id": "611e89b0636ae400229ed85c",
                            "storeId": "611e88ac636ae400229ec741",
                            "productId": "611e898d636ae400229ec7dc",
                            "__v": 0
                        }
                    ],
                    "productId": "611e898d636ae400229ec7dc",
                    "product": {
                        "name": "Deodorant",
                        "description": "description",
                        "inStock": true,
                        "imageUrl": "imageUrl",
                        "createdAt": "2021-08-19T16:40:45.640Z",
                        "updatedAt": "2021-08-19T16:40:45.640Z",
                        "_id": "611e898d636ae400229ec7dc",
                        "productCategoryId": "611e8965636ae400229ec78c",
                        "storeId": "611e88ac636ae400229ec741",
                        "__v": 0
                    }
                },
                {
                    "items": [
                        {
                            "promoSetting": {
                                "value": 0,
                                "minCartPrice": 0,
                                "enabled": false
                            },
                            "description": "description",
                            "name": "Coloration Cheveux Excellence Crème 5.3 Châtain clair doré Imedia",
                            "price": 102.95,
                            "imageUrl": "https://kaalixzone1.s3.amazonaws.com/images/606dd9b64a4dfb4e3f8a780dqQ7H.jpg",
                            "inStock": true,
                            "specifications": [],
                            "isMainPromo": false,
                            "createdAt": null,
                            "updatedAt": null,
                            "_id": "611e89b0636ae400229ed758",
                            "storeId": "611e88ac636ae400229ec741",
                            "productId": "611e898d636ae400229ec7de",
                            "__v": 0
                        },
                        {
                            "promoSetting": {
                                "value": 0,
                                "minCartPrice": 0,
                                "enabled": false
                            },
                            "description": "description",
                            "name": "Coloration Cheveux Excellence Crème 1 Noir Imedia",
                            "price": 102.95,
                            "imageUrl": "https://kaalixzone1.s3.amazonaws.com/images/606dd995b7386a575992da310l7T.jpg",
                            "inStock": true,
                            "specifications": [],
                            "isMainPromo": false,
                            "createdAt": null,
                            "updatedAt": null,
                            "_id": "611e89b0636ae400229ed764",
                            "storeId": "611e88ac636ae400229ec741",
                            "productId": "611e898d636ae400229ec7de",
                            "__v": 0
                        },
                        {
                            "promoSetting": {
                                "value": 0,
                                "minCartPrice": 0,
                                "enabled": false
                            },
                            "description": "description",
                            "name": "Coloration Cheveux Excellence Crème 6.1 Blond foncé",
                            "price": 102.95,
                            "imageUrl": "https://kaalixzone1.s3.amazonaws.com/images/606dda19b7386a575992da37S40J.jpg",
                            "inStock": true,
                            "specifications": [],
                            "isMainPromo": false,
                            "createdAt": null,
                            "updatedAt": null,
                            "_id": "611e89b0636ae400229ed766",
                            "storeId": "611e88ac636ae400229ec741",
                            "productId": "611e898d636ae400229ec7de",
                            "__v": 0
                        },
                        {
                            "promoSetting": {
                                "value": 0,
                                "minCartPrice": 0,
                                "enabled": false
                            },
                            "description": "description",
                            "name": "Shampooing anti-pelliculaire soin complet 400ml Head & Shoulders",
                            "price": 31.5,
                            "imageUrl": "https://kaalixzone1.s3.amazonaws.com/images/606dd7d2b7386a575992da26p6Q4.jpg",
                            "inStock": true,
                            "specifications": [],
                            "isMainPromo": false,
                            "createdAt": null,
                            "updatedAt": null,
                            "_id": "611e89b0636ae400229ed770",
                            "storeId": "611e88ac636ae400229ec741",
                            "productId": "611e898d636ae400229ec7de",
                            "__v": 0
                        },
                        {
                            "promoSetting": {
                                "value": 0,
                                "minCartPrice": 0,
                                "enabled": false
                            },
                            "description": "description",
                            "name": "Coloration Cheveux Excellence Crème 3 Châtain foncé Imedia",
                            "price": 102.95,
                            "imageUrl": "https://kaalixzone1.s3.amazonaws.com/images/606dd9a4b7386a575992da32XTUP.jpg",
                            "inStock": true,
                            "specifications": [],
                            "isMainPromo": false,
                            "createdAt": null,
                            "updatedAt": null,
                            "_id": "611e89b0636ae400229ed79a",
                            "storeId": "611e88ac636ae400229ec741",
                            "productId": "611e898d636ae400229ec7de",
                            "__v": 0
                        },
                        {
                            "promoSetting": {
                                "value": 0,
                                "minCartPrice": 0,
                                "enabled": false
                            },
                            "description": "description",
                            "name": "Shampooing anti-pelliculaire Fraîcheur Menthol 360ml Head & Shoulders",
                            "price": 34.5,
                            "imageUrl": "https://kaalixzone1.s3.amazonaws.com/images/606dde704a4dfb4e3f8a782bXYBR.jpg",
                            "inStock": true,
                            "specifications": [],
                            "isMainPromo": false,
                            "createdAt": null,
                            "updatedAt": null,
                            "_id": "611e89b0636ae400229ed7c8",
                            "storeId": "611e88ac636ae400229ec741",
                            "productId": "611e898d636ae400229ec7de",
                            "__v": 0
                        },
                        {
                            "promoSetting": {
                                "value": 0,
                                "minCartPrice": 0,
                                "enabled": false
                            },
                            "description": "description",
                            "name": "Huile extraordinaire cheveux très secs Elseve 100ml",
                            "price": 102.95,
                            "imageUrl": "https://kaalixzone1.s3.amazonaws.com/images/606ebf4a2b43844f1c8bccfbctRM.jpg",
                            "inStock": true,
                            "specifications": [],
                            "isMainPromo": false,
                            "createdAt": null,
                            "updatedAt": null,
                            "_id": "611e89b0636ae400229ed7f2",
                            "storeId": "611e88ac636ae400229ec741",
                            "productId": "611e898d636ae400229ec7de",
                            "__v": 0
                        }
                    ],
                    "productId": "611e898d636ae400229ec7de",
                    "product": {
                        "name": "Cheveux",
                        "description": "description",
                        "inStock": true,
                        "imageUrl": "imageUrl",
                        "createdAt": "2021-08-19T16:40:45.641Z",
                        "updatedAt": "2021-08-19T16:40:45.641Z",
                        "_id": "611e898d636ae400229ec7de",
                        "productCategoryId": "611e8965636ae400229ec78c",
                        "storeId": "611e88ac636ae400229ec741",
                        "__v": 0
                    }
                },
                {
                    "items": [
                        {
                            "promoSetting": {
                                "value": 0,
                                "minCartPrice": 0,
                                "enabled": false
                            },
                            "description": "description",
                            "name": "Rasoir Flex 5 x2 Bic",
                            "price": 40.95,
                            "imageUrl": "https://kaalixzone1.s3.amazonaws.com/images/606dd5f24a4dfb4e3f8a77eeT4G5.jpg",
                            "inStock": true,
                            "specifications": [],
                            "isMainPromo": false,
                            "createdAt": null,
                            "updatedAt": null,
                            "_id": "611e89af636ae400229ed59e",
                            "storeId": "611e88ac636ae400229ec741",
                            "productId": "611e898d636ae400229ec858",
                            "__v": 0
                        },
                        {
                            "promoSetting": {
                                "value": 0,
                                "minCartPrice": 0,
                                "enabled": false
                            },
                            "description": "description",
                            "name": "Rasoir Flex 4 x3 Bic",
                            "price": 32.5,
                            "imageUrl": "https://kaalixzone1.s3.amazonaws.com/images/606dd60d9fc9d956e21cdf8ckDka.jpg",
                            "inStock": true,
                            "specifications": [],
                            "isMainPromo": false,
                            "createdAt": null,
                            "updatedAt": null,
                            "_id": "611e89b0636ae400229ed6da",
                            "storeId": "611e88ac636ae400229ec741",
                            "productId": "611e898d636ae400229ec858",
                            "__v": 0
                        },
                        {
                            "promoSetting": {
                                "value": 0,
                                "minCartPrice": 0,
                                "enabled": false
                            },
                            "description": "description",
                            "name": "Gel à raser pour peaux sensibles 200ml Gilette",
                            "price": 49.25,
                            "imageUrl": "https://kaalixzone1.s3.amazonaws.com/images/606dd57a4a4dfb4e3f8a77ec6i3f.jpg",
                            "inStock": true,
                            "specifications": [],
                            "isMainPromo": false,
                            "createdAt": null,
                            "updatedAt": null,
                            "_id": "611e89b0636ae400229ed742",
                            "storeId": "611e88ac636ae400229ec741",
                            "productId": "611e898d636ae400229ec858",
                            "__v": 0
                        },
                        {
                            "promoSetting": {
                                "value": 0,
                                "minCartPrice": 0,
                                "enabled": false
                            },
                            "description": "description",
                            "name": "Rasoirs Blue II x5 Gilette",
                            "price": 26.2,
                            "imageUrl": "https://kaalixzone1.s3.amazonaws.com/images/606dd63b9fc9d956e21cdf8fotAS.jpg",
                            "inStock": true,
                            "specifications": [],
                            "isMainPromo": false,
                            "createdAt": null,
                            "updatedAt": null,
                            "_id": "611e89b0636ae400229ed748",
                            "storeId": "611e88ac636ae400229ec741",
                            "productId": "611e898d636ae400229ec858",
                            "__v": 0
                        },
                        {
                            "promoSetting": {
                                "value": 0,
                                "minCartPrice": 0,
                                "enabled": false
                            },
                            "description": "description",
                            "name": "Rasoir Flex 3 Hybrid x4 Bic",
                            "price": 29.95,
                            "imageUrl": "https://kaalixzone1.s3.amazonaws.com/images/606dd61e4a4dfb4e3f8a77efpq9M.jpg",
                            "inStock": true,
                            "specifications": [],
                            "isMainPromo": false,
                            "createdAt": null,
                            "updatedAt": null,
                            "_id": "611e89b0636ae400229ed75a",
                            "storeId": "611e88ac636ae400229ec741",
                            "productId": "611e898d636ae400229ec858",
                            "__v": 0
                        }
                    ],
                    "productId": "611e898d636ae400229ec858",
                    "product": {
                        "name": "Rasage",
                        "description": "description",
                        "inStock": true,
                        "imageUrl": "imageUrl",
                        "createdAt": "2021-08-19T16:40:45.842Z",
                        "updatedAt": "2021-08-19T16:40:45.842Z",
                        "_id": "611e898d636ae400229ec858",
                        "productCategoryId": "611e8965636ae400229ec78c",
                        "storeId": "611e88ac636ae400229ec741",
                        "__v": 0
                    }
                },
                {
                    "items": [
                        {
                            "promoSetting": {
                                "value": 0,
                                "minCartPrice": 0,
                                "enabled": false
                            },
                            "description": "description",
                            "name": "Rasoirs jetables Simply Venus Gilette",
                            "price": 27.75,
                            "imageUrl": "https://kaalixzone1.s3.amazonaws.com/images/606dd786b7386a575992da22ZIXx.jpg",
                            "inStock": true,
                            "specifications": [],
                            "isMainPromo": false,
                            "createdAt": null,
                            "updatedAt": null,
                            "_id": "611e89b0636ae400229ed760",
                            "storeId": "611e88ac636ae400229ec741",
                            "productId": "611e898d636ae400229ec862",
                            "__v": 0
                        }
                    ],
                    "productId": "611e898d636ae400229ec862",
                    "product": {
                        "name": "Epilation Femme",
                        "description": "description",
                        "inStock": true,
                        "imageUrl": "imageUrl",
                        "createdAt": "2021-08-19T16:40:45.849Z",
                        "updatedAt": "2021-08-19T16:40:45.849Z",
                        "_id": "611e898d636ae400229ec862",
                        "productCategoryId": "611e8965636ae400229ec78c",
                        "storeId": "611e88ac636ae400229ec741",
                        "__v": 0
                    }
                },
                {
                    "items": [
                        {
                            "promoSetting": {
                                "value": 0,
                                "minCartPrice": 0,
                                "enabled": false
                            },
                            "description": "description",
                            "name": "Bain de bouche pro-exp 500ml Oral B",
                            "price": 37.95,
                            "imageUrl": "https://kaalixzone1.s3.amazonaws.com/images/606dd0034a4dfb4e3f8a77c1WaAz.jpg",
                            "inStock": true,
                            "specifications": [],
                            "isMainPromo": false,
                            "createdAt": null,
                            "updatedAt": null,
                            "_id": "611e89af636ae400229ed3eb",
                            "storeId": "611e88ac636ae400229ec741",
                            "productId": "611e898d636ae400229ec879",
                            "__v": 0
                        },
                        {
                            "promoSetting": {
                                "value": 0,
                                "minCartPrice": 0,
                                "enabled": false
                            },
                            "description": "description",
                            "name": "Dentifrice herbal Miswak 30g+15g",
                            "price": 3.1,
                            "imageUrl": "https://kaalixzone1.s3.amazonaws.com/images/606dcf4e4a4dfb4e3f8a77bcJGRr.jpg",
                            "inStock": true,
                            "specifications": [],
                            "isMainPromo": false,
                            "createdAt": null,
                            "updatedAt": null,
                            "_id": "611e89af636ae400229ed3ef",
                            "storeId": "611e88ac636ae400229ec741",
                            "productId": "611e898d636ae400229ec879",
                            "__v": 0
                        },
                        {
                            "promoSetting": {
                                "value": 0,
                                "minCartPrice": 0,
                                "enabled": false
                            },
                            "description": "description",
                            "name": "Dentifrice anti-cavity fresh mint 125ml Crest",
                            "price": 14.95,
                            "imageUrl": "https://kaalixzone1.s3.amazonaws.com/images/606dcfa64a4dfb4e3f8a77belCUe.jpg",
                            "inStock": true,
                            "specifications": [],
                            "isMainPromo": false,
                            "createdAt": null,
                            "updatedAt": null,
                            "_id": "611e89af636ae400229ed561",
                            "storeId": "611e88ac636ae400229ec741",
                            "productId": "611e898d636ae400229ec879",
                            "__v": 0
                        },
                        {
                            "promoSetting": {
                                "value": 0,
                                "minCartPrice": 0,
                                "enabled": false
                            },
                            "description": "description",
                            "name": "Dentifrice kids princesse fraise Signal 75ml",
                            "price": 23.95,
                            "imageUrl": "https://kaalixzone1.s3.amazonaws.com/images/606dd0a64a4dfb4e3f8a77c7eWxO.jpg",
                            "inStock": true,
                            "specifications": [],
                            "isMainPromo": false,
                            "createdAt": null,
                            "updatedAt": null,
                            "_id": "611e89af636ae400229ed563",
                            "storeId": "611e88ac636ae400229ec741",
                            "productId": "611e898d636ae400229ec879",
                            "__v": 0
                        },
                        {
                            "promoSetting": {
                                "value": 0,
                                "minCartPrice": 0,
                                "enabled": false
                            },
                            "description": "description",
                            "name": "Dentifrice au charbon de bois naturals 75ml Colgate",
                            "price": 35.95,
                            "imageUrl": "https://kaalixzone1.s3.amazonaws.com/images/606dd00a9fc9d956e21cdf68jRt9.jpg",
                            "inStock": true,
                            "specifications": [],
                            "isMainPromo": false,
                            "createdAt": null,
                            "updatedAt": null,
                            "_id": "611e89af636ae400229ed580",
                            "storeId": "611e88ac636ae400229ec741",
                            "productId": "611e898d636ae400229ec879",
                            "__v": 0
                        },
                        {
                            "promoSetting": {
                                "value": 0,
                                "minCartPrice": 0,
                                "enabled": false
                            },
                            "description": "description",
                            "name": "Dentifrice kids  fraise Signal 75ml",
                            "price": 23.95,
                            "imageUrl": "https://kaalixzone1.s3.amazonaws.com/images/606dd0b49fc9d956e21cdf6cbBdo.jpg",
                            "inStock": true,
                            "specifications": [],
                            "isMainPromo": false,
                            "createdAt": null,
                            "updatedAt": null,
                            "_id": "611e89af636ae400229ed586",
                            "storeId": "611e88ac636ae400229ec741",
                            "productId": "611e898d636ae400229ec879",
                            "__v": 0
                        },
                        {
                            "promoSetting": {
                                "value": 0,
                                "minCartPrice": 0,
                                "enabled": false
                            },
                            "description": "description",
                            "name": "Dentifrice optic white blancheur 75ml Colgate",
                            "price": 32.95,
                            "imageUrl": "https://kaalixzone1.s3.amazonaws.com/images/606dd0b34a4dfb4e3f8a77c8b6I2.jpg",
                            "inStock": true,
                            "specifications": [],
                            "isMainPromo": false,
                            "createdAt": null,
                            "updatedAt": null,
                            "_id": "611e89af636ae400229ed588",
                            "storeId": "611e88ac636ae400229ec741",
                            "productId": "611e898d636ae400229ec879",
                            "__v": 0
                        },
                        {
                            "promoSetting": {
                                "value": 0,
                                "minCartPrice": 0,
                                "enabled": false
                            },
                            "description": "description",
                            "name": "Brosse à dents triple protection medium Signal",
                            "price": 6.75,
                            "imageUrl": "https://kaalixzone1.s3.amazonaws.com/images/606dcf414a4dfb4e3f8a77bb2NMB.jpg",
                            "inStock": true,
                            "specifications": [],
                            "isMainPromo": false,
                            "createdAt": null,
                            "updatedAt": null,
                            "_id": "611e89af636ae400229ed5a0",
                            "storeId": "611e88ac636ae400229ec741",
                            "productId": "611e898d636ae400229ec879",
                            "__v": 0
                        },
                        {
                            "promoSetting": {
                                "value": 0,
                                "minCartPrice": 0,
                                "enabled": false
                            },
                            "description": "description",
                            "name": "Dentifrice soin blancheur 75ml Sensodyne",
                            "price": 41.2,
                            "imageUrl": "https://kaalixzone1.s3.amazonaws.com/images/606dd11d9fc9d956e21cdf6enT8e.jpg",
                            "inStock": true,
                            "specifications": [],
                            "isMainPromo": false,
                            "createdAt": null,
                            "updatedAt": null,
                            "_id": "611e89af636ae400229ed5a2",
                            "storeId": "611e88ac636ae400229ec741",
                            "productId": "611e898d636ae400229ec879",
                            "__v": 0
                        },
                        {
                            "promoSetting": {
                                "value": 0,
                                "minCartPrice": 0,
                                "enabled": false
                            },
                            "description": "description",
                            "name": "Dentifrice nature coco blancheur 75ml Signal",
                            "price": 29.95,
                            "imageUrl": "https://kaalixzone1.s3.amazonaws.com/images/606dd0469fc9d956e21cdf6axIpM.jpg",
                            "inStock": true,
                            "specifications": [],
                            "isMainPromo": false,
                            "createdAt": null,
                            "updatedAt": null,
                            "_id": "611e89af636ae400229ed5a4",
                            "storeId": "611e88ac636ae400229ec741",
                            "productId": "611e898d636ae400229ec879",
                            "__v": 0
                        },
                        {
                            "promoSetting": {
                                "value": 0,
                                "minCartPrice": 0,
                                "enabled": false
                            },
                            "description": "description",
                            "name": "Dentifrice soin fraîcheur Signal 75ml",
                            "price": 23.95,
                            "imageUrl": "https://kaalixzone1.s3.amazonaws.com/images/606dd0544a4dfb4e3f8a77c4fNlK.jpg",
                            "inStock": true,
                            "specifications": [],
                            "isMainPromo": false,
                            "createdAt": null,
                            "updatedAt": null,
                            "_id": "611e89af636ae400229ed5a6",
                            "storeId": "611e88ac636ae400229ec741",
                            "productId": "611e898d636ae400229ec879",
                            "__v": 0
                        },
                        {
                            "promoSetting": {
                                "value": 0,
                                "minCartPrice": 0,
                                "enabled": false
                            },
                            "description": "description",
                            "name": "Dentifrice naturel charbon 75ml Signal",
                            "price": 29.95,
                            "imageUrl": "https://kaalixzone1.s3.amazonaws.com/images/606dd0394a4dfb4e3f8a77c2Pcyj.jpg",
                            "inStock": true,
                            "specifications": [],
                            "isMainPromo": false,
                            "createdAt": null,
                            "updatedAt": null,
                            "_id": "611e89b0636ae400229ed5b7",
                            "storeId": "611e88ac636ae400229ec741",
                            "productId": "611e898d636ae400229ec879",
                            "__v": 0
                        },
                        {
                            "promoSetting": {
                                "value": 0,
                                "minCartPrice": 0,
                                "enabled": false
                            },
                            "description": "description",
                            "name": "Dentifrice soin gencives 75ml Sensodyne",
                            "price": 41.2,
                            "imageUrl": "https://kaalixzone1.s3.amazonaws.com/images/606dd0fd4a4dfb4e3f8a77ca0Gpr.jpg",
                            "inStock": true,
                            "specifications": [],
                            "isMainPromo": false,
                            "createdAt": null,
                            "updatedAt": null,
                            "_id": "611e89b0636ae400229ed5bf",
                            "storeId": "611e88ac636ae400229ec741",
                            "productId": "611e898d636ae400229ec879",
                            "__v": 0
                        },
                        {
                            "promoSetting": {
                                "value": 0,
                                "minCartPrice": 0,
                                "enabled": false
                            },
                            "description": "description",
                            "name": "Dentifrice système blancheur 100ml Signal",
                            "price": 18.95,
                            "imageUrl": "https://kaalixzone1.s3.amazonaws.com/images/606dd0484a4dfb4e3f8a77c3jO0o.jpg",
                            "inStock": true,
                            "specifications": [],
                            "isMainPromo": false,
                            "createdAt": null,
                            "updatedAt": null,
                            "_id": "611e89b0636ae400229ed6d0",
                            "storeId": "611e88ac636ae400229ec741",
                            "productId": "611e898d636ae400229ec879",
                            "__v": 0
                        },
                        {
                            "promoSetting": {
                                "value": 0,
                                "minCartPrice": 0,
                                "enabled": false
                            },
                            "description": "description",
                            "name": "Dentifrice maxfresh clean menthe Colgate  75ml",
                            "price": 22.95,
                            "imageUrl": "https://kaalixzone1.s3.amazonaws.com/images/606dd0964a4dfb4e3f8a77c6JMGp.jpg",
                            "inStock": true,
                            "specifications": [],
                            "isMainPromo": false,
                            "createdAt": null,
                            "updatedAt": null,
                            "_id": "611e89b0636ae400229ed6d6",
                            "storeId": "611e88ac636ae400229ec741",
                            "productId": "611e898d636ae400229ec879",
                            "__v": 0
                        },
                        {
                            "promoSetting": {
                                "value": 0,
                                "minCartPrice": 0,
                                "enabled": false
                            },
                            "description": "description",
                            "name": "Dentifrice signal white now 75 ml",
                            "price": 34.95,
                            "imageUrl": "https://kaalixzone1.s3.amazonaws.com/images/606dd0754a4dfb4e3f8a77c5u4fo.jpg",
                            "inStock": true,
                            "specifications": [],
                            "isMainPromo": false,
                            "createdAt": null,
                            "updatedAt": null,
                            "_id": "611e89b0636ae400229ed6d8",
                            "storeId": "611e88ac636ae400229ec741",
                            "productId": "611e898d636ae400229ec879",
                            "__v": 0
                        },
                        {
                            "promoSetting": {
                                "value": 0,
                                "minCartPrice": 0,
                                "enabled": false
                            },
                            "description": "description",
                            "name": "Brosse à dents twister medium Colgate",
                            "price": 12.95,
                            "imageUrl": "https://kaalixzone1.s3.amazonaws.com/images/606dd0c54a4dfb4e3f8a77c9pdzj.jpg",
                            "inStock": true,
                            "specifications": [],
                            "isMainPromo": false,
                            "createdAt": null,
                            "updatedAt": null,
                            "_id": "611e89b0636ae400229ed6dc",
                            "storeId": "611e88ac636ae400229ec741",
                            "productId": "611e898d636ae400229ec879",
                            "__v": 0
                        },
                        {
                            "promoSetting": {
                                "value": 0,
                                "minCartPrice": 0,
                                "enabled": false
                            },
                            "description": "description",
                            "name": "Dentifrice soin complet 75ml Sensodyne",
                            "price": 41.2,
                            "imageUrl": "https://kaalixzone1.s3.amazonaws.com/images/606dd1084a4dfb4e3f8a77cbQYea.jpg",
                            "inStock": true,
                            "specifications": [],
                            "isMainPromo": false,
                            "createdAt": null,
                            "updatedAt": null,
                            "_id": "611e89b0636ae400229ed6e5",
                            "storeId": "611e88ac636ae400229ec741",
                            "productId": "611e898d636ae400229ec879",
                            "__v": 0
                        },
                        {
                            "promoSetting": {
                                "value": 0,
                                "minCartPrice": 0,
                                "enabled": false
                            },
                            "description": "description",
                            "name": "Dentifrice minions 50ml Colgate",
                            "price": 22.95,
                            "imageUrl": "https://kaalixzone1.s3.amazonaws.com/images/606dcfe59fc9d956e21cdf669pWD.jpg",
                            "inStock": true,
                            "specifications": [],
                            "isMainPromo": false,
                            "createdAt": null,
                            "updatedAt": null,
                            "_id": "611e89b0636ae400229ed73a",
                            "storeId": "611e88ac636ae400229ec741",
                            "productId": "611e898d636ae400229ec879",
                            "__v": 0
                        },
                        {
                            "promoSetting": {
                                "value": 0,
                                "minCartPrice": 0,
                                "enabled": false
                            },
                            "description": "description",
                            "name": "Dentifrice herbal Signal Miswak 120ml",
                            "price": 15.4,
                            "imageUrl": "https://kaalixzone1.s3.amazonaws.com/images/606dd1489fc9d956e21cdf709JxQ.jpg",
                            "inStock": true,
                            "specifications": [],
                            "isMainPromo": false,
                            "createdAt": null,
                            "updatedAt": null,
                            "_id": "611e89b0636ae400229ed73c",
                            "storeId": "611e88ac636ae400229ec741",
                            "productId": "611e898d636ae400229ec879",
                            "__v": 0
                        },
                        {
                            "promoSetting": {
                                "value": 0,
                                "minCartPrice": 0,
                                "enabled": false
                            },
                            "description": "description",
                            "name": "Brosse à dents double action medium Colgate",
                            "price": 7.95,
                            "imageUrl": "https://kaalixzone1.s3.amazonaws.com/images/606dd0199fc9d956e21cdf692PRO.jpg",
                            "inStock": true,
                            "specifications": [],
                            "isMainPromo": false,
                            "createdAt": null,
                            "updatedAt": null,
                            "_id": "611e89b0636ae400229ed744",
                            "storeId": "611e88ac636ae400229ec741",
                            "productId": "611e898d636ae400229ec879",
                            "__v": 0
                        },
                        {
                            "promoSetting": {
                                "value": 0,
                                "minCartPrice": 0,
                                "enabled": false
                            },
                            "description": "description",
                            "name": "Dentifrice triple action Colgate 75ml",
                            "price": 13.95,
                            "imageUrl": "https://kaalixzone1.s3.amazonaws.com/images/606dcff19fc9d956e21cdf67Kxlh.jpg",
                            "inStock": true,
                            "specifications": [],
                            "isMainPromo": false,
                            "createdAt": null,
                            "updatedAt": null,
                            "_id": "611e89b0636ae400229ed74a",
                            "storeId": "611e88ac636ae400229ec741",
                            "productId": "611e898d636ae400229ec879",
                            "__v": 0
                        }
                    ],
                    "productId": "611e898d636ae400229ec879",
                    "product": {
                        "name": "Hygiène Dentaire",
                        "description": "description",
                        "inStock": true,
                        "imageUrl": "imageUrl",
                        "createdAt": "2021-08-19T16:40:45.882Z",
                        "updatedAt": "2021-08-19T16:40:45.882Z",
                        "_id": "611e898d636ae400229ec879",
                        "productCategoryId": "611e8965636ae400229ec78c",
                        "storeId": "611e88ac636ae400229ec741",
                        "__v": 0
                    }
                },
                {
                    "items": [
                        {
                            "promoSetting": {
                                "value": 0,
                                "minCartPrice": 0,
                                "enabled": false
                            },
                            "description": "description",
                            "name": "Soin de jour antirides Q10 plus 50ml Nivea",
                            "price": 114.9,
                            "imageUrl": "https://kaalixzone1.s3.amazonaws.com/images/606dd1cf9fc9d956e21cdf71KWJx.jpg",
                            "inStock": true,
                            "specifications": [],
                            "isMainPromo": false,
                            "createdAt": null,
                            "updatedAt": null,
                            "_id": "611e89af636ae400229ed584",
                            "storeId": "611e88ac636ae400229ec741",
                            "productId": "611e898d636ae400229ec887",
                            "__v": 0
                        },
                        {
                            "promoSetting": {
                                "value": 0,
                                "minCartPrice": 0,
                                "enabled": false
                            },
                            "description": "description",
                            "name": "Crème de jour pour peaux sèchex Triple Active Nivea 50ml",
                            "price": 64.95,
                            "imageUrl": "https://kaalixzone1.s3.amazonaws.com/images/606dd39a4a4dfb4e3f8a77ddBITz.jpg",
                            "inStock": true,
                            "specifications": [],
                            "isMainPromo": false,
                            "createdAt": null,
                            "updatedAt": null,
                            "_id": "611e89af636ae400229ed59c",
                            "storeId": "611e88ac636ae400229ec741",
                            "productId": "611e898d636ae400229ec887",
                            "__v": 0
                        },
                        {
                            "promoSetting": {
                                "value": 0,
                                "minCartPrice": 0,
                                "enabled": false
                            },
                            "description": "description",
                            "name": "Soin de nuit Anti-Rides Q10 Plus Nivea 50ml",
                            "price": 114.9,
                            "imageUrl": "https://kaalixzone1.s3.amazonaws.com/images/606dd1e09fc9d956e21cdf72w8Ae.jpg",
                            "inStock": true,
                            "specifications": [],
                            "isMainPromo": false,
                            "createdAt": null,
                            "updatedAt": null,
                            "_id": "611e89b0636ae400229ed5bb",
                            "storeId": "611e88ac636ae400229ec741",
                            "productId": "611e898d636ae400229ec887",
                            "__v": 0
                        },
                        {
                            "promoSetting": {
                                "value": 0,
                                "minCartPrice": 0,
                                "enabled": false
                            },
                            "description": "description",
                            "name": "Gel anti points noirs incrustés 200ml",
                            "price": 52.95,
                            "imageUrl": "https://kaalixzone1.s3.amazonaws.com/images/606dd3489fc9d956e21cdf7eXyzP.jpg",
                            "inStock": true,
                            "specifications": [],
                            "isMainPromo": false,
                            "createdAt": null,
                            "updatedAt": null,
                            "_id": "611e89b0636ae400229ed5bd",
                            "storeId": "611e88ac636ae400229ec741",
                            "productId": "611e898d636ae400229ec887",
                            "__v": 0
                        },
                        {
                            "promoSetting": {
                                "value": 0,
                                "minCartPrice": 0,
                                "enabled": false
                            },
                            "description": "description",
                            "name": "Crème de visage éclaircissant 25g Fair & lovely",
                            "price": 11.5,
                            "imageUrl": "https://kaalixzone1.s3.amazonaws.com/images/606dd3879fc9d956e21cdf7fAUoG.jpg",
                            "inStock": true,
                            "specifications": [],
                            "isMainPromo": false,
                            "createdAt": null,
                            "updatedAt": null,
                            "_id": "611e89b0636ae400229ed5c1",
                            "storeId": "611e88ac636ae400229ec741",
                            "productId": "611e898d636ae400229ec887",
                            "__v": 0
                        },
                        {
                            "promoSetting": {
                                "value": 0,
                                "minCartPrice": 0,
                                "enabled": false
                            },
                            "description": "description",
                            "name": "Eau de rose 200ml Tiyya",
                            "price": 49.9,
                            "imageUrl": "https://kaalixzone1.s3.amazonaws.com/images/606dd3969fc9d956e21cdf80Liwm.jpg",
                            "inStock": true,
                            "specifications": [],
                            "isMainPromo": false,
                            "createdAt": null,
                            "updatedAt": null,
                            "_id": "611e89b0636ae400229ed5c3",
                            "storeId": "611e88ac636ae400229ec741",
                            "productId": "611e898d636ae400229ec887",
                            "__v": 0
                        },
                        {
                            "promoSetting": {
                                "value": 0,
                                "minCartPrice": 0,
                                "enabled": false
                            },
                            "description": "description",
                            "name": "Gel Nettoyant Purifiant pour peaux mixtes 150ml Nivea",
                            "price": 53.95,
                            "imageUrl": "https://kaalixzone1.s3.amazonaws.com/images/606dd2929fc9d956e21cdf78Cdk8.jpg",
                            "inStock": true,
                            "specifications": [],
                            "isMainPromo": false,
                            "createdAt": null,
                            "updatedAt": null,
                            "_id": "611e89b0636ae400229ed5c9",
                            "storeId": "611e88ac636ae400229ec741",
                            "productId": "611e898d636ae400229ec887",
                            "__v": 0
                        },
                        {
                            "promoSetting": {
                                "value": 0,
                                "minCartPrice": 0,
                                "enabled": false
                            },
                            "description": "description",
                            "name": "Crème soin de jour 24h hydratant Nivea 50ml",
                            "price": 61.25,
                            "imageUrl": "https://kaalixzone1.s3.amazonaws.com/images/606dd4564a4dfb4e3f8a77e6lWWz.jpg",
                            "inStock": true,
                            "specifications": [],
                            "isMainPromo": false,
                            "createdAt": null,
                            "updatedAt": null,
                            "_id": "611e89b0636ae400229ed6ce",
                            "storeId": "611e88ac636ae400229ec741",
                            "productId": "611e898d636ae400229ec887",
                            "__v": 0
                        },
                        {
                            "promoSetting": {
                                "value": 0,
                                "minCartPrice": 0,
                                "enabled": false
                            },
                            "description": "description",
                            "name": "Démaquillant yeux",
                            "price": 47.95,
                            "imageUrl": "https://kaalixzone1.s3.amazonaws.com/images/606dd4214a4dfb4e3f8a77e4lGZb.jpg",
                            "inStock": true,
                            "specifications": [],
                            "isMainPromo": false,
                            "createdAt": null,
                            "updatedAt": null,
                            "_id": "611e89b0636ae400229ed6d2",
                            "storeId": "611e88ac636ae400229ec741",
                            "productId": "611e898d636ae400229ec887",
                            "__v": 0
                        },
                        {
                            "promoSetting": {
                                "value": 0,
                                "minCartPrice": 0,
                                "enabled": false
                            },
                            "description": "description",
                            "name": "Masque tissu au charbon végétal SkinActive",
                            "price": 23.95,
                            "imageUrl": "https://kaalixzone1.s3.amazonaws.com/images/606dd27a4a4dfb4e3f8a77d7jBnR.jpg",
                            "inStock": true,
                            "specifications": [],
                            "isMainPromo": false,
                            "createdAt": null,
                            "updatedAt": null,
                            "_id": "611e89b0636ae400229ed6d4",
                            "storeId": "611e88ac636ae400229ec741",
                            "productId": "611e898d636ae400229ec887",
                            "__v": 0
                        },
                        {
                            "promoSetting": {
                                "value": 0,
                                "minCartPrice": 0,
                                "enabled": false
                            },
                            "description": "description",
                            "name": "Masque super hydratant pour peaux sèches et sensibles SkinActive",
                            "price": 23.95,
                            "imageUrl": "https://kaalixzone1.s3.amazonaws.com/images/606dd1d14a4dfb4e3f8a77ccw8VO.jpg",
                            "inStock": true,
                            "specifications": [],
                            "isMainPromo": false,
                            "createdAt": null,
                            "updatedAt": null,
                            "_id": "611e89b0636ae400229ed6de",
                            "storeId": "611e88ac636ae400229ec741",
                            "productId": "611e898d636ae400229ec887",
                            "__v": 0
                        },
                        {
                            "promoSetting": {
                                "value": 0,
                                "minCartPrice": 0,
                                "enabled": false
                            },
                            "description": "description",
                            "name": "Masque super hydratant pour peaux normales à mixtes SkinActive",
                            "price": 23.95,
                            "imageUrl": "https://kaalixzone1.s3.amazonaws.com/images/606dd1ee9fc9d956e21cdf73x89L.jpg",
                            "inStock": true,
                            "specifications": [],
                            "isMainPromo": false,
                            "createdAt": null,
                            "updatedAt": null,
                            "_id": "611e89b0636ae400229ed738",
                            "storeId": "611e88ac636ae400229ec741",
                            "productId": "611e898d636ae400229ec887",
                            "__v": 0
                        },
                        {
                            "promoSetting": {
                                "value": 0,
                                "minCartPrice": 0,
                                "enabled": false
                            },
                            "description": "description",
                            "name": "Nettoyant visage quotidien anti-brillance 150ml Clean & Clear",
                            "price": 44.85,
                            "imageUrl": "https://kaalixzone1.s3.amazonaws.com/images/606dd4ea9fc9d956e21cdf85xf8d.jpg",
                            "inStock": true,
                            "specifications": [],
                            "isMainPromo": false,
                            "createdAt": null,
                            "updatedAt": null,
                            "_id": "611e89b0636ae400229ed73e",
                            "storeId": "611e88ac636ae400229ec741",
                            "productId": "611e898d636ae400229ec887",
                            "__v": 0
                        },
                        {
                            "promoSetting": {
                                "value": 0,
                                "minCartPrice": 0,
                                "enabled": false
                            },
                            "description": "description",
                            "name": "Soin de nuit sensitive pour peaux sensibles 50ml Nivea",
                            "price": 61.25,
                            "imageUrl": "https://kaalixzone1.s3.amazonaws.com/images/606dd24e9fc9d956e21cdf75mcA9.jpg",
                            "inStock": true,
                            "specifications": [],
                            "isMainPromo": false,
                            "createdAt": null,
                            "updatedAt": null,
                            "_id": "611e89b0636ae400229ed740",
                            "storeId": "611e88ac636ae400229ec741",
                            "productId": "611e898d636ae400229ec887",
                            "__v": 0
                        },
                        {
                            "promoSetting": {
                                "value": 0,
                                "minCartPrice": 0,
                                "enabled": false
                            },
                            "description": "description",
                            "name": "Eau micellaire démaquillante pour peaux sensibles 400ml Nivea",
                            "price": 51.95,
                            "imageUrl": "https://kaalixzone1.s3.amazonaws.com/images/606dd20e9fc9d956e21cdf746hxf.jpg",
                            "inStock": true,
                            "specifications": [],
                            "isMainPromo": false,
                            "createdAt": null,
                            "updatedAt": null,
                            "_id": "611e89b0636ae400229ed746",
                            "storeId": "611e88ac636ae400229ec741",
                            "productId": "611e898d636ae400229ec887",
                            "__v": 0
                        },
                        {
                            "promoSetting": {
                                "value": 0,
                                "minCartPrice": 0,
                                "enabled": false
                            },
                            "description": "description",
                            "name": "Lait démaquillant visage et yeux Nivea 200 ml",
                            "price": 65.95,
                            "imageUrl": "https://kaalixzone1.s3.amazonaws.com/images/606dd2574a4dfb4e3f8a77d54sN5.jpg",
                            "inStock": true,
                            "specifications": [],
                            "isMainPromo": false,
                            "createdAt": null,
                            "updatedAt": null,
                            "_id": "611e89b0636ae400229ed74c",
                            "storeId": "611e88ac636ae400229ec741",
                            "productId": "611e898d636ae400229ec887",
                            "__v": 0
                        },
                        {
                            "promoSetting": {
                                "value": 0,
                                "minCartPrice": 0,
                                "enabled": false
                            },
                            "description": "description",
                            "name": "Nettoyant 3en1 barbe+visage+cheveux BarberClub 200ml",
                            "price": 73.95,
                            "imageUrl": "https://kaalixzone1.s3.amazonaws.com/images/606dd2be9fc9d956e21cdf7aPatG.jpg",
                            "inStock": true,
                            "specifications": [],
                            "isMainPromo": false,
                            "createdAt": null,
                            "updatedAt": null,
                            "_id": "611e89b0636ae400229ed74e",
                            "storeId": "611e88ac636ae400229ec741",
                            "productId": "611e898d636ae400229ec887",
                            "__v": 0
                        },
                        {
                            "promoSetting": {
                                "value": 0,
                                "minCartPrice": 0,
                                "enabled": false
                            },
                            "description": "description",
                            "name": "Crème hydratante de nuit Triple Active Nivea 50ml",
                            "price": 64.95,
                            "imageUrl": "https://kaalixzone1.s3.amazonaws.com/images/606dd3a54a4dfb4e3f8a77dejdgB.jpg",
                            "inStock": true,
                            "specifications": [],
                            "isMainPromo": false,
                            "createdAt": null,
                            "updatedAt": null,
                            "_id": "611e89b0636ae400229ed750",
                            "storeId": "611e88ac636ae400229ec741",
                            "productId": "611e898d636ae400229ec887",
                            "__v": 0
                        },
                        {
                            "promoSetting": {
                                "value": 0,
                                "minCartPrice": 0,
                                "enabled": false
                            },
                            "description": "description",
                            "name": "Gel nettoyant fraîcheur peaux normales à mixtes 150ml Nivea",
                            "price": 53.95,
                            "imageUrl": "https://kaalixzone1.s3.amazonaws.com/images/606dd3774a4dfb4e3f8a77dbTIcs.jpg",
                            "inStock": true,
                            "specifications": [],
                            "isMainPromo": false,
                            "createdAt": null,
                            "updatedAt": null,
                            "_id": "611e89b0636ae400229ed754",
                            "storeId": "611e88ac636ae400229ec741",
                            "productId": "611e898d636ae400229ec887",
                            "__v": 0
                        },
                        {
                            "promoSetting": {
                                "value": 0,
                                "minCartPrice": 0,
                                "enabled": false
                            },
                            "description": "description",
                            "name": "Gommage quotidien anti-brillance 150ml Clean & Clear",
                            "price": 49.95,
                            "imageUrl": "https://kaalixzone1.s3.amazonaws.com/images/606dd4d79fc9d956e21cdf84qwX9.jpg",
                            "inStock": true,
                            "specifications": [],
                            "isMainPromo": false,
                            "createdAt": null,
                            "updatedAt": null,
                            "_id": "611e89b0636ae400229ed75e",
                            "storeId": "611e88ac636ae400229ec741",
                            "productId": "611e898d636ae400229ec887",
                            "__v": 0
                        },
                        {
                            "promoSetting": {
                                "value": 0,
                                "minCartPrice": 0,
                                "enabled": false
                            },
                            "description": "description",
                            "name": "Nettoyant profond 3 en 1 150ml",
                            "price": 61.95,
                            "imageUrl": "https://kaalixzone1.s3.amazonaws.com/images/606dd4bd9fc9d956e21cdf83hYsK.jpg",
                            "inStock": true,
                            "specifications": [],
                            "isMainPromo": false,
                            "createdAt": null,
                            "updatedAt": null,
                            "_id": "611e89b0636ae400229ed762",
                            "storeId": "611e88ac636ae400229ec741",
                            "productId": "611e898d636ae400229ec887",
                            "__v": 0
                        },
                        {
                            "promoSetting": {
                                "value": 0,
                                "minCartPrice": 0,
                                "enabled": false
                            },
                            "description": "description",
                            "name": "Soin Ré-hydratant Anti-Relâchement pour yeux 15ml",
                            "price": 87.1,
                            "imageUrl": "https://kaalixzone1.s3.amazonaws.com/images/606dd2344a4dfb4e3f8a77d2h4vG.jpg",
                            "inStock": true,
                            "specifications": [],
                            "isMainPromo": false,
                            "createdAt": null,
                            "updatedAt": null,
                            "_id": "611e89b0636ae400229ed784",
                            "storeId": "611e88ac636ae400229ec741",
                            "productId": "611e898d636ae400229ec887",
                            "__v": 0
                        }
                    ],
                    "productId": "611e898d636ae400229ec887",
                    "product": {
                        "name": "Soin du Visage, Démaquillants",
                        "description": "description",
                        "inStock": true,
                        "imageUrl": "imageUrl",
                        "createdAt": "2021-08-19T16:40:45.895Z",
                        "updatedAt": "2021-08-19T16:40:45.895Z",
                        "_id": "611e898d636ae400229ec887",
                        "productCategoryId": "611e8965636ae400229ec78c",
                        "storeId": "611e88ac636ae400229ec741",
                        "__v": 0
                    }
                },
                {
                    "items": [
                        {
                            "promoSetting": {
                                "value": 0,
                                "minCartPrice": 0,
                                "enabled": false
                            },
                            "description": "description",
                            "name": "Savon Dettol cool 120g",
                            "price": 9.5,
                            "imageUrl": "https://kaalixzone1.s3.amazonaws.com/images/606ed47bdf68d85aa5c94ee3RVkZ.jpg",
                            "inStock": true,
                            "specifications": [],
                            "isMainPromo": false,
                            "createdAt": null,
                            "updatedAt": null,
                            "_id": "611e89b0636ae400229eda17",
                            "storeId": "611e88ac636ae400229ec741",
                            "productId": "611e898d636ae400229ec87f",
                            "__v": 0
                        }
                    ],
                    "productId": "611e898d636ae400229ec87f",
                    "product": {
                        "name": "Gel Douche, Savons",
                        "description": "description",
                        "inStock": true,
                        "imageUrl": "imageUrl",
                        "createdAt": "2021-08-19T16:40:45.886Z",
                        "updatedAt": "2021-08-19T16:40:45.886Z",
                        "_id": "611e898d636ae400229ec87f",
                        "productCategoryId": "611e8965636ae400229ec78c",
                        "storeId": "611e88ac636ae400229ec741",
                        "__v": 0
                    }
                }
            ],
            "productCategory": {
                "name": "Hygiène, Beauté",
                "description": "description",
                "inStock": true,
                "imageUrl": "https://kaalixzone1.s3.amazonaws.com/images/606ed86e2b43844f1c8bcd70takc.jpg",
                "createdAt": "2021-08-19T16:40:05.577Z",
                "updatedAt": "2021-08-19T16:40:05.577Z",
                "_id": "611e8965636ae400229ec78c",
                "storeId": "611e88ac636ae400229ec741",
                "__v": 0
            }
        },
        {
            "productCategoryId": "611e8965636ae400229ec792",
            "products": [
                {
                    "items": [
                        {
                            "promoSetting": {
                                "value": 0,
                                "minCartPrice": 0,
                                "enabled": false
                            },
                            "description": "description",
                            "name": "xx Essuie-Tout Maxi X2 Sany",
                            "price": 17.95,
                            "imageUrl": "https://kaalixzone1.s3.amazonaws.com/images/606eee25df68d85aa5c94fb5tGlU.jpg",
                            "inStock": true,
                            "specifications": [],
                            "isMainPromo": false,
                            "createdAt": null,
                            "updatedAt": null,
                            "_id": "611e903b636ae400229ee26b",
                            "storeId": "611e88ac636ae400229ec741",
                            "productId": "611e898d636ae400229ec7cf",
                            "__v": 0
                        }
                    ],
                    "productId": "611e898d636ae400229ec7cf",
                    "product": {
                        "name": "Essuie Tout , Emballages Menagers",
                        "description": "description",
                        "inStock": true,
                        "imageUrl": "imageUrl",
                        "createdAt": "2021-08-19T16:40:45.602Z",
                        "updatedAt": "2021-08-19T16:40:45.602Z",
                        "_id": "611e898d636ae400229ec7cf",
                        "productCategoryId": "611e8965636ae400229ec792",
                        "storeId": "611e88ac636ae400229ec741",
                        "__v": 0
                    }
                },
                {
                    "items": [
                        {
                            "promoSetting": {
                                "value": 0,
                                "minCartPrice": 0,
                                "enabled": false
                            },
                            "description": "description",
                            "name": "20 Sacs-Poubelles 40L Folia",
                            "price": 24.95,
                            "imageUrl": "https://kaalixzone1.s3.amazonaws.com/images/606ee87fdf68d85aa5c94f78maVm.jpg",
                            "inStock": true,
                            "specifications": [],
                            "isMainPromo": false,
                            "createdAt": null,
                            "updatedAt": null,
                            "_id": "611e89b1636ae400229edc06",
                            "storeId": "611e88ac636ae400229ec741",
                            "productId": "611e898d636ae400229ec8b7",
                            "__v": 0
                        }
                    ],
                    "productId": "611e898d636ae400229ec8b7",
                    "product": {
                        "name": "Droguerie",
                        "description": "description",
                        "inStock": true,
                        "imageUrl": "imageUrl",
                        "createdAt": "2021-08-19T16:40:45.946Z",
                        "updatedAt": "2021-08-19T16:40:45.946Z",
                        "_id": "611e898d636ae400229ec8b7",
                        "productCategoryId": "611e8965636ae400229ec792",
                        "storeId": "611e88ac636ae400229ec741",
                        "__v": 0
                    }
                },
                {
                    "items": [
                        {
                            "promoSetting": {
                                "value": 0,
                                "minCartPrice": 0,
                                "enabled": false
                            },
                            "description": "description",
                            "name": "Papier Hygiénique x 12 Dalaa",
                            "price": 23.95,
                            "imageUrl": "https://kaalixzone1.s3.amazonaws.com/images/606f1e08cb473a5ccc95f58bLGMj.jpg",
                            "inStock": true,
                            "specifications": [],
                            "isMainPromo": false,
                            "createdAt": null,
                            "updatedAt": null,
                            "_id": "611e89b2636ae400229ee22e",
                            "storeId": "611e88ac636ae400229ec741",
                            "productId": "611e898d636ae400229ec88b",
                            "__v": 0
                        }
                    ],
                    "productId": "611e898d636ae400229ec88b",
                    "product": {
                        "name": "Mouchoirs, Papier Hygienique",
                        "description": "description",
                        "inStock": true,
                        "imageUrl": "imageUrl",
                        "createdAt": "2021-08-19T16:40:45.898Z",
                        "updatedAt": "2021-08-19T16:40:45.898Z",
                        "_id": "611e898d636ae400229ec88b",
                        "productCategoryId": "611e8965636ae400229ec792",
                        "storeId": "611e88ac636ae400229ec741",
                        "__v": 0
                    }
                }
            ],
            "productCategory": {
                "name": "Entretien, Nettoyage",
                "description": "description",
                "inStock": true,
                "imageUrl": "https://kaalixzone1.s3.amazonaws.com/images/606ed664df68d85aa5c94ee99FtJ.jpg",
                "createdAt": "2021-08-19T16:40:05.583Z",
                "updatedAt": "2021-08-19T16:40:05.583Z",
                "_id": "611e8965636ae400229ec792",
                "storeId": "611e88ac636ae400229ec741",
                "__v": 0
            }
        },
        {
            "productCategoryId": "611e8965636ae400229ec794",
            "products": [
                {
                    "items": [
                        {
                            "promoSetting": {
                                "value": 0,
                                "minCartPrice": 0,
                                "enabled": false
                            },
                            "description": "description",
                            "name": "Farine fleur pâtissière 2kg Maymouna",
                            "price": 15.8,
                            "imageUrl": "https://kaalixzone1.s3.amazonaws.com/images/606ede702b43844f1c8bcda4Grus.jpg",
                            "inStock": true,
                            "specifications": [],
                            "isMainPromo": false,
                            "createdAt": null,
                            "updatedAt": null,
                            "_id": "611e89b1636ae400229edb2a",
                            "storeId": "611e88ac636ae400229ec741",
                            "productId": "611e898d636ae400229ec847",
                            "__v": 0
                        }
                    ],
                    "productId": "611e898d636ae400229ec847",
                    "product": {
                        "name": "Farine, Semoule",
                        "description": "description",
                        "inStock": true,
                        "imageUrl": "imageUrl",
                        "createdAt": "2021-08-19T16:40:45.807Z",
                        "updatedAt": "2021-08-19T16:40:45.807Z",
                        "_id": "611e898d636ae400229ec847",
                        "productCategoryId": "611e8965636ae400229ec794",
                        "storeId": "611e88ac636ae400229ec741",
                        "__v": 0
                    }
                },
                {
                    "items": [],
                    "productId": "611e898d636ae400229ec891",
                    "product": {
                        "name": "Produits du Monde",
                        "description": "description",
                        "inStock": true,
                        "imageUrl": "imageUrl",
                        "createdAt": "2021-08-19T16:40:45.901Z",
                        "updatedAt": "2021-08-19T16:40:45.901Z",
                        "_id": "611e898d636ae400229ec891",
                        "productCategoryId": "611e8965636ae400229ec794",
                        "storeId": "611e88ac636ae400229ec741",
                        "__v": 0
                    }
                },
                {
                    "items": [
                        {
                            "promoSetting": {
                                "value": 0,
                                "minCartPrice": 0,
                                "enabled": false
                            },
                            "description": "description",
                            "name": "Crunchips Red Chili 100g Lorenz",
                            "price": 11.95,
                            "imageUrl": "https://kaalixzone1.s3.amazonaws.com/images/606f07498ad00d53b34a4e1eRywd.jpg",
                            "inStock": true,
                            "specifications": [],
                            "isMainPromo": false,
                            "createdAt": null,
                            "updatedAt": null,
                            "_id": "611e89b1636ae400229edd64",
                            "storeId": "611e88ac636ae400229ec741",
                            "productId": "611e898d636ae400229ec85e",
                            "__v": 0
                        }
                    ],
                    "productId": "611e898d636ae400229ec85e",
                    "product": {
                        "name": "Apéritifs",
                        "description": "description",
                        "inStock": true,
                        "imageUrl": "imageUrl",
                        "createdAt": "2021-08-19T16:40:45.844Z",
                        "updatedAt": "2021-08-19T16:40:45.844Z",
                        "_id": "611e898d636ae400229ec85e",
                        "productCategoryId": "611e8965636ae400229ec794",
                        "storeId": "611e88ac636ae400229ec741",
                        "__v": 0
                    }
                }
            ],
            "productCategory": {
                "name": "Epicerie Salée",
                "description": "description",
                "inStock": true,
                "imageUrl": "https://kaalixzone1.s3.amazonaws.com/images/606dc8549fc9d956e21cdf25gLyC.jpg",
                "createdAt": "2021-08-19T16:40:05.585Z",
                "updatedAt": "2021-08-19T16:40:05.585Z",
                "_id": "611e8965636ae400229ec794",
                "storeId": "611e88ac636ae400229ec741",
                "__v": 0
            }
        },
        {
            "productCategoryId": "611e8965636ae400229ec790",
            "products": [
                {
                    "items": [],
                    "productId": "611e898d636ae400229ec8ad",
                    "product": {
                        "name": "Pâtes À Tartiner",
                        "description": "description",
                        "inStock": true,
                        "imageUrl": "imageUrl",
                        "createdAt": "2021-08-19T16:40:45.934Z",
                        "updatedAt": "2021-08-19T16:40:45.934Z",
                        "_id": "611e898d636ae400229ec8ad",
                        "productCategoryId": "611e8965636ae400229ec790",
                        "storeId": "611e88ac636ae400229ec741",
                        "__v": 0
                    }
                },
                {
                    "items": [],
                    "productId": "611e898d636ae400229ec8c3",
                    "product": {
                        "name": "Confitures",
                        "description": "description",
                        "inStock": true,
                        "imageUrl": "imageUrl",
                        "createdAt": "2021-08-19T16:40:45.953Z",
                        "updatedAt": "2021-08-19T16:40:45.953Z",
                        "_id": "611e898d636ae400229ec8c3",
                        "productCategoryId": "611e8965636ae400229ec790",
                        "storeId": "611e88ac636ae400229ec741",
                        "__v": 0
                    }
                },
                {
                    "items": [
                        {
                            "promoSetting": {
                                "value": 0,
                                "minCartPrice": 0,
                                "enabled": false
                            },
                            "description": "description",
                            "name": "Pack génoise cacao fourré lait Kinder Délice 10x39g",
                            "price": 60,
                            "imageUrl": "https://kaalixzone1.s3.amazonaws.com/images/606f2de0b770275d236a3d6f7Izp.jpg",
                            "inStock": true,
                            "specifications": [],
                            "isMainPromo": false,
                            "createdAt": null,
                            "updatedAt": null,
                            "_id": "611e89b1636ae400229edf7e",
                            "storeId": "611e88ac636ae400229ec741",
                            "productId": "611e898d636ae400229ec8b9",
                            "__v": 0
                        }
                    ],
                    "productId": "611e898d636ae400229ec8b9",
                    "product": {
                        "name": "Bonbons, Chocolats",
                        "description": "description",
                        "inStock": true,
                        "imageUrl": "imageUrl",
                        "createdAt": "2021-08-19T16:40:45.947Z",
                        "updatedAt": "2021-08-19T16:40:45.947Z",
                        "_id": "611e898d636ae400229ec8b9",
                        "productCategoryId": "611e8965636ae400229ec790",
                        "storeId": "611e88ac636ae400229ec741",
                        "__v": 0
                    }
                }
            ],
            "productCategory": {
                "name": "Epicerie Sucrée, Petit Déjeuner",
                "description": "description",
                "inStock": true,
                "imageUrl": "https://kaalixzone1.s3.amazonaws.com/images/606dc6b74a4dfb4e3f8a7767FcR7.jpg",
                "createdAt": "2021-08-19T16:40:05.582Z",
                "updatedAt": "2021-08-19T16:40:05.582Z",
                "_id": "611e8965636ae400229ec790",
                "storeId": "611e88ac636ae400229ec741",
                "__v": 0
            }
        },
        {
            "productCategoryId": "611e8965636ae400229ec78a",
            "products": [
                {
                    "items": [
                        {
                            "promoSetting": {
                                "value": 0,
                                "minCartPrice": 0,
                                "enabled": false
                            },
                            "description": "description",
                            "name": "Croquettes au bœuf et légumes pour chien 4kg Casino",
                            "price": 89.95,
                            "imageUrl": "https://kaalixzone1.s3.amazonaws.com/images/606dc21d4a4dfb4e3f8a772e0JqK.jpg",
                            "inStock": true,
                            "specifications": [],
                            "isMainPromo": false,
                            "createdAt": null,
                            "updatedAt": null,
                            "_id": "611e89aa636ae400229ec93a",
                            "storeId": "611e88ac636ae400229ec741",
                            "productId": "611e898d636ae400229ec87b",
                            "__v": 0
                        },
                        {
                            "promoSetting": {
                                "value": 0,
                                "minCartPrice": 0,
                                "enabled": false
                            },
                            "description": "description",
                            "name": "Croquettes au bœuf pour chats adultes 1,75Kg Whiskas",
                            "price": 88.25,
                            "imageUrl": "https://kaalixzone1.s3.amazonaws.com/images/606dc2ce9fc9d956e21cdee65rgx.jpg",
                            "inStock": true,
                            "specifications": [],
                            "isMainPromo": false,
                            "createdAt": null,
                            "updatedAt": null,
                            "_id": "611e89ab636ae400229ecaa4",
                            "storeId": "611e88ac636ae400229ec741",
                            "productId": "611e898d636ae400229ec87b",
                            "__v": 0
                        },
                        {
                            "promoSetting": {
                                "value": 0,
                                "minCartPrice": 0,
                                "enabled": false
                            },
                            "description": "description",
                            "name": "Croquettes viandes pour chats 2kg Casino",
                            "price": 54.95,
                            "imageUrl": "https://kaalixzone1.s3.amazonaws.com/images/606dc26e9fc9d956e21cdee1CaKn.jpg",
                            "inStock": true,
                            "specifications": [],
                            "isMainPromo": false,
                            "createdAt": null,
                            "updatedAt": null,
                            "_id": "611e89ac636ae400229ecb64",
                            "storeId": "611e88ac636ae400229ec741",
                            "productId": "611e898d636ae400229ec87b",
                            "__v": 0
                        },
                        {
                            "promoSetting": {
                                "value": 0,
                                "minCartPrice": 0,
                                "enabled": false
                            },
                            "description": "description",
                            "name": "Terrine à la truite pour chats 100g Casino",
                            "price": 4.2,
                            "imageUrl": "https://kaalixzone1.s3.amazonaws.com/images/606dc2809fc9d956e21cdee3XY6P.jpg",
                            "inStock": true,
                            "specifications": [],
                            "isMainPromo": false,
                            "createdAt": null,
                            "updatedAt": null,
                            "_id": "611e89ad636ae400229ecd3d",
                            "storeId": "611e88ac636ae400229ec741",
                            "productId": "611e898d636ae400229ec87b",
                            "__v": 0
                        },
                        {
                            "promoSetting": {
                                "value": 0,
                                "minCartPrice": 0,
                                "enabled": false
                            },
                            "description": "description",
                            "name": "Barquette  bœuf pour chat 100g Casino",
                            "price": 5,
                            "imageUrl": "https://kaalixzone1.s3.amazonaws.com/images/606dc2b79fc9d956e21cdee4bODM.jpg",
                            "inStock": true,
                            "specifications": [],
                            "isMainPromo": false,
                            "createdAt": null,
                            "updatedAt": null,
                            "_id": "611e89ad636ae400229ecd41",
                            "storeId": "611e88ac636ae400229ec741",
                            "productId": "611e898d636ae400229ec87b",
                            "__v": 0
                        },
                        {
                            "promoSetting": {
                                "value": 0,
                                "minCartPrice": 0,
                                "enabled": false
                            },
                            "description": "description",
                            "name": "Barquette au lapin pour chat 100g Casino",
                            "price": 5.95,
                            "imageUrl": "https://kaalixzone1.s3.amazonaws.com/images/606dc2854a4dfb4e3f8a7737aPBo.jpg",
                            "inStock": true,
                            "specifications": [],
                            "isMainPromo": false,
                            "createdAt": null,
                            "updatedAt": null,
                            "_id": "611e89ad636ae400229ecd43",
                            "storeId": "611e88ac636ae400229ec741",
                            "productId": "611e898d636ae400229ec87b",
                            "__v": 0
                        },
                        {
                            "promoSetting": {
                                "value": 0,
                                "minCartPrice": 0,
                                "enabled": false
                            },
                            "description": "description",
                            "name": "Emincés poulet-dinde pour chats 405g Casino",
                            "price": 12.95,
                            "imageUrl": "https://kaalixzone1.s3.amazonaws.com/images/606dc2794a4dfb4e3f8a7735I7FH.jpg",
                            "inStock": true,
                            "specifications": [],
                            "isMainPromo": false,
                            "createdAt": null,
                            "updatedAt": null,
                            "_id": "611e89ad636ae400229ecda9",
                            "storeId": "611e88ac636ae400229ec741",
                            "productId": "611e898d636ae400229ec87b",
                            "__v": 0
                        },
                        {
                            "promoSetting": {
                                "value": 0,
                                "minCartPrice": 0,
                                "enabled": false
                            },
                            "description": "description",
                            "name": "Bouchées à la volaille pour chiens 1240g Casino",
                            "price": 21.95,
                            "imageUrl": "https://kaalixzone1.s3.amazonaws.com/images/606dc2e79fc9d956e21cdee8JQ6Z.jpg",
                            "inStock": true,
                            "specifications": [],
                            "isMainPromo": false,
                            "createdAt": null,
                            "updatedAt": null,
                            "_id": "611e89ad636ae400229ecdab",
                            "storeId": "611e88ac636ae400229ec741",
                            "productId": "611e898d636ae400229ec87b",
                            "__v": 0
                        },
                        {
                            "promoSetting": {
                                "value": 0,
                                "minCartPrice": 0,
                                "enabled": false
                            },
                            "description": "description",
                            "name": "Litière pour chats 5kg Master Cat",
                            "price": 27.5,
                            "imageUrl": "https://kaalixzone1.s3.amazonaws.com/images/606dc2d79fc9d956e21cdee7zZUb.jpg",
                            "inStock": true,
                            "specifications": [],
                            "isMainPromo": false,
                            "createdAt": null,
                            "updatedAt": null,
                            "_id": "611e89ae636ae400229ed0fa",
                            "storeId": "611e88ac636ae400229ec741",
                            "productId": "611e898d636ae400229ec87b",
                            "__v": 0
                        },
                        {
                            "promoSetting": {
                                "value": 0,
                                "minCartPrice": 0,
                                "enabled": false
                            },
                            "description": "description",
                            "name": "Croquettes à la volaille et légumes pour chiot 4kg Casino",
                            "price": 113.95,
                            "imageUrl": "https://kaalixzone1.s3.amazonaws.com/images/606dc2134a4dfb4e3f8a772dicHF.jpg",
                            "inStock": true,
                            "specifications": [],
                            "isMainPromo": false,
                            "createdAt": null,
                            "updatedAt": null,
                            "_id": "611e89ae636ae400229ed0fe",
                            "storeId": "611e88ac636ae400229ec741",
                            "productId": "611e898d636ae400229ec87b",
                            "__v": 0
                        },
                        {
                            "promoSetting": {
                                "value": 0,
                                "minCartPrice": 0,
                                "enabled": false
                            },
                            "description": "description",
                            "name": "Terrine au saumon pour chats 100g Casino",
                            "price": 4.5,
                            "imageUrl": "https://kaalixzone1.s3.amazonaws.com/images/606dc2c19fc9d956e21cdee5cCMb.jpg",
                            "inStock": true,
                            "specifications": [],
                            "isMainPromo": false,
                            "createdAt": null,
                            "updatedAt": null,
                            "_id": "611e89ae636ae400229ed100",
                            "storeId": "611e88ac636ae400229ec741",
                            "productId": "611e898d636ae400229ec87b",
                            "__v": 0
                        },
                        {
                            "promoSetting": {
                                "value": 0,
                                "minCartPrice": 0,
                                "enabled": false
                            },
                            "description": "description",
                            "name": "Croquettes au poulet, canard et légumes pour chat 2kg Casino",
                            "price": 53.95,
                            "imageUrl": "https://kaalixzone1.s3.amazonaws.com/images/606dc2779fc9d956e21cdee2bKIj.jpg",
                            "inStock": true,
                            "specifications": [],
                            "isMainPromo": false,
                            "createdAt": null,
                            "updatedAt": null,
                            "_id": "611e89ae636ae400229ed104",
                            "storeId": "611e88ac636ae400229ec741",
                            "productId": "611e898d636ae400229ec87b",
                            "__v": 0
                        },
                        {
                            "promoSetting": {
                                "value": 0,
                                "minCartPrice": 0,
                                "enabled": false
                            },
                            "description": "description",
                            "name": "Bouchées au bœuf pour chiens 1240g Casino",
                            "price": 21.95,
                            "imageUrl": "https://kaalixzone1.s3.amazonaws.com/images/606dc25f4a4dfb4e3f8a7732Y9GO.jpg",
                            "inStock": true,
                            "specifications": [],
                            "isMainPromo": false,
                            "createdAt": null,
                            "updatedAt": null,
                            "_id": "611e89ae636ae400229ed227",
                            "storeId": "611e88ac636ae400229ec741",
                            "productId": "611e898d636ae400229ec87b",
                            "__v": 0
                        },
                        {
                            "promoSetting": {
                                "value": 0,
                                "minCartPrice": 0,
                                "enabled": false
                            },
                            "description": "description",
                            "name": "Croquettes au poulet pour chat junior 1,75Kg Whiskas",
                            "price": 86.5,
                            "imageUrl": "https://kaalixzone1.s3.amazonaws.com/images/606dc2264a4dfb4e3f8a772fNr17.jpg",
                            "inStock": true,
                            "specifications": [],
                            "isMainPromo": false,
                            "createdAt": null,
                            "updatedAt": null,
                            "_id": "611e89ae636ae400229ed22f",
                            "storeId": "611e88ac636ae400229ec741",
                            "productId": "611e898d636ae400229ec87b",
                            "__v": 0
                        },
                        {
                            "promoSetting": {
                                "value": 0,
                                "minCartPrice": 0,
                                "enabled": false
                            },
                            "description": "description",
                            "name": "Terrine au bœuf 400g Casino",
                            "price": 12.95,
                            "imageUrl": "https://kaalixzone1.s3.amazonaws.com/images/606dc2684a4dfb4e3f8a77343ZPa.jpg",
                            "inStock": true,
                            "specifications": [],
                            "isMainPromo": false,
                            "createdAt": null,
                            "updatedAt": null,
                            "_id": "611e89ae636ae400229ed237",
                            "storeId": "611e88ac636ae400229ec741",
                            "productId": "611e898d636ae400229ec87b",
                            "__v": 0
                        }
                    ],
                    "productId": "611e898d636ae400229ec87b",
                    "product": {
                        "name": "Aliments Chats & Chiens",
                        "description": "description",
                        "inStock": true,
                        "imageUrl": "imageUrl",
                        "createdAt": "2021-08-19T16:40:45.883Z",
                        "updatedAt": "2021-08-19T16:40:45.883Z",
                        "_id": "611e898d636ae400229ec87b",
                        "productCategoryId": "611e8965636ae400229ec78a",
                        "storeId": "611e88ac636ae400229ec741",
                        "__v": 0
                    }
                }
            ],
            "productCategory": {
                "name": "Animalerie",
                "description": "description",
                "inStock": true,
                "imageUrl": "https://kaalixzone1.s3.amazonaws.com/images/607011edffd812546eda2261O0dQ.jpg",
                "createdAt": "2021-08-19T16:40:05.575Z",
                "updatedAt": "2021-08-19T16:40:05.575Z",
                "_id": "611e8965636ae400229ec78a",
                "storeId": "611e88ac636ae400229ec741",
                "__v": 0
            }
        }
    ]
}