import { GET_STORE_REQUEST,GET_STORE_SUCCESS,GET_STORE_FAIL } from '../Constants/store.config.constants'

const initialState = {
  store: {},
  error: "",
  loading: false,
};

export default function storeReducer(state = initialState, action) {
  switch (action.type) {
    case GET_STORE_REQUEST:
      return {
        loading: true
      }

    case GET_STORE_SUCCESS:
      return {
        ...state,
        loading: false,
        store: action.store
      };
    case GET_STORE_FAIL:
      return {
        ...state,
        loading: false,
        error: action.error?.message || (action?.error?.errors || [{ message: "" }])[0].message,
      };
    
    default:
      return state;
  }
}