import React, { useEffect, useState } from 'react'
import { Grid, Box ,Typography} from '@material-ui/core'

import { formatTimeForOrder } from '../../../helpers/index'
import ChangeDurationComponents from '../components/ChangeDuration.components'
import Button from '../../../components/material-dashboard-pro-react/CustomButtons/Button'
import { updateOrder } from '../services/serverApi.service'
import { preparationDuration } from '../mock/index'

const style = {
    titleColor: { color: 'balck',fontWeight: 'bold',fontSize: '15px' },
}
export default function OnlyDetailsComponent({ order, close }) {
    // order.duration = 25
    return (
        <div>
            <Grid container spacing={3}  >
                <Grid item xs={8} style={{fontFamily:'auto'}}>
                    Commande ID :{order.shortId}<br />
                    Temps de préparation  :{order.duration}<br />
                    
                </Grid>
                <Grid item xs={4} style={{fontFamily:'auto'}}>
                    Commande crée le :{formatTimeForOrder(order.createdAt)}<br />
                    Temps écoulé : <ChangeDurationComponents time={order.createdAt} />
                </Grid>
            </Grid>
            <Grid container spacing={2}>
                <Grid item xs={12} container style={{background:'#f1f1f1',borderRadius:'20px',marginTop:'30px'}} >
                    {order?.payment?.cartDetails.length > 0 && order?.payment?.cartDetails.map((element) => (
                        <Grid item xs={12} container spacing={2}>
                            <Grid item xs={12} style={{ ...style.titleColor }}>
                                {element.name}
                            </Grid>
                            <Grid item xs={12}>
                                {element.items.length > 0 && element.items.map((item) => (
                                    <Box display="flex" justifyContent="center">

                                        <Grid container spacing={2} style={{ display: 'flex', alignItems: 'center' }}>
                                            <Grid item xs={12} container style={{ display: 'flex', alignItems: 'center' }} className='mt-3' spacing={2}>
                                                <Grid item xs={5} style={{ ...style.titleColor, marginLeft: '20px',textAlign:'center'}}>
                                                    {`${item.name} ( x ${item.quantity} )`}
                                                </Grid>
                                                <Grid item xs={4}>
                                                    <div style={{ borderBottom: '1px solid black', width: '100%', }}></div>
                                                </Grid>
                                                <Grid item xs={2} style={{ ...style.titleColor }}>
                                                    <strong>{item.itemTotalPrice + ' dh'}</strong>
                                                    {`( x ${item.quantity} )`}
                                                </Grid>
                                                <Grid xs={12} style={{ ...style.titleColor,textAlign:'center',marginBottom:'10px' }} >
                            <Grid item xs={12} style={{ ...style.titleColor ,marginBottom:'14px',color:'red'}}>
                              {item?.note ==''? '':`Notes ------------------  ${item.note}`} 
                            </Grid>
                              </Grid>
                                            </Grid>
                                            {/* <Grid item xs={12} className="mt-2" container> */}
                                            <Grid container >
                                                {item.specifications.length > 0 && item.specifications.map((specification) => (
                                                    <Grid container spacing={2} style={{ display: 'flex', alignItems: 'center', marginLeft: '40px' }}>
                                                        <Grid item xs={12}>
                                                            {specification.name + ' :'}
                                                        </Grid>
                                                        <Grid item xs={12}>
                                                            {specification.list.length > 0 && specification.list.map((content) => (
                                                                <Grid container spacing={2} style={{ display: 'flex', alignItems: 'center' }}>
                                                                    <Grid item xs={4} >
                                                                        {content.name}
                                                                    </Grid>
                                                                    <Grid item xs={5}>
                                                                        <div style={{ borderBottom: '1px solid black', width: '100%', }}></div>
                                                                    </Grid>
                                                                    <Grid item xs={3}>
                                                                        {content.price + ' dh'}
                                                                    </Grid>
                                                                </Grid>
                                                            ))}
                                                        </Grid>
                                                    </Grid>
                                                ))}
                                            </Grid>
                                            {/* </Grid> */}

                                        </Grid>
                                    </Box>
                                ))}
                            </Grid>
                        </Grid>
                    ))}
                </Grid>
            </Grid>
            <Grid container spacing={3} justify="flex-end" >
                <Grid item xs={4} className="mt-3" >
                    <Typography variant="h5" style={{fontWeight:'bold' }} >Total : {order?.payment.originalOrderPrice} dh</Typography>
                </Grid>
            </Grid>
            {/* <Grid container spacing={3}>
                <Grid item xs={12} style={{ border: '1px solid grey', borderRadius: "12px" }}>
                    {order?.payment?.cartDetails.length > 0 && order?.payment?.cartDetails.map((element) => (
                        element.items.length > 0 && element.items.map((item) => (

                            <Grid container spacing={3}>
                                <Grid item xs={8}>
                                    <pan>{`${item.name} ( x ${item.quantity} )`}</pan>
                                    <br />
                                    <br />
                                    <div style={{ marginLeft: "30px" }}>
                                        {item.specifications.length > 0 && item.specifications.map((specification) => (
                                            <Grid container spacing={3}>
                                                <Grid item xs={8}>
                                                    {`${specification.name}`}
                                                    {specification.list.length > 0 && specification.list.map((content) => (
                                                        <span><br />{content.name}{content.price > 0 && ` (${content.price} dh)`}</span>
                                                    ))
                                                    }
                                                </Grid>
                                            </Grid>

                                        ))}
                                    </div>
                                </Grid>
                                <Grid item xs={4} >
                                    {item.itemPrice} dh
                                </Grid>
                                <Grid container spacing={3} justify="flex-end">
                                    <Grid item xs={4} style={{ marginBottom: '20px' }}>
                                        {item.specificationPrice > 0 && item.specificationPrice + 'dh'}
                                    </Grid>
                                </Grid>


                            </Grid>
                        ))
                    ))}
                    <Grid container spacing={3} justify="flex-end" style={{ borderTop: '1px solid #000' }}>
                        <Grid item xs={4} style={{ marginTop: '20px' }}>
                            Total : {order?.payment.orderPrice} dh
                        </Grid>
                    </Grid>
                </Grid>
            </Grid> */}
        </div>
    )
}
