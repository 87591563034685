
const scope = 'OrdersPages'
export const GET_ORDER_REQUEST=`${scope}/GET_ORDER_REQUEST`;
export const GET_ORDER_SUCCESS=`${scope}/GET_ORDER_SUCCESS`;
export const GET_ORDER_FAIL=`${scope}/GET_ORDER_FAIL`;
export const CHANGE_ORDER_STATUS=`${scope}/CHANGE_ORDER_STATUS`;
export const NEW_ORDER=`${scope}/NEW_ORDER`;
export const VALIDATE_ORDER=`${scope}/VALIDATE_ORDER`;
export const CANCEL_ORDER=`${scope}/CANCEL_ORDER`;
export const FIRST_TIME=`${scope}/FIRST_TIME`;
