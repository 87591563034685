import React, { useState } from "react";
// nodejs library to set properties for components
import PropTypes from "prop-types";
import cx from "classnames";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import Box from "@material-ui/core/Box";
import Typography from "@mui/material/Typography";
import Toolbar from "@material-ui/core/Toolbar";
import Hidden from "@material-ui/core/Hidden";

// material-ui icons
import Menu from "@material-ui/icons/Menu";
import MoreVert from "@material-ui/icons/MoreVert";
import ViewList from "@material-ui/icons/ViewList";
import StoreIcon from '@material-ui/icons/Store';

// core components
import AdminNavbarLinks from "./AdminNavbarLinks";

import Button from "components/material-dashboard-pro-react/CustomButtons/Button.js";

import styles from "assets/jss/material-dashboard-pro-react/components/adminNavbarStyle.js";
import { Avatar } from "@material-ui/core";

import imageStore from '../../../assets/img/logo-resto.png';

import { getUserDataState } from "features/auth/redux/selectors";
import { useSelector } from "react-redux";
import { getStoreState } from "features/dashboardStore/redux/selectore";

import collapseLogo from '../../../assets/img/newlogo-2.png'

const useStyles = makeStyles(styles);

export default function AdminNavbar(props) {
  const classes = useStyles();
  const { color, rtlActive, brandText } = props;
  const [storeConfig, setStoreConfig] = useState(false);
  const storeState = useSelector(getStoreState);
  // console.log(storeState)
  const appBarClasses = cx({
    [" " + classes[color]]: color,
  });
  const sidebarMinimize =
    classes.sidebarMinimize +
    " " +
    cx({
      [classes.sidebarMinimizeRTL]: rtlActive,
    });
  return (
    <AppBar 
    position="fixed"
    className={classes.appBar + appBarClasses} 
    >
      <Toolbar className={classes.container} style={{marginBottom:'0px'}}>
        <Hidden smDown implementation="css">
          <div className={sidebarMinimize}>
            {props.miniActive ? (
              <Button
                justIcon
                round
                color="white"
                onClick={props.sidebarMinimize}
              >
                <ViewList className={classes.sidebarMiniIcon} />
              </Button>
            ) : (
              <Button
                justIcon
                round
                color="white"
                onClick={props.sidebarMinimize}
              >
                <MoreVert className={classes.sidebarMiniIcon} />
              </Button>
            )}
          </div>
        </Hidden>
        {!props.miniActive ?
        <Box 
        className={classes.storeInfo}
        style={{
          alignItems: 'center'
        }}
        // sx={{ flexGrow: 2.2 }}
        >
          {/* <Avatar src="../../assets/img/logo-resto.jpg"></Avatar> */} 
            <img 
            className={classes.imageStore}
            src={storeState?.store?.imageUrl} 
            />
            <Box
          className={classes.storeTitle}
          style={{
            flexDirection: 'column',
          }}>
            <span
            className={classes.storeName}
            style={{
              fontWeight : 'bold',
              letterSpacing: '4px',
            }}
            >
              {storeState?.store?.name}
            </span>
            <span
            className={classes.storeAdd + ' adresse'}
            style={{
              fontWeight: '400',
              // width:'max-content'
            }}
            >
              {/* 31 Rue Ali Abderrezzak, Casablanca 20250 */}
              {storeState?.store?.address}
            </span>
          </Box>
          </Box>
            :
            <Box className={classes.storeInfo} style={{ alignItems: 'center' }}>
              <img src={collapseLogo} style={{ width: '15%', margin: '0px 0px 0px -20px' }} />
            </Box>
          }
        <div className={classes.flex}> 
          {/* Here we create navbar brand, based on route name */}
          {/* <Button href="#" className={classes.title} color="transparent"> */}
            {/* {brandText} */}
          {/* </Button> */}
         </div> 
        <Hidden smDown implementation="css">
          <AdminNavbarLinks rtlActive={rtlActive} />
        </Hidden> 
       <Hidden mdUp implementation="css">
          <Button
            className={classes.appResponsive}
            color="transparent"
            justIcon
            aria-label="open drawer"
            onClick={props.handleDrawerToggle}
          >
            <Menu />
          </Button>
        </Hidden>
        <Box 
        onClick={props.handleStoreDrawerToggle}
        sx={{ flexGrow: 0.1 }}
        style={{
          cursor: 'pointer',
          display: 'flex',
          flexDirection: 'row',
          // justifyContent: 'space-around',
          alignItems: 'center'
        }}
        >
            {/* <Typography> */}
              <StoreIcon className={classes.storeConfigIcon} />
              <span 
              className={classes.storeConfig}
              style={{
                fontWeight: 'bold'
              }}
              >
                Store Configuration
              </span>
            {/* </Typography> */}
          </Box>
      </Toolbar>
    </AppBar>
  );
}

AdminNavbar.propTypes = {
  color: PropTypes.oneOf(["primary", "info", "success", "warning", "danger"]),
  rtlActive: PropTypes.bool,
  brandText: PropTypes.string,
  miniActive: PropTypes.bool,
  handleDrawerToggle: PropTypes.func,
  handleStoreDrawerToggle: PropTypes.func,
  sidebarMinimize: PropTypes.func,
};
