import React, { useState, useEffect, useRef } from 'react';

import {
  Grid,
  Box,
  TextField,
  Table,
  TableRow,
  TableCell,
  TableHead,
  TableBody,
} from '@material-ui/core';
import GridContainer from '../../../components/material-dashboard-pro-react/Grid/GridContainer';
import Card from '../../../components/material-dashboard-pro-react/Card/Card';
import CardHeader from '../../../components/material-dashboard-pro-react/Card/CardHeader';
import CardBody from '../../../components/material-dashboard-pro-react/Card/CardBody';
import ChangeDurationComponents from '../components/ChangeDuration.components';
import OrderDetailsComponenet from '../components/Order.detail.history';
import ModalComponent from '../../../components/Modal.component';
import {
  formatTimeForOrder,
  formatTime,
  calcDuration,
} from '../../../helpers/index';
import { deliveryManStatus } from '../enumes/index';
import { getHistoryOrders } from '../services/serverApi.service';
import Pagination from '@mui/material/Pagination';
import PaginationItem from '@mui/material/PaginationItem';
import Stack from '@mui/material/Stack';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import SearchIcon from '@mui/icons-material/Search';
import CloseIcon from '@mui/icons-material/Close';
import { DateTimePicker, LocalizationProvider } from '@mui/lab';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import CustomButton from '../../../components/material-dashboard-pro-react/CustomButtons/Button';
import moment from 'moment';
import { useAlert } from 'react-alert';
import { statusCode } from '../mock';
import SelectBoxComponent from '../../../components/SelectBox.component';
import { getUserDataState } from 'features/auth/redux/selectors';
import { useSelector } from 'react-redux';
import { selectStatus } from '../mock';
import { useFormik } from 'formik';

export default function orderHistoryPage() {
  const alert = useAlert();
  const storeId = localStorage.getItem('storeId');
  const inpRefForShortId = useRef();
  const [resultsData, setResultsData] = useState({
    items: [],
  });
  const [order, setOrder] = useState([]);
  const [indexStore, setIndexStore] = useState(-1);
  const [openModal, setOpenModal] = useState('');
  const [page, setPage] = useState(0);
  const [rowPerPage, setRowPerPage] = useState(30);
  const [total, setTotal] = useState(0);
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');
  const [shortId, setShortId] = useState('');
  const [useDate, setUseDate] = useState(false);
  const [statusSelected, setStatusSelected] = useState('');
  //   const formik = useFormik({
  //     initialValues: {
  //         statusSelected: '',
  //       },
  // })
  // store state :
  const storeState = useSelector(getUserDataState);

  const getOrdersCompleted = (skip, limit, paged, start, end, useTime, id) => {
    getHistoryOrders(skip, limit, paged, storeId, start, end, useTime, id)
      .then(({ data }) => {
        setTotal(Math.ceil(data.total / rowPerPage));
        setResultsData({
          // total: data.total,
          items:
            data?.orders &&
            data.orders.map((item, index) => {
              return {
                ...item,
                time: (
                  <div>
                    <span>{formatTimeForOrder(item.createdAt)}</span>
                    <br />
                    <span>{calcDuration(item.createdAt)} min</span>
                  </div>
                ),
              };
            }),
        });
      })
      .catch((error) => {
        error.response.status == statusCode.NOT_FOUND &&
          alert.show('Aucune commande trouvée', {
            type: 'error',
          });
        setResultsData({ items: [] });
      });
  };
  const handleChangePage = (value) => {
    setPage(value - 1);
    getOrdersCompleted(
      value - 1,
      rowPerPage,
      true,
      startDate,
      endDate,
      useDate,
      shortId,
    );
  };
  useEffect(() => {
    getOrdersCompleted(
      page,
      rowPerPage,
      true,
      startDate,
      endDate,
      useDate,
      shortId,
    );
  }, []);
  const handleOpenModal = (item, index, type) => {
    setOrder(item);
    setIndexStore(index);
    setOpenModal(type);
  };
  const closeModal = () => {
    setOpenModal('');
  };

  return (
    <GridContainer className="bordGlobalContainer">
      <Grid container className="bordHeader">
        <span className="bordPageTitle">Historique</span>
      </Grid>
      <Card>
        <CardBody>
          <Card>
            <CardBody>
              <Grid container spacing={3}>
                <Grid item md={3} xs={12} className="mt-4">
                  <input
                    type="text"
                    ref={inpRefForShortId}
                    placeholder="commande ID"
                    style={{
                      border: 'none',
                      borderBottom: '1px solid black',
                    }}
                    defaultValue={shortId}
                  />
                </Grid>

                <Grid item md={3} xs={12} className="mb-3">
                  <LocalizationProvider dateAdapter={AdapterDateFns}>
                    <DateTimePicker
                      renderInput={(props) => <TextField {...props} />}
                      label="Start date"
                      ampm={false}
                      value={startDate}
                      onChange={(newValue) => {
                        setStartDate(moment(newValue).format().split('+')[0]);
                        !useDate && setUseDate(true);
                      }}
                    />
                  </LocalizationProvider>
                </Grid>
                <Grid item md={3} xs={12} className="mb-3">
                  <LocalizationProvider dateAdapter={AdapterDateFns}>
                    <DateTimePicker
                      renderInput={(props) => <TextField {...props} />}
                      label="End Date"
                      ampm={false}
                      value={endDate}
                      onChange={(newValue) => {
                        setEndDate(moment(newValue).format().split('+')[0]);
                        !useDate && setUseDate(true);
                      }}
                    />
                  </LocalizationProvider>
                </Grid>
                <Grid
                  item
                  md={3}
                  xs={12}
                  container
                  justify="flex-space-between"
                >
                  <Box textAlign="center">
                    <CustomButton
                      round
                      type="submit"
                      color="success"
                      size="sm"
                      loaderColor="white"
                      onClick={() => {
                        setShortId(inpRefForShortId.current.value);
                        page > 0 && setPage(0);
                        getOrdersCompleted(
                          page > 0 ? 0 : page,
                          rowPerPage,
                          true,
                          startDate,
                          endDate,
                          useDate,
                          inpRefForShortId.current.value,
                        );
                      }}
                    >
                      <SearchIcon />
                    </CustomButton>
                  </Box>
                  <Box textAlign="center">
                    <CustomButton
                      round
                      type="submit"
                      color="google"
                      size="sm"
                      loaderColor="white"
                      onClick={() => {
                        setUseDate(false);
                        setStartDate(null);
                        setEndDate(null);
                        setShortId('');
                        setStatusSelected('');
                        inpRefForShortId.current.value = '';
                        page > 0 && setPage(0);
                        getOrdersCompleted(
                          page > 0 ? 0 : page,
                          rowPerPage,
                          true,
                          '',
                          '',
                          false,
                          '',
                        );
                      }}
                    >
                      <CloseIcon />
                    </CustomButton>
                  </Box>
                </Grid>
              </Grid>
              <Grid container spacing={3}>
                <Grid item md={3} xs={12} className="mt-4">
                  <span className="font-weight-bold">Status</span>
                  <SelectBoxComponent
                    items={selectStatus.map((type) => ({
                      value: type.label,
                      label: type.label,
                    }))}
                    onValueChange={(value) => {
                      setStatusSelected(value);
                    }}
                    value={statusSelected}
                  />
                </Grid>
              </Grid>
            </CardBody>
          </Card>
          {/* table body */}
          <Table
            sx={{ minWidth: 650 }}
            aria-label="simple table"
            style={{
              border: 'none',
              borderCollapse: 'separate',
              borderSpacing: '0px 10px',
            }}
          >
            <TableHead>
              <TableRow>
                <TableCell
                  className="bordTableCell"
                  align="center"
                  style={{ width: 200 }}
                >
                  Transaction ID
                </TableCell>
                <TableCell
                  className="bordTableCell"
                  align="center"
                  style={{ width: 200 }}
                >
                  Total de la commande
                </TableCell>
                <TableCell
                  className="bordTableCell"
                  align="center"
                  style={{ width: 200 }}
                >
                  Prix apres commission
                </TableCell>
                <TableCell
                  className="bordTableCell"
                  align="center"
                  style={{ width: 200 }}
                >
                  Commission
                </TableCell>
                <TableCell
                  className="bordTableCell"
                  align="center"
                  style={{ width: 200 }}
                >
                  Date
                </TableCell>
                <TableCell
                  className="bordTableCell"
                  align="center"
                  style={{ width: 200 }}
                >
                  Paiement
                </TableCell>
                <TableCell
                  className="bordTableCell"
                  align="center"
                  style={{ width: 200 }}
                >
                  Status
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {resultsData.items &&
                resultsData.items.length > 0 &&
                resultsData.items.map((order, index) => (
                  // (order.deliveryManStatus==deliveryManStatus.ARRIVED_TO_DESTINATION||order.deliveryManStatus==deliveryManStatus.STARTED_DELIVERY||order.deliveryManStatus==deliveryManStatus.DELIVERED)&&
                  // (order.shortId=='615c303b45489d'||order.shortId=='615b2917454802'||order.shortId=='615c310d4548a2')&&
                  <TableRow
                    className="rowTableCellWait"
                    key={order.shortId}
                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                    style={{
                      backgroundColor:
                        order.customerStatus == 'CANCELED'
                          ? '#ffefea'
                          : '#d2f8d2',
                      height: '80px',
                    }}
                  >
                    <TableCell align="center" className="bordTableCell">
                      {order.shortId}
                    </TableCell>
                    <TableCell align="center" className="bordTableCell">
                      {order.payment.orderPrice} Dhs
                    </TableCell>
                    <TableCell align="center" className="bordTableCell">
                      {(
                        order?.payment?.originalOrderPrice -
                        (order?.payment?.originalOrderPrice *
                          order?.payment?.storeCommission) /
                          100
                      ).toFixed(2)}{' '}
                      Dhs
                    </TableCell>
                    <TableCell>
                      {(
                        (order?.payment?.originalOrderPrice *
                          order?.payment?.storeCommission) /
                        100
                      ).toFixed(2)}{' '}
                      Dhs
                    </TableCell>
                    <TableCell align="center" className="bordTableCell">
                      {formatTime(order.createdAt)}
                    </TableCell>
                    <TableCell
                      style={{
                        color:
                          order?.payment?.shouldDeliveryManPayStore === true
                            ? '#2bbd77'
                            : 'red',
                        fontSize: 'medium',
                      }}
                      align="center"
                      className="bordTableCell"
                    >
                      {/* {order?.payment?.shouldDeliveryManPayStore === true ?'à Encaisser':'Ne Pas Encaisse'} */}
                      {(order.payment?.payments || []).find(
                        (x) => x.gateway == 'Card' && x.amount > 0,
                      )
                        ? 'Card'
                        : 'Cash'}
                    </TableCell>
                    <TableCell
                      align="center"
                      className="bordTableCellStat"
                      style={{
                        color:
                          order.customerStatus == 'CANCELED' ? 'red' : 'green',
                        fontWeight: 'bold',
                      }}
                    >
                      {order.customerStatus == 'CANCELED' ? 'Annulée' : 'Livré'}
                    </TableCell>
                  </TableRow>
                  // <Card background={order.customerStatus=='CANCELED'?'#f73378':'#4caf50'} color={'#fff'}  onClick={() => handleOpenModal(order, index, 'orderDetails')}>
                  //     <CardBody>
                  //         <Grid container spacing={3}>
                  //             <Grid item xs={3}>
                  //                 {order?.shortId}
                  //             </Grid>
                  //             <Grid item xs={3}>
                  //                 {order?.payment?.orderPrice} dh
                  //             </Grid>
                  //             <Grid item xs={3}>
                  //                 <div>
                  //                     <span>{formatTime(order.createdAt)}</span><br />
                  //                     {/* <ChangeDurationComponents time={order.createdAt} /> */}
                  //                 </div>
                  //             </Grid>
                  //             <Grid item xs={3}>
                  //                 <div>
                  //                     <span>{order.customerStatus=='CANCELED'?'annulée':order?.payment?.payments.map(item=>item.amount>0&&item.gateway+'\n')}</span><br />
                  //                 </div>
                  //             </Grid>
                  //         </Grid>
                  //     </CardBody>
                  // </Card>
                ))}
              <Box display="flex" justifyContent="center">
                <Stack spacing={2}>
                  <Pagination
                    count={total}
                    onChange={(e, val) => handleChangePage(val)}
                    renderItem={(item) => (
                      <PaginationItem
                        components={{
                          previous: ArrowBackIcon,
                          next: ArrowForwardIcon,
                        }}
                        {...item}
                      />
                    )}
                  />
                </Stack>
              </Box>
            </TableBody>
          </Table>
        </CardBody>
      </Card>
      {openModal && (
        <ModalComponent
          maxWidth={false}
          close={closeModal}
          visible={openModal === 'orderDetails'}
          title="Détail de commande"
        >
          <OrderDetailsComponenet
            order={order}
            close={closeModal}
            index={indexStore}
            // stores={stores}
            // setStores={setStores}
          />
        </ModalComponent>
      )}
    </GridContainer>
  );
}
