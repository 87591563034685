import React,{useState,useEffect} from 'react'
import { useAlert } from 'react-alert';
import DataTableContainerComponent from '../../../components/DataTableSortingContainer.component';
import { getItem,updateItem } from '../services/serverAPI.service'
import CreateItem from '../components/CreateItem'
import SwitchComponent from '../../../components/Switch.component'

import GridContainer from '../../../components/material-dashboard-pro-react/Grid/GridContainer';
import Card from '../../../components/material-dashboard-pro-react/Card/Card';
import CardHeader from '../../../components/material-dashboard-pro-react/Card/CardHeader';
import CardBody from '../../../components/material-dashboard-pro-react/Card/CardBody';
import Button from '../../../components/material-dashboard-pro-react/CustomButtons/Button';
import { Grid, Box, Divider } from '@material-ui/core'

export default function Promo() {
  const alert = useAlert();
    const id = localStorage.getItem('storeId')
    const [resultsData, setResultsData] = useState({
        total: 0,
        items: [],
        tableItems: [],
    });
    const [isLoading, setIsLoading] = useState(false);
    const [page, setPage] = useState(0)
    const pages = [100, 250, 500];
    let numberOfRows = [10, 50, 100];
    const [rowsPerPage, setRowsPerPage] = useState(numberOfRows[0])

    const handlePaginate = (val) => {
        console.log(val,'val');
        // console.log(val.pageSelect, val.numberOfRows);
        setResultsData({
          items: [],
          tableItems: [],
        });
        loadSpecificationList(val.pageSelect, val.numberOfRows);
      };

    const updateIemFromSwitch=(key,key1,value, item)=>{
        key=='promoSetting'?item[key][key1] = value:item[key1]=value
        
        // console.log(JSON.stringify(item));
        console.log(item);
        updateItem(item)
            .then(() => {
                alert.show('item updated', {
                    type: 'success',
                });
                loadSpecificationList(page, rowsPerPage)
            })
            .catch((error) => {
                alert.show(error.message, {
                    type: 'error',
                });
            })
    }
    const loadSpecificationList = (skip,limit) => {
        setIsLoading(true);
        getItem(id,skip,limit)
            .then(({ data }) => {
                console.log(data);
                const promoArray = []
                data.length&&data.map(promo=>{
                    promo.promoSetting.enabled&&promoArray.push(promo)
                })
                promoArray&&promoArray.length>0
                ?
                setResultsData({
                    total: data.length,
                    items: promoArray.map((item, index) => {
                      if(item.promoSetting.enabled){

                        return {
                            ...item,
                            name: item.name||'--',
                            description: item.description?item.description:'--',
                            price: item.price?item.price:'--',
                            imageUrl: item.imageUrl?<img src={item.imageUrl} style={{width:'50px',height:'50px'}}/>:'--',
                            enabled:<SwitchComponent
                            onChange={()=>{}}
                            // onChange={(value) => updateIemFromSwitch('promoSetting','enabled',value, item)}
                            value="checked"
                            checked={item.promoSetting.enabled}
                            label="enabled"
                            />,
                            isMainPromo: 
                                <SwitchComponent
                                onChange={()=>{}}
                                value="checked"
                                checked={item.isMainPromo}
                                label="isMainPromo"
                                />,
                            inStock: 
                                <SwitchComponent
                                onChange={() => {}}
                                value="checked"
                                checked={item.inStock}
                                label="inStock"
                                />,
                            specification:item.specifications&&item.specifications.length > 0&&item.specifications.map(itemFromList=>(
                                <p>{itemFromList}</p>
                            )),
                            type:item.promoSetting?.type?item.promoSetting.type:'--',
                            
                            

                        };
                      }
                    }),
                    
                })
                :setResultsData({items:[]})
                
            })
            .catch(error => console.log(error))
            .finally(() => {
                setIsLoading(false);
            });
    };
    useEffect(() => {
        loadSpecificationList(page, rowsPerPage)
    },[page, rowsPerPage])
    const PromoDetail = ()=>{
        return (
            <div>promoDetail
               
            </div> 
        )
    }
    return (
        <GridContainer className='bordGlobalContainer'>
            <Card>
                <CardHeader className='bordCardTableHeader'>
                    <div className='bordTableTitleDivider'>
                        <span className='bordStoreTableTitle'>
                            Promo List
                        </span>
                        <Divider className='lineTitle' />
                    </div>
                </CardHeader>
                <CardBody>
                <DataTableContainerComponent
                total={resultsData.total}
                numberOfRowsProps={numberOfRows}
                resultsData={resultsData}
                initData={resultsData.items}
                columns={[
                    {
                        Header: 'Image',
                        accessor: 'imageUrl',
                    },
                    {
                        Header: 'Name',
                        accessor: 'name',
                    },
                    {
                        Header: 'Description',
                        accessor: 'description',
                    },
                    {
                        Header: 'Price',
                        accessor: 'price',
                    },
                    {
                        Header: 'inStock',
                        accessor: 'inStock',
                    },
                    {
                        Header: 'specification',
                        accessor: 'specification',
                    },
                    {
                        Header: 'promo(type)',
                        accessor: 'type',
                    },   
                ]}
                onPaginate={handlePaginate}
                isPaginate={true}
                // title="Promo List"
                isLoading={isLoading}
                loadItemsList={loadSpecificationList}
                // CreateItemFormComponent={CreateItem}
            // actionButtons={{
            //     miniEdit: {
            //         color: 'success',
            //           type: 'miniEdit',
            //            component:EditPromo
            //     },
            //         edit: {
            //           color: 'success',
            //           type: 'edit',
            //           component: EditeItem,
            //         },
            //     delete: {
            //         color: 'danger',
            //         type: 'delete',
            //            component:DeleteItem
            //     },
                
            // }}
                />
                </CardBody>
            </Card>
        </GridContainer>
    )
}
