import React from 'react';
import { makeStyles } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import PropTypes from 'prop-types';
import authStyle from '../assets/jss/pages/authStyle';
import imgLogo from '../../../assets/img/newLogo.png';
import imgLogo1 from '../../../assets/img/newlogo-2.png';
import SnackbarContent from '../../../components/material-dashboard-pro-react/Snackbar/SnackbarContent';

const useStyles = makeStyles(authStyle);

export default function AuthPage({ onSubmit, responseMessage, children }) {
  const classes = useStyles();

  return (
    <Grid
      container
      alignItems="center"
      justify="center"
      className={classes.container}
      // className='container'
    >
      <Grid item md={4} xs={10}>
        <Grid
          container
          direction="column"
          alignItems="center"
          justify="center"
          // className={classes.formCard}
          className='formCard'
        >
          {Boolean(responseMessage?.message) && (
            <div 
            // className={classes.snackbarContainer}
            className='snackbarContainer'
            >
              <SnackbarContent
                message={responseMessage.message}
                color={responseMessage.isError ? 'danger' : 'success'}
              />
            </div>
          )}
          <img 
          // className={classes.logoImg} 
          className="logo"
          src={imgLogo1} alt="logo" 
          />
          <form 
          onSubmit={onSubmit} 
          // className={classes.form}>
          className='form'>
            {children}
          </form>
        </Grid>
        <Typography color="textSecondary" align="center" ariant="div">
          Copyright @Kaalix 2022
        </Typography>
      </Grid>
    </Grid>
  );
}

AuthPage.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  responseMessage: PropTypes.object.isRequired,
  children: PropTypes.any.isRequired,
};
