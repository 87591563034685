import React from 'react'

import { Grid, Box ,Typography} from '@material-ui/core'

import { formatTimeForOrder } from '../../../helpers/index'
import ChangeDurationComponents from '../components/ChangeDuration.components'
import Button from '../../../components/material-dashboard-pro-react/CustomButtons/Button'
import { updateOrder } from '../services/serverApi.service'
import { cancel } from 'redux-saga/effects'

const style = {
    titleColor: { color: 'balck',fontWeight: 'bold',fontSize: '15px' },
}
export default function EndPreparationComponent({ order, close }) {
    console.log(order);
    const acceptOrder = () => {
        updateOrder(order.store._id, order._id, 'END_PREPARATION')
            .then(() => close())
            .catch((error) => console.log(error))

    }
    const cancelModel = () => {
      close()
    }
    cancelModel
    return (
        <div> 
            {/* <Grid container spacing={3}>
                <Grid item xs={8} style={{fontFamily:'auto'}}>
                    Commande ID :{order.shortId}
                </Grid>
                <Grid item xs={4} style={{fontFamily:'auto'}}>
                    Commande crée le :{formatTimeForOrder(order.createdAt)}<br />
                    Temps écoulé :<ChangeDurationComponents time={order.createdAt} />
                </Grid>
            </Grid>
            <Grid container spacing={2}>
                <Grid item xs={12} container style={{background:'#f1f1f1',borderRadius:'20px',marginTop:'30px'}}>
                    {order?.payment?.cartDetails.length > 0 && order?.payment?.cartDetails.map((element) => (
                        <Grid item xs={12} container spacing={2}>
                            <Grid item xs={12} style={{ ...style.titleColor }}>
                                {element.name}
                            </Grid>
                            <Grid item xs={12}>
                                {element.items.length > 0 && element.items.map((item) => (
                                    <Box display="flex" justifyContent="center">

                                        <Grid container spacing={2} style={{ display: 'flex', alignItems: 'center' }}>
                                            <Grid item xs={12} container style={{ display: 'flex', alignItems: 'center' }} className='mt-3' spacing={2}>
                                                <Grid item xs={5} style={{ ...style.titleColor, marginLeft: '20px' }}>
                                                    {`${item.name} ( x ${item.quantity} )`}
                                                </Grid>
                                                <Grid item xs={4}>
                                                    <div style={{ borderBottom: '1px solid black', width: '100%', }}></div>
                                                </Grid>
                                                <Grid item xs={2} style={{ ...style.titleColor }}>
                                                    <strong>{item.itemTotalPrice + ' dh'}</strong>
                                                </Grid>
                                            </Grid>
                                            <Grid container >
                                                {item.specifications.length > 0 && item.specifications.map((specification) => (
                                                    <Grid container spacing={2} style={{ display: 'flex', alignItems: 'center', marginLeft: '40px' }}>
                                                        <Grid item xs={12}>
                                                            {specification.name + ' :'}
                                                        </Grid>
                                                        <Grid item xs={12}>
                                                            {specification.list.length > 0 && specification.list.map((content) => (
                                                                <Grid container spacing={2} style={{ display: 'flex', alignItems: 'center' }}>
                                                                    <Grid item xs={4} >
                                                                        {content.name}
                                                                    </Grid>
                                                                    <Grid item xs={5}>
                                                                        <div style={{ borderBottom: '1px solid black', width: '100%', }}></div>
                                                                    </Grid>
                                                                    <Grid item xs={3}>
                                                                        {content.price + ' dh'}
                                                                    </Grid>
                                                                </Grid>
                                                            ))}
                                                        </Grid>
                                                    </Grid>
                                                ))}
                                            </Grid>
                                           

                                        </Grid>
                                    </Box>
                                ))}
                            </Grid>
                        </Grid>
                    ))}
                </Grid>
            </Grid>
            <Grid container spacing={3} justify="flex-end" >
                <Grid item xs={4} className="mt-3" >
                    <Typography variant="h5" style={{fontWeight:'bold' }}>Total : {order?.payment.orderPrice} dh</Typography>
                </Grid>
            </Grid> */}
            <Box textAlign="center">
            
            <Button
               className='actionButton'
                round
                type="submit"
                color="success"
                // disabled={loading}
                // loading={loading}
                loaderColor="white"
                onClick={acceptOrder}
            >
                Oui
            </Button>
            <Button
              style={{
                background: 'none',
                border: '1px solid red',
                color: 'red',
              }}
               className='actionButton'
                round
                type="submit"
                color="success"
                // disabled={loading}
                // loading={loading}
                loaderColor="white"
                onClick={()=>{cancelModel()}}
            >
                Non
            </Button>
            </Box>
          
        </div>
    )
}
