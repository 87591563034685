export const preparationDuration = [5, 10, 15, 20, 25, 30, 35, 40, 45]
export const StoreStatus = {
  WAITING_FOR_ACCEPT: 'WAITING_FOR_ACCEPT',
  ACCEPTED: 'ACCEPTED',
  START_PREPARATION: 'START_PREPARATION',
  END_PREPARATION: 'END_PREPARATION',
};

export const commissionTypes = {
  MOMTHLY: 'MONTHLY',
  SOURCE: 'SOURCE',
}
export const statusCode = {
  NOT_FOUND: 404,
}
export const waitingForStore = ['WAITING_FOR_ACCEPT', 'CREATED']
export const selectStatus = [
  {
    label: 'livré',
    value: 'delivred',
  },
  {
    label: 'Annulé',
    value: 'cancled',
  },
]
 
