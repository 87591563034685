import React, { useEffect, useState } from 'react';
import { Grid, Box, Typography } from '@material-ui/core';
import ReactImageMagnify from 'react-image-magnify';

import { formatTimeForOrder } from '../../../helpers/index';
import ChangeDurationComponents from '../components/ChangeDuration.components';
import Button from '../../../components/material-dashboard-pro-react/CustomButtons/Button';
import { updateOrder } from '../services/serverApi.service';
import { preparationDuration, commissionTypes } from '../mock/index';

const style = {
  titleColor: { color: 'red' },
};
export default function OnlyDetailsComponent({ order, close }) {
  // order.duration = 25
  const [commission, setCommission] = useState('');
  // console.log(order?.payment?.pricePaid);
  console.log(order);
  const calculatCommission = () => {
    let kaalixCommission;
    switch (order.payment?.storeCommissionType) {
      case commissionTypes.MOMTHLY:
        // pricePaid * storeCommission / 100
        kaalixCommission =
          (order?.payment?.pricePaid * order?.payment?.storeCommission) / 100;
        setCommission(kaalixCommission);

        break;
      case commissionTypes.SOURCE:
        // pricePaid * [ storeCommission / ( 100 - storeCommission ) ]
        kaalixCommission =
          order?.payment?.pricePaid *
          (order?.payment?.storeCommission /
            (100 - order?.payment?.storeCommission));
        setCommission(kaalixCommission);
        break;

      default:
        break;
    }
  };
  useEffect(() => {
    calculatCommission();
  }, [order]);
  // console.log('commission state',commission);
  return (
    <div>
      <Grid container spacing={3}>
        <Grid item xs={8}>
          <Box sx={{ display: 'grid' }}>
            <span>
              - Commande ID :<strong>{order.shortId}</strong>
            </span>
            <span>
              - Temps de préparation :<strong>{order?.durationd}</strong>
            </span>
            {/* <span>- price  :<strong>{order?.payment?.orderPrice} dh</strong></span> */}
            <span>
              - Prix :
              <strong>
                {order?.payment?.pricePaid &&
                  order?.payment?.pricePaid.toFixed(2)}{' '}
                dh
              </strong>
            </span>
            <span>
              - Commission Kaalix :
              <strong>{commission && commission.toFixed(2)}</strong>
            </span>
            {/* <span>- price Paid By Client  :<strong>{order?.payment?.pricePaidByClient} dh</strong></span> */}
            <span>
              - Mode de paiement :
              {order?.payment?.payments &&
                order?.payment?.payments &&
                order?.payment?.payments.map(
                  (item) =>
                    item.amount > 0 &&
                    '\n \n' +
                      item.gateway +
                      '(' +
                      item.amount.toFixed(2) +
                      'dh )' +
                      '\n \n',
                )}
            </span>
          </Box>
        </Grid>
        <Grid item xs={4}>
          Commande crée le :{formatTimeForOrder(order.createdAt)}
          <br />
          {/* <ChangeDurationComponents time={order.createdAt} /> */}
        </Grid>
      </Grid>

      <Grid container spacing={2}>
        <Grid item xs={12} container>
          {order?.payment?.cartDetails.length > 0 &&
            order?.payment?.cartDetails.map((element) => (
              <Grid item xs={12} container spacing={2}>
                <Grid item xs={12} style={{ ...style.titleColor }}>
                  {element.name}
                </Grid>
                <Grid item xs={12}>
                  {element.items.length > 0 &&
                    element.items.map((item) => (
                      <Box display="flex" justifyContent="center">
                        <Grid
                          container
                          spacing={2}
                          style={{ display: 'flex', alignItems: 'center' }}
                        >
                          <Grid
                            item
                            xs={12}
                            container
                            style={{ display: 'flex', alignItems: 'center' }}
                            className="mt-3"
                            spacing={2}
                          >
                            <Grid
                              item
                              xs={5}
                              style={{
                                ...style.titleColor,
                                marginLeft: '20px',
                              }}
                            >
                              {`${item.name} ( x ${item.quantity} )`}
                            </Grid>
                            <Grid item xs={4}>
                              <div
                                style={{
                                  borderBottom: '1px solid black',
                                  width: '100%',
                                }}
                              ></div>
                            </Grid>
                            <Grid item xs={2}>
                              <strong>{item.itemTotalPrice + ' dh'}</strong>
                            </Grid>
                          </Grid>
                          {/* <Grid item xs={12} className="mt-2" container> */}
                          <Grid container>
                            {item.specifications.length > 0 &&
                              item.specifications.map((specification) => (
                                <Grid
                                  container
                                  spacing={2}
                                  style={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    marginLeft: '40px',
                                  }}
                                >
                                  <Grid item xs={12}>
                                    {specification.name + ' :'}
                                  </Grid>
                                  <Grid item xs={12}>
                                    {specification.list.length > 0 &&
                                      specification.list.map((content) => (
                                        <Grid
                                          container
                                          spacing={2}
                                          style={{
                                            display: 'flex',
                                            alignItems: 'center',
                                          }}
                                        >
                                          <Grid item xs={4}>
                                            {content.name}
                                          </Grid>
                                          <Grid item xs={5}>
                                            <div
                                              style={{
                                                borderBottom: '1px solid black',
                                                width: '100%',
                                              }}
                                            ></div>
                                          </Grid>
                                          <Grid item xs={3}>
                                            {content.price + ' dh'}
                                          </Grid>
                                        </Grid>
                                      ))}
                                  </Grid>
                                </Grid>
                              ))}
                          </Grid>
                          {/* </Grid> */}
                        </Grid>
                      </Box>
                    ))}
                </Grid>
              </Grid>
            ))}
        </Grid>
      </Grid>

      <Grid container spacing={3}>
        {order?.payment?.invoiceUrl && (
          <Grid item xs={6}>
            <ReactImageMagnify
              {...{
                smallImage: {
                  alt: 'img',
                  isFluidWidth: true,
                  src: order?.payment?.invoiceUrl,
                },
                largeImage: {
                  src: order?.payment?.invoiceUrl,
                  width: 1300,
                  height: 1200,
                },
              }}
            />
          </Grid>
        )}
        <Grid container spacing={3} justify="flex-end">
          <Grid item xs={4} className="mt-3">
            <Typography variant="h5">
              Total :{' '}
              {order?.payment.orderPrice &&
                order?.payment.orderPrice?.toFixed(2)}
              dh
            </Typography>
          </Grid>
        </Grid>
      </Grid>
    </div>
  );
}
