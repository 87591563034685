import React , {useEffect} from 'react'

export default function logoutComponent() {
    useEffect(() => {
        localStorage.removeItem('persist:root')
        window.location.reload()
    }, [])
    return (
        <div>
            
        </div>
    )
}
