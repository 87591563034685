import React, { useState, useEffect } from 'react'
import { useAlert } from 'react-alert';
import DataTableContainerComponent from '../../../components/DataTableSortingContainer.component';
import { getCategoryList, updateStockInCategoryList } from '../services/serverAPI.service'
import CreatePublicityComponent from '../components/CreateCategoryList'
import SwitchComponent from '../../../components/Switch.component'
import DeleteCategoryListComponent from '../components/DeleteCategoryList.component'
import EditeCategoryListComponent from '../components/EditeCategoryList.component'
import { Grid, Box, Divider } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';

import GridContainer from '../../../components/material-dashboard-pro-react/Grid/GridContainer';
import Card from '../../../components/material-dashboard-pro-react/Card/Card';
import CardHeader from '../../../components/material-dashboard-pro-react/Card/CardHeader';
import CardBody from '../../../components/material-dashboard-pro-react/Card/CardBody';
import Button from '../../../components/material-dashboard-pro-react/CustomButtons/Button';

import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';



export default function CategoryList() {

    const alert = useAlert();
    const id = localStorage.getItem('storeId')
    const [resultsData, setResultsData] = useState({
        total: 0,
        items: [],
        tableItems: [],
    });
    const [isLoading, setIsLoading] = useState(false);
    const [page, setPage] = useState(0)
    let numberOfRows = [10, 50, 100];
    const [rowsPerPage, setRowsPerPage] = useState(numberOfRows[0])
    const [counter, setCounter] = useState(0)
   



    const loadCategoryList = (skip,limit) => {
        setIsLoading(true);
        getCategoryList(id,skip,limit)
            .then(({ data }) => {
                let count = 0;
                data.productCategories.forEach((v) => ((v.inStock === true|| v.inStock==='true') && count++));
                setCounter(count)
                console.log(data);
                setResultsData({
                    total: data.total,
                    items: data.productCategories.map((item, index) => {
                        return {
                            ...item,
                            name: item.name||'--',
                            description: item.description ? item.description : '--',
                            inStockCol:
                                <SwitchComponent
                                    onChange={(value) => updateStocks(value, item,count-1)}
                                    value="checked"
                                    checked={item.inStock}
                                    label="InStock"
                                />
                            ,
                            imageUrl: item.imageUrl ? <img src={item.imageUrl} alt="" border="3" height="50" width="60" /> : '--',

                        };
                    }),
                    
                });
            })
            .catch(error => console.log(error))
            .finally(() => {
                setIsLoading(false);
            });
    };
    const updateStocks = async (value, item,count) => {
        if(value==false){
            if(count>=1){

                console.log(count)
                item.inStock = value,
                updateStockInCategoryList(item)
                    .then(() => {
                        alert.show('stock updated', {
                            type: 'success',
                        });
                        loadCategoryList()
                    })
                    .catch((error) => {
                        alert.show(error.message, {
                            type: 'error',
                        });
                    })
            }
        }else{
            item.inStock = value,
            updateStockInCategoryList(item)
                .then(() => {
                    alert.show('stock updated', {
                        type: 'success',
                    });
                    loadCategoryList()
                })
                .catch((error) => {
                    alert.show(error.message, {
                        type: 'error',
                    });
                })
        }
    }
    useEffect(() => {
        loadCategoryList(page, rowsPerPage)
    }, [page, rowsPerPage])
    const handlePaginate = (val) => {
        // console.log(val,'val');
        // console.log(val.pageSelect, val.numberOfRows);
        setResultsData({
          items: [],
          tableItems: [],
        });
        loadCategoryList(val.pageSelect, val.numberOfRows);
      };

     

    return (
        <GridContainer className='bordGlobalContainer'>
            <Card>
                <CardHeader className='bordCardTableHeader'>
                    <div className='bordTableTitleDivider'>
                        <span className='bordStoreTableTitle'>
                            Catégories List
                        </span>
                        <Divider className='lineTitle' />
                    </div>
                </CardHeader>
                <CardBody>
               
                   <DataTableContainerComponent 
                    total={resultsData.total}
                    numberOfRowsProps={numberOfRows}
                    resultsData={resultsData}
                    initData={resultsData.items}
                    onPaginate={handlePaginate}
                    isPaginate={true}
                    page={page + 1}
                    columns={[
                        {
                            Header: 'Image',
                            accessor: 'imageUrl',
                        },
                        {
                            Header: 'Name',
                            accessor: 'name',
                        },
                        {
                            Header: 'Description',
                            accessor: 'description',
                        },
                        {
                            Header: 'Stock',
                            accessor: 'inStockCol',
                        },
                    ]}
                    isLoading={isLoading}
                    loadItemsList={loadCategoryList}
                    />
                </CardBody>
            </Card>
        </GridContainer>
        // <DataTableContainerComponent
        // total={resultsData.total}
        //     numberOfRowsProps={numberOfRows}
        //     resultsData={resultsData}
        //     initData={resultsData.items}
        //     onPaginate={handlePaginate}
        //     isPaginate={true}
        //     columns={[
        //         {
        //             Header: 'Name',
        //             accessor: 'name',
        //         },
        //         {
        //             Header: 'Image',
        //             accessor: 'imageUrl',
        //         },
        //         {
        //             Header: 'Description',
        //             accessor: 'description',
        //         },
        //         {
        //             Header: 'Stock',
        //             accessor: 'inStockCol',
        //         },
        //         // {
        //         //     Header: 'actions',
        //         //     accessor: 'actions',
        //         // },
        //     ]}
        //     // title="category List"
        //     isLoading={isLoading}
        //     loadItemsList={loadCategoryList}
        //     // CreateItemFormComponent={CreatePublicityComponent}
        //     // actionButtons={{
                
        //     //         edit: {
        //     //           color: 'success',
        //     //           type: 'edit',
        //     //           component: EditeCategoryListComponent,
        //     //         },
        //     //     delete: {
        //     //         color: 'danger',
        //     //         type: 'delete',
        //     //            component:DeleteCategoryListComponent
        //     //     },
        //     // }}
        // />
    )
}
