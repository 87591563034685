import axios from 'axios';
import {
  backendServerConfig,
  MICROSERVICE_BASE_URL,
} from '../../../config/backendServer.config';

// ***************************** start CategoryList ****************************
export function getCategoryList(id,paged, limit) {
    return axios.get(
      `${MICROSERVICE_BASE_URL.STORES}/product_category?page=${paged || 0}&limit=${
        limit || 0
      }&storeId=${id}&paged=${true}`,
    );
  }
  export function getCategoryLists(id,paged, limit) {
    return axios.get(
      `${MICROSERVICE_BASE_URL.STORES}/product_category?limit=${
        limit || 0
      }&storeId=${id}&paged=${paged || false}`,
    );
  }

export function updateStockInCategoryList(item) {
    return axios.put(
      `${MICROSERVICE_BASE_URL.STORES}/product_category/${item._id}`,{...item}
    );
  }
export function createCategoryList(data) {
    return axios.post(
      `${MICROSERVICE_BASE_URL.STORES}/product_category`,{...data}
    );
  }
export function deleteCategoryList(id) {
    return axios.delete(
      `${MICROSERVICE_BASE_URL.STORES}/product_category/${id}`
    );
  }
// ***************************** end CategoryList ****************************

// ************************** start Category *******************
export function getCategorys(id,paged,limit) {
  return axios.get(
    `${MICROSERVICE_BASE_URL.STORES}/product?page=${paged || 0}&limit=${
      limit || 0
    }&storeId=${id}&paged=${true}`,
  );
}
export function getCategory(id, limit, paged) {
  return axios.get(
    `${MICROSERVICE_BASE_URL.STORES}/product?limit=${
      limit || 0
    }&storeId=${id}&paged=${paged || false}`,
  );
}

export function createCategory(data) {
  return axios.post(
    `${MICROSERVICE_BASE_URL.STORES}/product`,{...data}
  );
}
export function deleteCategory(id) {
  return axios.delete(
    `${MICROSERVICE_BASE_URL.STORES}/product/${id}`
  );
}

export function updateCategory(item) {
  return axios.put(
    `${MICROSERVICE_BASE_URL.STORES}/product/${item._id}`,{...item}
  );
}

// **************************** end Category **********************


// **************************** start Specification **********************

export function getSpecification(id, limit, paged) {
  return axios.get(
    `${MICROSERVICE_BASE_URL.STORES}/specification?limit=${
      limit || 0
    }&storeId=${id}&paged=${paged || false}`,
  );
}

export function getSpecifications(id,paged,limit) {
  return axios.get(
    `${MICROSERVICE_BASE_URL.STORES}/specification?page=${paged || 0}&limit=${
      limit || 0
    }&storeId=${id}&paged=${true}`,
  );
}


export function addSpecification(data) {
  return axios.post(
    `${MICROSERVICE_BASE_URL.STORES}/specification`,{...data}
  );
}

export function deleteSpecification(id) {
  return axios.delete(
    `${MICROSERVICE_BASE_URL.STORES}/specification/${id}`
  );
}
export function updateSpecification(item) {
  console.log(item);
  return axios.put(
    `${MICROSERVICE_BASE_URL.STORES}/specification/${item._id}`,{...item}
  );
}
// **************************** end Specification **********************

// **************************** end Item **********************
export function getItem(id, paged,limit,productId) {
  return axios.get(
    `${MICROSERVICE_BASE_URL.STORES}/item?page=${paged || 0}&limit=${
      limit || 0
    }&storeId=${id}&paged=${true}&productId=${productId || ''}`,
  );
}

export function createItem(data) {
  return axios.post(
    `${MICROSERVICE_BASE_URL.STORES}/item`,{...data}
  );
}

export function deleteItem(id) {
  return axios.delete(
    `${MICROSERVICE_BASE_URL.STORES}/item/${id}`
  );
}

export function updateItem(item) {
  return axios.put(
    `${MICROSERVICE_BASE_URL.STORES}/item/${item._id}`,{...item}
  );
}

// **************************** end Item **********************
// **************************** start product **********************
export function getProduct(id) {
  return axios.get(
    `${MICROSERVICE_BASE_URL.CONTENT}/store/${id}/products`,
  );
}
export function updateProduct(storeId,payload) {
  console.log(payload);
  console.log(JSON.stringify(payload));
  return axios.post(
    `${MICROSERVICE_BASE_URL.CONTENT}/mapping/store/${storeId}/products`,{products:payload}
  );
}
// **************************** end product **********************
// **************************** start tag **********************
export function getTag(page, limit, paged) {
  return axios.get(
    `${MICROSERVICE_BASE_URL.ADMIN}/tag_category?page=${page || 0}&limit=${
      limit || 0
    }&paged=${paged || false}`,
  );
}
// **************************** end tag **********************
// **************************** start tag **********************
export function updateStore(id, data) {
  return axios.put(`${MICROSERVICE_BASE_URL.STORES}/store/${id}`, data);
}
// **************************** end tag **********************