import React from 'react';
import { Box } from '@material-ui/core';
import InputAdornment from '@material-ui/core/InputAdornment';
import MailIcon from '@material-ui/icons/Mail';
import LockOpenIcon from '@material-ui/icons/LockOpen';
import * as yup from 'yup';
import { useFormik } from 'formik';
import Typography from '@material-ui/core/Typography';
import { Link } from 'react-router-dom';
import PersonIcon from '@material-ui/icons/Person';
import Button from '../../../components/material-dashboard-pro-react/CustomButtons/Button';
import CustomInput from '../../../components/material-dashboard-pro-react/CustomInput/CustomInput';
import { grayColor } from '../../../assets/jss/material-dashboard-pro-react';
import { registerApi } from '../services/serverApi.service';
import AuthPage from '../pages/Auth.page';
import useAuth from '../hooks/useAuth.hook';

const validationSchema = yup.object({
  username: yup.string().required(),
  email: yup.string().email().required(),
  password: yup.string().required(),
  confirmPassword: yup
    .string()
    .oneOf([yup.ref('password'), null], 'Passwords must match'),
});

export default function RegisterFormComponent() {
  const {
    isLoading,
    responseMessage,
    setResponseMessage,
    setIsLoading,
    classes,
  } = useAuth();
  const formik = useFormik({
    initialValues: {
      email: '',
      username: '',
      password: '',
      passwordVerification: '',
    },
    validationSchema,
  });

  // function onSubmit(e) {
  //   e.preventDefault();
  //   setIsLoading(true);
  //   registerApi(formik.values)
  //     .then(() => {
  //       setResponseMessage({
  //         message: 'Votre compte a été bien créé',
  //         isError: false,
  //       });
  //     })
  //     .catch((error) => {
  //       setResponseMessage({
  //         message: error.response.data.message || error.message,
  //         isError: true,
  //       });
  //     })
  //     .finally(() => {
  //       setIsLoading(false);
  //     });
  // }

  // return (
  //   <AuthPage onSubmit={onSubmit} responseMessage={responseMessage}>
  //     <CustomInput
  //       labelText="Username"
  //       id="username"
  //       formControlProps={{
  //         fullWidth: true,
  //       }}
  //       type="text"
  //       error={formik.touched.username && Boolean(formik.errors.username)}
  //       inputProps={{
  //         value: formik.values.username,
  //         onBlur: formik.handleBlur('username'),
  //         onChange: formik.handleChange('username'),
  //         endAdornment: (
  //           <InputAdornment position="end">
  //             <PersonIcon style={{ color: grayColor[2] }} />
  //           </InputAdornment>
  //         ),
  //       }}
  //     />
  //     <CustomInput
  //       labelText="Email"
  //       id="email"
  //       formControlProps={{
  //         fullWidth: true,
  //       }}
  //       type="email"
  //       error={formik.touched.email && Boolean(formik.errors.email)}
  //       inputProps={{
  //         value: formik.values.email,
  //         onBlur: formik.handleBlur('email'),
  //         onChange: formik.handleChange('email'),
  //         endAdornment: (
  //           <InputAdornment position="end">
  //             <MailIcon style={{ color: grayColor[2] }} />
  //           </InputAdornment>
  //         ),
  //       }}
  //     />
  //     <CustomInput
  //       labelText="Mot de passe"
  //       id="password"
  //       formControlProps={{
  //         fullWidth: true,
  //       }}
  //       error={formik.touched.password && Boolean(formik.errors.password)}
  //       inputProps={{
  //         value: formik.values.password,
  //         onBlur: formik.handleBlur('password'),
  //         onChange: formik.handleChange('password'),
  //         type: 'password',
  //         endAdornment: (
  //           <InputAdornment position="end">
  //             <LockOpenIcon style={{ color: grayColor[2] }} />
  //           </InputAdornment>
  //         ),
  //       }}
  //     />
  //     <CustomInput
  //       labelText="Confirmation de mot de passe"
  //       id="confirmPassword"
  //       formControlProps={{
  //         fullWidth: true,
  //       }}
  //       error={
  //         formik.touched.confirmPassword &&
  //         Boolean(formik.errors.confirmPassword)
  //       }
  //       inputProps={{
  //         value: formik.values.confirmPassword,
  //         onBlur: formik.handleBlur('confirmPassword'),
  //         onChange: formik.handleChange('confirmPassword'),
  //         type: 'password',
  //         endAdornment: (
  //           <InputAdornment position="end">
  //             <LockOpenIcon style={{ color: grayColor[2] }} />
  //           </InputAdornment>
  //         ),
  //       }}
  //     />

  //     <Box textAlign="center">
  //       <Button
  //         className={classes.loginBtn}
  //         round
  //         type="submit"
  //         color="success"
  //         disabled={!formik.dirty || !formik.isValid}
  //         loading={isLoading}
  //         loaderColor="white"
  //       >
  //         LOGIN
  //       </Button>
  //     </Box>

  //     <Typography
  //       ariant="caption"
  //       display="block"
  //       align="center"
  //       gutterBottom
  //       color="textSecondary"
  //     >
  //       Vous avez deja un compte ? <Link to="/login">Se connecter</Link>
  //     </Typography>
  //   </AuthPage>
  // );
}
