const _package = require('../../package.json')
const env = _package.config.env;
// const env = 'staging'; // 'prod', 'staging', 'localhost'

export const backendServerConfig = {
  appUrl:
    env == 'prod'
      ? 'https://ms.kaalix.com'
      : env == 'staging'
        // ? 'http://k8s-default-ingresss-f5f101d3ef-1331662391.us-east-2.elb.amazonaws.com'
        ? 'https://preprod.kaalix.com'
        : 'http://localhost',
  TIMEOUT: '',
  grant: 'c3RvcmVBcHBAa2FhbGl4LmNvbmY6c2VjcmV0',
};
export const backendSocketConfig =
// 'http://k8s-default-ingresss-62388db349-42299485.us-east-2.elb.amazonaws.com'
{
  appUrl:
    env == 'prod'
      ? 'https://ms.kaalix.com'
      : env == 'staging'
        // ? 'http://k8s-default-ingresss-62388db349-42299485.us-east-2.elb.amazonaws.com'
        ? 'https://preprod.kaalix.com'
        : 'http://localhost',
  path: '/api/v1/sockets/connect',
  config: {
    // const socket = io("http://localhost:4010", {
    path: '/api/v1/sockets/connect',
    query: { token: 'Bearer ccc4d35706c9bab63815f38bf78e4a22246bcedc' },
  },
  TIMEOUT: '',
  grant: 'cm9vdEFwcEBrYWFsaXguY29uZjpzZWNyZXQ=',
};
// const dev = false;

// export const backendServerConfig = {
//   appUrl: dev
//     ? 'http://localhost'
//     : 'http://k8s-default-ingresss-f5f101d3ef-1331662391.us-east-2.elb.amazonaws.com',
//   TIMEOUT: '',
//   // grant: 'cm9vdEFwcEBrYWFsaXguY29uZjpzZWNyZXQ=',
//   grant: 'c3RvcmVBcHBAa2FhbGl4LmNvbmY6c2VjcmV0',
// };
// export const backendSocketConfig =
// {

//   appUrl: 'http://k8s-default-ingresss-62388db349-42299485.us-east-2.elb.amazonaws.com',
//   path: '/api/v1/sockets/connect',
//   config:{
//     path: '/api/v1/sockets/connect',
//     query: { token: 'Bearer ccc4d35706c9bab63815f38bf78e4a22246bcedc' },
//   },
//   TIMEOUT: '',
//   grant: 'cm9vdEFwcEBrYWFsaXguY29uZjpzZWNyZXQ=',
// };
export const SOCKET_URL = {
  connect: `${backendSocketConfig.appUrl}/api/v1/sockets/connect`
};

export const MICROSERVICE_BASE_URL = env == 'localhost'
  ? {
    ADMIN: `${backendServerConfig.appUrl}:4004/api/v1/admin`,
    AUTH_SERV: `${backendServerConfig.appUrl}:4000/api/v1/users`,
    CUSTOMER: `${backendServerConfig.appUrl}:4005/api/v1/customer`,
    CONTENT: `${backendServerConfig.appUrl}:4001/api/v1/content`,
    PAYMENT_SERV: `${backendServerConfig.appUrl}:4006/api/v1/srv-payment`,
    CUSTOMER_PAYMENT: `${backendServerConfig.appUrl}:4006/api/v1/customer-payment`,
    ORDERS: `${backendServerConfig.appUrl}:4008/api/v1/orders`,
    STORES: `${backendServerConfig.appUrl}:4003/api/v1/stores`,
    PROVIDER: `${backendServerConfig.appUrl}:4007/api/v1/delivery-man`,
    DELIVERY_MAN_PAYMENT: `${backendServerConfig.appUrl}:4011/api/v1/delivery-man-payment`,
    DISPATCHING: `${backendServerConfig.appUrl}:4009/api/v1/dispatching`,
    DELIVERY_MAN: `${backendServerConfig.appUrl}:4007/api/v1/delivery-man`,
    CRON: `${backendServerConfig.appUrl}:4015/api/v1/cron`,

  }
  : {
    ADMIN: `${backendServerConfig.appUrl}/api/v1/admin`,
    AUTH_SERV: `${backendServerConfig.appUrl}/api/v1/users`,
    CUSTOMER: `${backendServerConfig.appUrl}/api/v1/customer`,
    CONTENT: `${backendServerConfig.appUrl}/api/v1/content`,
    PAYMENT_SERV: `${backendServerConfig.appUrl}/api/v1/srv-payment`,
    CUSTOMER_PAYMENT: `${backendServerConfig.appUrl}/api/v1/customer-payment`,
    ORDERS: `${backendServerConfig.appUrl}/api/v1/orders`,
    STORES: `${backendServerConfig.appUrl}/api/v1/stores`,
    UPLOAD_IMAGE: `${backendServerConfig.appUrl}/api/v1/media/upload/get_link`,
    DELIVERY_MAN: `${backendServerConfig.appUrl}/api/v1/delivery-man`,
    DISPATCHING: `${backendServerConfig.appUrl}/api/v1/dispatching`,
    PROVIDER: `${backendServerConfig.appUrl}/api/v1/delivery-man`,
    DELIVERY_MAN_PAYMENT: `${backendServerConfig.appUrl}/api/v1/delivery-man-payment`,
    CRON: `${backendServerConfig.appUrl}/api/v1/cron`,
  };
