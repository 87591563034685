import { takeEvery, takeLatest, put, all, fork } from 'redux-saga/effects'
import axios from 'axios'
import { getSettingSuccessAction, getSettingFailAction } from './actions/setting.actions'
import { GET_SETTING_REQUEST } from './constants/setting.constants'
import { MICROSERVICE_BASE_URL } from '../../../config/backendServer.config'

const getSettingFromAPI = () => {
  const data = axios.get(
    `${MICROSERVICE_BASE_URL.CRON}/setting`,
  );
  return data
}

function* getSetting() {
  try {
    const { data } = yield getSettingFromAPI()
    yield put(getSettingSuccessAction(data?.cronSetting))

  } catch (error) {
    yield put(getSettingFailAction(error?.response?.data))


  }
}


export default function* watcherSage() {
  yield takeLatest(GET_SETTING_REQUEST, getSetting)

}