/*!

=========================================================
* Material Dashboard React - v1.10.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/material-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
// @material-ui/icons
import Dashboard from '@material-ui/icons/Dashboard';
import LocalShippingIcon from '@material-ui/icons/LocalShipping';
import SportsMotorsportsIcon from '@material-ui/icons/SportsMotorsports';
import StoreIcon from '@material-ui/icons/Store';
import NotificationsIcon from '@material-ui/icons/Notifications';
import HistoryIcon from '@material-ui/icons/History';
import LogoutIcon from '@mui/icons-material/Logout';
// import CoffeeMakerNotificationsIcon from '@material-ui/icons/CoffeeMaker';
import CoffeeMakerIcon from '@mui/icons-material/CoffeeMaker';
import HomeIcon from '@mui/icons-material/Home';
import TwoWheelerIcon from '@mui/icons-material/TwoWheeler';
// core components/views for Admin layout
import ProductsPage from './features/dashboardStore/pages/Products.page';
import CategoryList from './features/dashboardStore/pages/CategoryList';
import Category from './features/dashboardStore/pages/Category';
import Specifications from './features/dashboardStore/pages/Specification';
import Item from './features/dashboardStore/pages/Item';
import Promo from './features/dashboardStore/pages/Promo';
import StoreConfig from './features/dashboardStore/pages/StoreConfig';
import OrdersWaitForAccept from "./features/commades/pages/OrdersWaitForAccept.page";
import orderInProgress from "./features/commades/pages/orderInProgress.page";
import orderWaitingDelivery from "./features/commades/pages/orderWaitingDelivery.page";
import OrderHistory from "./features/commades/pages/orderHistory.page";
import logoutComponent from './features/auth/components/logout.component';
import BoardPage from './features/commades/pages/board.page';
// core components/views for RTL layout
const path = window.location.pathname;

import Test from './features/dashboardStore/pages/test';


const dashboardRoutesWebStore = [ 
  {
    collapse: true,
    name: 'Order',
    icon: LocalShippingIcon,
    state: 'orderCollapse',
    displayParent : false,
    views: [
      // {
      //   path: '/orders/order-waiting',
      //   name: 'Nouvelle commande',
      //   mini: <NotificationsIcon/>,
      //   component: OrdersWaitForAccept,
      //   layout: '/admin',
      // },
      // {
      //   path: '/orders/order-in-progress',
      //   name: 'Commandes en cours',
      //   mini: <CoffeeMakerIcon/>,
      //   component: orderInProgress,
      //   layout: '/admin',
      // },
      // {
      //   path: '/orders/order-waiting-delivery',
      //   name: 'En attente de livraison',
      //   mini: <TwoWheelerIcon />,
      //   component: orderWaitingDelivery,
      //   layout: '/admin',
      // },
      {
        path: '/order/home',
        name: 'Accueil',
        mini: <HomeIcon />,
        component: BoardPage,
        layout: '/admin',
      },
      {
        path: '/orders/history',
        name: 'History',
        mini: <HistoryIcon/>,
        component: OrderHistory,
        layout: '/admin',
      },
    ],
  },
  // {
  //   collapse: true,
  //   name: 'store Configuration',
  //   icon: StoreIcon,
  //   displayParent : true,
  //   state: 'seconddeliveryInfoCollapse',
  //   views: [
  //     {
  //       path: '/store',
  //       name: 'Store',
  //       mini: 'ST',
  //       component: ProductsPage,
  //       layout: '/admin',
  //     },
  //     {
  //       path: '/categories-list',
  //       name: 'categories list (Product category list)',
  //       mini: 'CL',
  //       component: CategoryList,
  //       layout: '/admin',
  //     },
  //     {
  //       path: '/categories',
  //       name: 'Categories(product List)',
  //       mini: 'CA',
  //       component: Category,
  //       layout: '/admin',
  //     },
  //     {
  //       path: '/items',
  //       name: 'Items',
  //       mini: 'IT',
  //       component: Item,
  //       layout: '/admin',
  //     },
  //     {
  //       path: '/specifications',
  //       name: 'Specifications',
  //       mini: 'SP',
  //       component: Specifications,
  //       layout: '/admin',
  //     },
  //     {
  //       path: '/promo',
  //       name: 'Promo',
  //       mini: 'PR',
  //       component: Promo,
  //       layout: '/admin',
  //     },
  //     {
  //       path: '/configuration',
  //       name: 'Configuration',
  //       mini: 'CO',
  //       component: StoreConfig,
  //       layout: '/admin',
  //     },
  //   ],
  // },
  {
    collapse: true,
    name: 'Logout',
    icon: LocalShippingIcon,
    state: 'logoutCollapse',
    displayParent : false,
    views: [
      {
        path: '/orders/logout',
        name: 'Logout',
        mini: <LogoutIcon/>,
        component: logoutComponent,
        layout: '/admin',
      },
    ],
  },
];
// const myDashboard = path.startsWith('/dashboard-store')
//   ? dashStore
//   : dashboardRoutes2;

export default dashboardRoutesWebStore;
