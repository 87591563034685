import React , { useState }from 'react'
import {calcDuration} from '../../../helpers/index'

export default function ChangeDurationComponents({time}) {
    const [timeWithMin,setTimeWithMin] = useState(calcDuration(time))
        // let timeWithMin =calcDuration(time)
        setInterval(() => {
            setTimeWithMin(calcDuration(time))
       }, 60000) 
       return (
        <>
            <span>{timeWithMin} min</span>
            
        </>
      )
}
