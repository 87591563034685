import React, { useEffect, useState, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Howl, Howler } from 'howler';
import { Grid, Box, Card } from '@material-ui/core';
import HearingIcon from '@mui/icons-material/Hearing';
import CallEndIcon from '@mui/icons-material/CallEnd';
import { useHistory } from "react-router-dom";
import { useAlert } from 'react-alert';
import Button from '../../../components/material-dashboard-pro-react/CustomButtons/Button';
import ModalComponent from '../../../components/Modal.component';

import {
  getOrderRequestAction,
  changeOrderStatusAction,
  newOrderAction,
  validateOrder,
  cancelOrder,
  firstTimeAction,
} from '../redux/actions/order.actions';
import { getSettingRequestAction } from '../redux/actions/setting.actions';
import { getOrdersDataState, getSettingDataState } from '../redux/selectors';
import { getUserDataState } from '../../auth/redux/selectors';
import { socketConnect, getCronConfig } from '../services/serverApi.service';
import { StoreStatus } from '../mock';
// import sound1 from '../../../audio/mixkit-bell-notification-933.wav';
import sound1 from '../../../audio/order-recieve.wav';
var socket;
export default function AllOrdersPage(props) {
  const alert = useAlert();
  const buttonRef = useRef(null);
  const [socket, setSocket] = useState(null);
  const [retryToGetSetting, setRetryToGetSetting] = useState(0);
  const [audio, setAudio] = useState(new Audio(sound1));
  let history = useHistory();
  const dispatch = useDispatch();

  const orders = useSelector(getOrdersDataState);
  const userData = useSelector(getUserDataState);
  const settingData = useSelector(getSettingDataState);
  const { setting, error, loading } = settingData;

  const storeId = localStorage.getItem('storeId');
  const cityId = localStorage.getItem('cityId');
  const notification = () => {
    audio.loop = true;
  };
  const handleCloseModal = () => {
    dispatch(firstTimeAction());
  };
  const getSetting = () => {
    if (
      !loading &&
      Object.keys(setting || {}).length == 0 &&
      retryToGetSetting < 3
    ) {
      dispatch(getSettingRequestAction());
      setRetryToGetSetting(retryToGetSetting + 1);
    }
  };
  useEffect(() => {
    notification();
  }, []);
  useEffect(() => {
    !orders.firstTime &&
      userData &&
      dispatch(getOrderRequestAction(cityId, storeId));
  }, [userData, orders.firstTime]);
  useEffect(() => {
    if (!socket && userData && !orders.firstTime) {
      setSocket(socketConnect(userData.accessToken));
    }
    function subscribeToAdminRoom() {
      if (socket) {
        socket.emit('JOIN_ROOM', storeId);
      }
    }
    if (socket) {
      subscribeToAdminRoom();
    }
    socket &&
      userData &&
      socket.on('error', (data) => {
        alert.show(data, {
          type: 'error',
        });
      });
  }, [socket, userData, orders.firstTime]);
  useEffect(() => {
    getSetting();
  }, [retryToGetSetting]);

  const playSnds = async ()=>{
    
    if(window.navigator.userAgent.indexOf("Mac") !== -1){
      // console.log('ios')
      (function () {
        console.log('heelo',text)
        var text = document.querySelector('#showtext')
        var playButton = document.querySelector('#playSound')

           function playSnd() {
          audio.play()
          text.innerHTML='SHOOOOOOOOO TEEEEEXTT'

           };
           playButton.onclick = playSnd() 
           playButton.ontouchstart = playSnd() 
          
     
       }    
     )()
      
   
      (async function () {
       
        if('AudioContext' in window || 'webkitAudioContext' in window) {  
                
        //       var AudioContext = window.AudioContext || window.webkitAudioContext;
        //       var context = new AudioContext(); // Make it crossbrowser    
                      
        //             const audioBuffer = await fetch(sound1)
        //               .then(res => res.arrayBuffer())
        //               .then(buffer => context.decodeAudioData(buffer));
            
        //            play(audioBuffer);
              
            
        //       function play(audioBuffer) {
        //       console.log('rrrrrrrr')
        //         const source = context.createBufferSource();
        //         source.buffer = audioBuffer;
        //         source.connect(context.destination);
        //         source.start();
        // }
        var play = function play(audioBuffer) {
          var source = context.createBufferSource();
          source.buffer = audioBuffer;
          source.connect(context.destination);
          source.start();
        };
  
        var AudioContext = window.AudioContext || window.webkitAudioContext;
        var context = new AudioContext(); // Make it crossbrowser
        var gainNode = context.createGain();
        gainNode.gain.value = 1; // set volume to 100%
        var yodelBuffer = void 0;
  
        // The Promise-based syntax for BaseAudioContext.decodeAudioData() is not supported in Safari(Webkit).
        window.fetch(sound1)
          .then(response => response.arrayBuffer())
          .then(arrayBuffer => context.decodeAudioData(arrayBuffer,
             audioBuffer => {
                yodelBuffer = audioBuffer;
              },
              error =>
                console.error(error)
            ))
  
      
        // Play the file every 2 seconds. You won't hear it in iOS until the audio context is unlocked.
        window.setTimeout(function(){
          play(yodelBuffer);
        },950);
  
  
      }
        }());
    
        
      
      
      
    }else{
      audio.play().catch((error) => {
      console.log('Audio error:', { error });
    });
    }
  }

  useEffect(() => {
    !orders.firstTime &&
      socket &&
      userData &&
      socket.on('NEW_ORDER', function (data) {
        if (data.storeStatus === 'WAITING_FOR_ACCEPT') {
          dispatch(changeOrderStatusAction(data, data?.storeStatus));
          // dispatch(newOrderAction(data));
          // if (data.dispatchingStatus !== 'INVALID') {
          alert.show('new order', {
            type: 'success',
          });
          audio.play().catch((error) => {
            console.log('Audio error:', { error });
          });
        }
        // }
      });
    !orders.firstTime &&
      socket &&
      userData &&
      socket.on('ORDER_STATUS_CHANGED', function (data) {
        dispatch(changeOrderStatusAction(data, data?.storeStatus));
        if (data.storeStatus === 'WAITING_FOR_ACCEPT') {
          console.log(12);
          alert.show('new order', {
            type: 'success',
          });
         
           playSnds();
          // audio.play().catch((error) => {
          //   console.log('Audio error:', { error });
          // });
        }
      });
    !orders.firstTime &&
      socket &&
      userData &&
      socket.on('ORDER_CANCELLED', function (data) {
        // if (
        //   data.storeStatus == StoreStatus.ACCEPTED ||
        //   data.storeStatus == StoreStatus.WAITING_FOR_ACCEPT
        // ) {
        dispatch(cancelOrder(data._id));
        // }
      });
  }, [socket, orders.firstTime]);
  // const Soundios = ()=>{
  //   if ('AudioContext' in window || 'webkitAudioContext' in window) {
  //     var AudioContext = window.AudioContext || window.webkitAudioContext;
  //     var context = new AudioContext(); // Make it crossbrowser
  //     var gainNode = context.createGain();
  //     gainNode.gain.value = 1;
  //      var buffer = context.createBuffer(1, 1, 22050);
  //       var source = context.createBufferSource();
  //       source.buffer = buffer;
  //       source.connect(context.destination);
  //       // play the file. noteOn is the older version of start()
  //       source.start ? source.start(0) : source.noteOn(0);
  //   }
  // }

   const pathName = ()=>{
    
    if(window.location.pathname =='/'){
      history.push("admin/order/home")

    }
   }
   useEffect(()=>{
    pathName()
   },[])
  return (
    <div style={{ position: 'relative', bottom: '60px' }}>
      {userData && (
        <Grid container>
          <Grid item xs={10}>
            <Card>
            </Card>
          </Grid>
          <Grid item xs={2} justify="flex-end">
            {orders.firstTime && (
              <ModalComponent
                maxWidth={'sm'}
                close={handleCloseModal}
                visible={orders.firstTime}
                title="Kaalix Store"
              >
                Bonjour
              </ModalComponent>
            )}
            
            <div id='showtext'></div>

            <CallEndIcon
              style={{
                fontSize: '330%',
                backgroundColor: 'red',
                borderRadius: '50%',
                padding: '5px',
                color: 'white',
                cursor: 'pointer',
                position:'sticky',
                zIndex:'100'
              }}
              onClick={() => {
                audio.pause();
                audio.currentTime = 0;
              }}
            />

            <CallEndIcon
              id='playSound'
              style={{
                fontSize: '330%',
                backgroundColor: 'green',
                borderRadius: '50%',
                padding: '5px',
                color: 'white',
                cursor: 'pointer',
                display:'none',
                
              }}
            
            />

          </Grid>
        </Grid>

      )}
    
    </div>
  );
}
