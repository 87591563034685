import React from 'react';
import ReactDOM from 'react-dom';
import axios from 'axios';
import App from './App';
import reportWebVitals from './reportWebVitals';

import './assets/css/material-dashboard-pro-react.css';
import { store } from './redux/store';
import { refreshAccessTokenApi } from './features/auth/services/serverApi.service';
import { setUserDataAction } from './features/auth/redux/actions/auth.action';

import './assets/scss/material-dashboard-pro-react.scss';

import './assets/css/global.css';

/*
axios interceptors
 */
axios.interceptors.request.use((config) => {
  const reduxState = store.getState();
  // config.headers['Access-Control-Allow-Origin'] = '*';
  // config.headers['Access-Control-Allow-Methods'] = 'GET, POST, PUT, DELETE';
  // config.headers['Access-Control-Allow-Headers'] = 'Content-Type';
  // config.headers['Access-Control-Allow-Credentials'] = 'true';
  if (reduxState.authReducer?.adminData) {
    if (!config.headers.Authorization) {
      config.headers.Authorization = `Bearer ${reduxState.authReducer.adminData.accessToken}`;
    }
  }

  return config;
});

axios.interceptors.response.use(
  (response) => response,
  async (error) => {
    console.log('error', error);
    if (error?.response?.status === 403) {
      const reduxState = store.getState();

      const { data } = await refreshAccessTokenApi(
        reduxState.authReducer.adminData.refreshToken,
      );

      store.dispatch(setUserDataAction(data));

      error.config.headers.Authorization = `Bearer ${data.accessToken}`;

      return axios(error.config);
    }

    return Promise.reject(error);
  },
);
/*
end axios interceptors code
 */

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById('root'),
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
