import React,{useState,useEffect} from 'react'
import { useAlert } from 'react-alert';
import DataTableContainerComponent from '../../../components/DataTableSortingContainer.component';
import { getCategorys,updateCategory ,getCategoryLists} from '../services/serverAPI.service'
import CreateCategory from '../components/CreateCategory'
import SwitchComponent from '../../../components/Switch.component'
import DeleteCategory from '../components/DeleteCategory'
import EditeCategoryComponent from '../components/EditeCategory'

import GridContainer from '../../../components/material-dashboard-pro-react/Grid/GridContainer';
import Card from '../../../components/material-dashboard-pro-react/Card/Card';
import CardHeader from '../../../components/material-dashboard-pro-react/Card/CardHeader';
import CardBody from '../../../components/material-dashboard-pro-react/Card/CardBody';
import Button from '../../../components/material-dashboard-pro-react/CustomButtons/Button';
import { Grid, Box, Divider } from '@material-ui/core'

export default function Category() {
    const alert = useAlert();
    const id = localStorage.getItem('storeId')
    const [resultsData, setResultsData] = useState({
        total: 0,
        items: [],
        tableItems: [],
    });
    const [isLoading, setIsLoading] = useState(false);
    const [categoryList,setCategoryList] = useState([])
    const [page, setPage] = useState(0)
    let numberOfRows = [10, 50, 100];
    const [rowsPerPage, setRowsPerPage] = useState(numberOfRows[0])
    const handlePaginate = (val) => {
        // console.log(val,'val');
        // console.log(val.pageSelect, val.numberOfRows);
        setResultsData({
          items: [],
          tableItems: [],
        });
        loadCategory(val.pageSelect, val.numberOfRows);
      };
    const loadCategoryList = ()=>{
        getCategoryLists(id)
        .then(({data})=>setCategoryList(data))
        .catch((error)=>console.log(error))
    }
    const loadCategory = (skip,limit) => {
        setIsLoading(true);
        getCategorys(id,skip,limit)
            .then(({ data }) => {
                setResultsData({
                    total: data.total,
                    items: data.products.map((item, index) => {
                        return {
                            ...item,
                            name: item.name||'--',
                            description: item.description ? item.description : '--',
                            inStock:
                                <SwitchComponent
                                    onChange={(value) => updateStocks(value, item)}
                                    value="checked"
                                    checked={item.inStock}
                                    label="InStock"
                                />
                            ,
                            imageUrl: item.imageUrl ? <img src={item.imageUrl} alt="" border="3" height="50" width="60" /> : '--',
                            productCategoryId: categoryList.find(x=>x._id==item.productCategoryId)?.name

                        };
                    }),
                    
                });
            })
            .catch(error => console.log(error))
            .finally(() => {
                setIsLoading(false);
            });
    };
    
    const updateStocks = (value, item) => {
        item.inStock = value,
        updateCategory(item)
            .then(() => {
                alert.show('stock updated', {
                    type: 'success',
                });
                loadCategory()
            })
            .catch((error) => {
                alert.show(error.message, {
                    type: 'error',
                });
            })
    }
    useEffect(() => {
        loadCategoryList()
    },[])
    useEffect(() => {
        loadCategory(page, rowsPerPage)
    },[page, rowsPerPage,categoryList])
    return (
        <GridContainer className='bordGlobalContainer'>
            <Card>
                <CardHeader className='bordCardTableHeader'>
                    <div className='bordTableTitleDivider'>
                        <span className='bordStoreTableTitle'>
                            Catégories
                        </span>
                        <Divider className='lineTitle' />
                    </div>
                </CardHeader>
                <CardBody>
                    <DataTableContainerComponent 
                    total={resultsData.total}
                    numberOfRowsProps={numberOfRows}
                    resultsData={resultsData}
                    initData={resultsData.items}
                    onPaginate={handlePaginate}
                    isPaginate={true}
                    page={page + 1}
                    columns={[
                        {
                            Header: 'Image',
                            accessor: 'imageUrl',
                        },
                        {
                            Header: 'Name',
                            accessor: 'name',
                        },
                        {
                            Header: 'Description',
                            accessor: 'description',
                        },
                        {
                            Header: 'Catégorie',
                            accessor: 'productCategoryId',
                        },
                        {
                            Header: 'InStock',
                            accessor: 'inStock'
                        }
                    ]}
                    isLoading={isLoading}
                    loadItemsList={loadCategory}
                    />
                </CardBody>
            </Card>
        </GridContainer>
        // <DataTableContainerComponent
        //     numberOfRowsProps={numberOfRows}
        //     resultsData={resultsData}
        //     initData={resultsData.items}
        //     onPaginate={handlePaginate}
        //     isPaginate={false}
        //     columns={[
        //         {
        //             Header: 'Name',
        //             accessor: 'name',
        //         },
        //         {
        //             Header: 'Description',
        //             accessor: 'description',
        //         },
        //         {
        //             Header: 'inStock',
        //             accessor: 'inStock',
        //         },
        //         {
        //             Header: 'Image',
        //             accessor: 'imageUrl',
        //         },
        //         {
        //             Header: 'product Category',
        //             accessor: 'productCategoryId',
        //         },
        //         // {
        //         //     Header: 'actions',
        //         //     accessor: 'actions',
        //         // },
        //     ]}
        //     title="Category"
        //     isLoading={isLoading}
        //     loadItemsList={loadCategory}
        //     // CreateItemFormComponent={CreateCategory}
        //     // actionButtons={{
                
        //     //         edit: {
        //     //           color: 'success',
        //     //           type: 'edit',
        //     //           component: EditeCategoryComponent,
        //     //         },
        //     //     delete: {
        //     //         color: 'danger',
        //     //         type: 'delete',
        //     //            component:DeleteCategory
        //     //     },
        //     // }}
        // />
    )
}
