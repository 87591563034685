import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Switch from '@material-ui/core/Switch';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import PropTypes from 'prop-types';
import styles from '../assets/jss/material-dashboard-pro-react/customCheckboxRadioSwitch';

const useStyles = makeStyles(styles);

export default function SwitchComponent({ checked, onChange, ...props }) {
  const classes = useStyles();
  return (
    <FormControlLabel
      control={
        <Switch
          checked={checked}
          onChange={(event) => onChange(event.target.checked)}
          value="checked"
          classes={{
            switchBase: classes.switchBase,
            checked: classes.switchChecked,
            thumb: classes.switchIcon,
            track: classes.switchBar,
          }}
        />
      }
      classes={{
        label: classes.label,
      }}
      label="Toggle is on"
      {...props}
    />
  );
}

SwitchComponent.propTypes = {
  checked: PropTypes.bool.isRequired,
  onChange: PropTypes.func.isRequired,
};
