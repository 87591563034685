import { GET_STORE_REQUEST,GET_STORE_SUCCESS,GET_STORE_FAIL } from '../Constants/store.config.constants'


export const getStoreRequestAction =(storeId)=>({
    type : GET_STORE_REQUEST,
    storeId
})
export const getStoreSuccessAction =(store)=>({
    type : GET_STORE_SUCCESS,
    store
})
export const getStoreFailAction =(error)=>({
    type : GET_STORE_FAIL,
    error
})