import React, { useEffect, useState } from 'react';
import Assignment from '@material-ui/icons/Assignment';
import { makeStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import CircularProgress from '@material-ui/core/CircularProgress';
import { Box } from '@material-ui/core';
import Person from '@material-ui/icons/Person';
import Edit from '@material-ui/icons/Edit';
import Close from '@material-ui/icons/Close';
import Map from '@material-ui/icons/Map';
import Update from '@material-ui/icons/Update';
import FileCopyIcon from '@material-ui/icons/FileCopy';
import {Autocomplete} from '@material-ui/lab'
import { frontEndUrl } from '../config/FrontEndUrl';
import GridContainer from './material-dashboard-pro-react/Grid/GridContainer';
import GridItem from './material-dashboard-pro-react/Grid/GridItem';
import Card from './material-dashboard-pro-react/Card/Card';
import CardHeader from './material-dashboard-pro-react/Card/CardHeader';
import CardIcon from './material-dashboard-pro-react/Card/CardIcon';
import CardBody from './material-dashboard-pro-react/Card/CardBody';
import Table from './material-dashboard-pro-react/Table/Table';
import ReactTable from './material-dashboard-pro-react/ReactTable/ReactTable';
import Button from './material-dashboard-pro-react/CustomButtons/Button';
import ModalComponent from './Modal.component';
import tableDataStyles from '../assets/jss/material-dashboard-pro-react/views/extendedTablesStyle';
import dataTableContainerStyles from '../assets/jss/table-data-container';
import Dvr from '@material-ui/icons/Dvr';
import Favorite from '@material-ui/icons/Favorite';
import { DataGrid } from '@material-ui/data-grid';
import styles from '../assets/jss/material-dashboard-pro-react/customSelectStyle';
const useTableDataStyles = makeStyles(tableDataStyles);
const useDataTableContainerStyles = makeStyles(dataTableContainerStyles);
export default function DataTableContainerComponent({
  resultsData,
  initData,
  columns,
  onPaginate,
  isPaginate,
  total,
  title,
  isLoading,
  loadItemsList,
  actionButtons,
  CreateItemFormComponent,
  customColumnStyle,
  searchInp,
  SearchComponent,
  props
}) {
  const classes = useTableDataStyles();
  const tableDataClasses = useDataTableContainerStyles();
  const [modal, setModal] = useState({
    visible: false,
    type: 'view',
  });
  const [selectedItemIndex, setSelectedItemIndex] = useState(null);

  const EditItemFormComponent = actionButtons?.edit?.component;
  const MiniEditItemFormComponent = actionButtons?.miniEdit?.component;
  const ViewItemComponent = actionButtons?.view?.component;
  const MapViewComponent = actionButtons?.map?.component;
  const LinkComponent = actionButtons?.link?.component;
  const DeleteComponent = actionButtons?.delete?.component;
  const DuplicateStoreComponent = actionButtons?.duplicate?.component;
  const defaultCustomColumnStyle = {
    minWidth:'180px',
    maxWidth: '180px',
    textAlign:'center'
  }

  const closeModal = () => {
    setSelectedItemIndex(null);
    setModal({ visible: false, type: '' });
  };

  const [data, setData] = useState([]);
  useEffect(() => {
    setData(
      initData.map((prop, index) => {
        return {
          ...prop,
          actions: (
            // we've added some custom button actions
            <div className="actions-right">
              {actionButtons
                ? Object.values(actionButtons).map((prop) => (
                    <Button
                      color={prop.color}
                      simple
                      className={classes.actionButton}
                      key={prop.type}
                      onClick={() => {
                        setSelectedItemIndex(index);
                        setModal({ type: prop.type, visible: true });
                      }}
                    >
                      {prop.type === 'view' && (
                        <Person className={classes.icon} />
                      )}
                      {prop.type === 'edit' && (
                        <Edit className={classes.icon} />
                      )}
                      {prop.type === 'delete' && (
                        <Close className={classes.icon} />
                      )}
                      {prop.type === 'duplicate' && (
                        <FileCopyIcon className={classes.icon} />
                      )}
                      {prop.type === 'map' && <Map className={classes.icon} />}

                      {prop.type === 'link' && (
                        <LinkComponent
                          href={prop.href}
                          id={resultsData.items[index]._id}
                          icon={prop.icon}
                          key={prop.href}
                          setToLocal={prop.setToLocal}
                        />
                      )}
                      {prop.type === 'miniEdit' && (
                        <Update className={classes.icon} />
                      )}
                    </Button>
                  ))
                : ''}
            </div>
          ),
        };
      }),
    );
  }, [resultsData, isLoading]);

  return (
    <GridContainer>
      <GridItem xs={12}>
        {CreateItemFormComponent && (
          <Box display="flex" justifyContent="flex-end">
            <Button
              onClick={() => setModal({ visible: true, type: 'create' })}
              color="success"
            >
              Create
            </Button>
          </Box>
        )}
        <Card>
          <CardHeader color="rose" icon>
            {/* <CardIcon color="rose">
              <Assignment />
            </CardIcon> */}
            <h4 className={classes.cardIconTitle}>{title}</h4>
          </CardHeader>
          <CardBody>
          {searchInp&&
            <SearchComponent/>
          // <Autocomplete
          // id="combo-box-demo"
          // options={stores}
          // defaultValue={[stores[3]]}
          // getOptionLabel={option => option.name}
          // style={{ width: 300 }}
          // onChange={(event, newValue) => {
          //     newValue&&setFieldValue(`publicitySubGroups.${index}.pubs.${i}.storeId`,newValue._id)
          //   }}
          // renderInput={(stores) => <TextField {...stores} label="Combo box" variant="outlined" onChange={(e)=>setText(e.target.value)}/>}
          // />
          }
            {isLoading && (
              <Box display="flex" justifyContent="center">
                <CircularProgress size={30} />
              </Box>
            )}
            <ReactTable
              columns={columns}
              data={isLoading ? [] : data}
              total={total || 100}
              onPaginate={(val) => (onPaginate ? onPaginate(val) : '')}
              isPaginate={isPaginate}
              customColumnStyle={customColumnStyle ? customColumnStyle : defaultCustomColumnStyle}
            />
          </CardBody>
        </Card>
      </GridItem>
      <ModalComponent
        close={() => setModal({ visible: false, type: '' })}
        visible={modal.visible && modal.type === 'create'}
        title="Create"
      >
        {!!CreateItemFormComponent && (
          <CreateItemFormComponent
            onItemCreated={loadItemsList}
            close={closeModal}
            props={props||null}
          />
        )}
      </ModalComponent>
      {Boolean(EditItemFormComponent) && (
        <ModalComponent
          close={closeModal}
          visible={modal.visible && modal.type === 'edit'}
          title="Edit"
        >
          <EditItemFormComponent
            data={resultsData.items[selectedItemIndex]}
            onItemEdited={loadItemsList}
            props={actionButtons?.edit?.props || null}
            close={closeModal}
          />
        </ModalComponent>
      )}
      {Boolean(MiniEditItemFormComponent) && (
        <ModalComponent
          close={closeModal}
          visible={modal.visible && modal.type === 'miniEdit'}
          title="Edit Promo"
        >
          <MiniEditItemFormComponent
            data={resultsData.items[selectedItemIndex]}
            onItemEdited={loadItemsList}
            close={closeModal}
          />
        </ModalComponent>
      )}
      {Boolean(ViewItemComponent) && (
        <ModalComponent
          close={closeModal}
          visible={modal.visible && modal.type === 'view'}
          title="Detail"
        >
          <ViewItemComponent item={resultsData.items[selectedItemIndex]} />
        </ModalComponent>
      )}
      {!!MapViewComponent && (
        <ModalComponent
          close={closeModal}
          visible={modal.visible && modal.type === 'map'}
          title="Map"
          classes={{
            paper: tableDataClasses.paper,
          }}
          maxWidth={actionButtons.map.width}
        >
          {selectedItemIndex !== null && (
            <MapViewComponent item={resultsData.items[selectedItemIndex]} />
          )}
        </ModalComponent>
      )}
      {!!actionButtons && actionButtons.duplicate && DuplicateStoreComponent && (
        <ModalComponent
          close={closeModal}
          visible={modal.visible && modal.type === 'duplicate'}
          title="duplicate"
          classes={{
            paper: tableDataClasses.paper,
          }}
          maxWidth={actionButtons.delete.width}
        >
          {/* {selectedItemIndex !== null && (
            <DeleteComponent
              // item={resultsData.items[selectedItemIndex]}
              data={resultsData.items[selectedItemIndex]}
              onItemEdited={loadItemsList}
              close={closeModal}
            />
          )} */}
          <DuplicateStoreComponent
            data={resultsData.items[selectedItemIndex]}
            onItemEdited={loadItemsList}
            props={actionButtons?.duplicate?.props || null}
            close={closeModal}
          />
          {/* <div>
            JJJJJ {selectedItemIndex}{' '}
            {JSON.stringify(resultsData.items[selectedItemIndex])}
          </div> */}
        </ModalComponent>
      )}
      {!!actionButtons &&
        actionButtons.delete &&
        (DeleteComponent ? (
          <ModalComponent
            close={closeModal}
            visible={modal.visible && modal.type === 'delete'}
            title="Delete"
            classes={{
              paper: tableDataClasses.paper,
            }}
            maxWidth={actionButtons.delete.width}
          >
            {selectedItemIndex !== null && (
              <DeleteComponent
                // item={resultsData.items[selectedItemIndex]}
                data={resultsData.items[selectedItemIndex]}
                onItemEdited={loadItemsList}
                close={closeModal}
              />
            )}
          </ModalComponent>
        ) : (
          <ModalComponent
            close={closeModal}
            visible={modal.visible && modal.type === 'delete'}
            title="Delete"
            actions={
              <>
                <Button onClick={closeModal}>Cancel</Button>
                <Button onClick={closeModal} color="success">
                  Yes
                </Button>
              </>
            }
          >
            <div>Are you sure do delete this item ?</div>
          </ModalComponent>
        ))}
    </GridContainer>
  );
}

DataTableContainerComponent.propTypes = {
  resultsData: PropTypes.any.isRequired,
  initData: PropTypes.any,
  columns: PropTypes.any,
  onPaginate: PropTypes.func,
  title: PropTypes.string.isRequired,
  isLoading: PropTypes.bool.isRequired,
  isUpdated: PropTypes.bool,
  isPaginate: PropTypes.bool,
  loadItemsList: PropTypes.any.isRequired,
  actionButtons: PropTypes.object,
  CreateItemFormComponent: PropTypes.any,
  numberOfRowsProps: PropTypes.any,
};
