import React from 'react';
import { Provider } from 'react-redux';
import { BrowserRouter, Switch,HashRouter } from 'react-router-dom';
import { Provider as AlertProvider } from 'react-alert';
import AlertTemplate from 'react-alert-template-basic';
import { PersistGate } from 'redux-persist/integration/react';
import { LoadScript } from '@react-google-maps/api';
import LoginFormComponent from './features/auth/components/LoginForm.component';
import RegisterFormComponent from './features/auth/components/RegisterForm.component';
import { persistor, store } from './redux/store';
import AuthRoute from './wrappers/AuthRoute';
import Admin from './layouts/Admin';
import AllOrders from './features/commades/pages/AllOrders.page'
import BoardPage from './features/commades/pages/board.page';
import { alertConfig } from './config/alert.config';
import 'bootstrap/dist/css/bootstrap.min.css';
import './assets/css/module.css';
import { StylesProvider } from '@material-ui/core/styles';

function App() {
  return (
    <StylesProvider injectFirst>
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <AlertProvider template={AlertTemplate} {...alertConfig}>
          <BrowserRouter>
              <Switch>
                <AuthRoute type="guest" path="/login">
                  <LoginFormComponent />
                </AuthRoute>
                {/* <AuthRoute type="guest" path="/register">
                  <RegisterFormComponent />
                </AuthRoute> */}
                <AuthRoute type="private" path="/">
                  <Admin />
                </AuthRoute>
                <AuthRoute type="private" path="/dashboard-store">
                  <Admin />
                </AuthRoute>
                <AuthRoute type="private" path="/setting">
                  <Admin />
                </AuthRoute>
              </Switch>
              <AllOrders />
          </BrowserRouter>
        </AlertProvider>
      </PersistGate>
    </Provider>
    </StylesProvider>
  );
}

export default App;
