import React, { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux';
import * as yup from 'yup';
import { useFormik } from 'formik';
import { Box } from '@material-ui/core';
import PropTypes from 'prop-types';
import { useAlert } from 'react-alert';
import TagsInput from 'react-tagsinput';
import Button from '../../../components/material-dashboard-pro-react/CustomButtons/Button';
import CustomInput from '../../../components/material-dashboard-pro-react/CustomInput/CustomInput';
import {
    getSpecification,
    getCategory,
    createItem,
} from '../services/serverAPI.service';
import SelectBoxComponent from '../../../components/SelectBox.component';
import ImageUpload from '../../../components/material-dashboard-pro-react/CustomUpload/ImageUpload';
import CustomRadioGroup from '../../../components/material-dashboard-pro-react/CustomRadioGroup/CustomRadioGroup';
// import { serviceTypes } from '../mocks';
import { uploadImageResetAction } from '../../../redux/uploadRedux/upload.action'
import SwitchComponent from '../../../components/Switch.component'


const validationSchema = yup.object({
    name: yup.string().required(),
    storeId: yup.string().required(),
    productId: yup.string().required(),
    description: yup.string().required(),
    price: yup.number().typeError('value is number').required(),
    imageUrl: yup.string().nullable(),
    inStock: yup.bool().required(),
    specifications: yup.array().of(yup.string()).required(),
    isMainPromo: yup.bool().required(),
    promoSetting: yup.object().shape({
        type: yup.string().required(),
        // value: yup.number().required(),
        value: yup.number().when('type',{
        is:value=>value=='reduction',
        then:yup.number().typeError('value is number').required('value is required when choise reduction'),
        // otherwise: yup.number().typeError('value is number')
        otherwise: ''
        }),
        discountType: yup.string().when('type',{
        is:value=>value=='reduction',
        then:yup.string().required(),
        // otherwise: yup.number().typeError('value is number')
        otherwise: yup.string().nullable()
        }),
        // discountType: yup.string().required(),
        // minCartPrice: yup.number().when('type',{
        // is:value=>value=='offered product'||value=='1 buy = 1 free',
        // then:yup.number().typeError('minCartPrice is number').required(),
        // // otherwise: yup.number().typeError('value is number')
        // otherwise: ''
        // }),
        minCartPrice: yup.number(),
        enabled: yup.bool().required(),
    }),
});

export default function CreateItem({ onItemCreated, close }) {
    const alert = useAlert();
    const dispatch = useDispatch();
    const storeId = localStorage.getItem('storeId')
    const upload = useSelector((state) => state.uploadReducer)
    const { iconUrl, error, loading } = upload
    // console.log(iconUrl, error, loading);
    const [isLoading, setIsLoading] = useState(false);
    const radioGroupData = [
        {name:"Discount"},{name:"OfferedAboveMinCartPrice"},{name:"OneBoughtOneOffered"}
    ]
    const secondRadioGroupData = [
        {name:"Percentage"},{name:"Absolute"}
    ]
    const [promoValueIcon,setPromoValueIcon] = useState('%');
    const formik = useFormik({
        initialValues: {
            name:'',
            storeId:storeId,
            productId:'',
            description:'',
            price:'',
            imageUrl:'',
            inStock: true,
            specifications: [],
            isMainPromo:false,
            promoSetting: {
                type: null,
                // type: radioGroupData[0].name,
                value: 0,
                discountType: "Percentage",
                minCartPrice: 0,
                enabled: false,
            },

           
            
        },
        validationSchema,
    });
    // console.log(formik.errors);
    const [specification, setSpecification] = useState([]);
    const [products, setProducts] = useState([]);

    const onCreate = () => {
        // setIsLoading(true);
        console.log(formik.values);
        createItem(formik.values)
            .then(({data}) => {
                console.log(data);
                alert.show('Service has been created', {
                    type: 'success',
                });
                formik.resetForm();
                close()
                onItemCreated();
                dispatch(uploadImageResetAction())
            })
            .catch((error) => {
                alert.show(error.message, {
                    type: 'error',
                });
            })
            .finally(() => {
                setIsLoading(false);
            });
    };

    const onChangeTags = (items) => {
        items[items.length - 1]

        console.log(items);
        formik.setFieldValue('tags', items);
        formik.setFieldValue('iconUrl', iconUrl);
    };



    // const onRemoveImage = () => {
    //   formik.setFieldValue('iconUrl', '');
    // };

    useEffect(() => {
        iconUrl && formik.setFieldValue('imageUrl', iconUrl)
        getSpecification(storeId)
            .then(({ data }) => {
                setSpecification(data);
            })
            .catch((error) => {
                alert.show(error.message, {
                    type: 'error',
                });
            });

        getCategory(storeId)
            // eslint-disable-next-line no-shadow
            .then(({ data }) => {
                setProducts(data);
            })
            .catch((error) => {
                alert.show(error.message, {
                    type: 'error',
                });
            });
            
    }, [iconUrl,formik.values?.promoSetting]);
    
    async function handleClick(event) {
        if(event.target.value!="Discount"){
            // const errorObj=formik?.error?.promoSetting
            // delete errorObj?.value
            formik.setFieldValue('promoSetting.discountType','Percentage')
            formik.setFieldValue('promoSetting.value',0)
            // console.log(errorObj?.value);
            // delete errorObj?.value
        }
        if(event.target.value=="Discount"){
            formik.setFieldValue('promoSetting.value',0)
            formik.setFieldValue('promoSetting.discountType',secondRadioGroupData[0].name)
            
        }
        if(event.target.value=="OfferedAboveMinCartPrice"||event.target.value=="OneBoughtOneOffered"){
            // formik.setFieldValue('promoSetting.minCartPrice','')
            // formik.setFieldValue('promoSetting.discountType',secondRadioGroupData[0].name)
        }
        
        // if (event.target.value === value) {
        //   setValue("");
        // } else {
        //   setValue(event.target.value);
        // }
      }
      const handleClickDiscountType=(event)=>{

          if(event.target.value=="Percentage"){
              setPromoValueIcon('%')
        }
          if(event.target.value=="Absolute"){
              setPromoValueIcon('dh')
        }
      }
      const changeInp = (e)=>{
        formik.handleChange('promoSetting.value')
      }
    
    return (
        <div>
            
            <Box display="flex" justifyContent="center">
                <ImageUpload
                    label="Select icon"
                    folder="service"
                    avatar
                />
            </Box>
            <CustomInput
                labelText="Name"
                formControlProps={{
                    fullWidth: true,
                }}
                type="text"
                error={formik.touched.name && Boolean(formik.errors.name)}
                helperText={formik.errors.name&&formik.errors.name}
                inputProps={{
                    value: formik.values.name,
                    onBlur: formik.handleBlur('name'),
                    onChange: formik.handleChange('name'),
                }}
            />
            <CustomInput
                labelText="Description"
                formControlProps={{
                    fullWidth: true,
                }}
                type="text"
                error={formik.touched.description && Boolean(formik.errors.description)}
                helperText={formik.errors.description&&formik.errors.description}
                inputProps={{
                    value: formik.values.description,
                    onBlur: formik.handleBlur('description'),
                    onChange: formik.handleChange('description'),
                }}
            />

            <CustomInput
                labelText="price"
                formControlProps={{
                    fullWidth: true,
                }}
                type="text"
                error={formik.touched.price && Boolean(formik.errors.price)}
                inputProps={{
                    value: formik.values.price,
                    onBlur: formik.handleBlur('price'),
                    onChange: formik.handleChange('price'),
                }}
            />
            <SelectBoxComponent
                items={specification.map((item) => ({
                    value: item._id,
                    label: item.name,
                }))}
                inputLabel="specification list"
                onValueChange={(items) => {
                    formik.setFieldValue('specifications', items);
                }}
                value={formik.values.specifications}
                multiple
            />
            {/* <SelectBoxComponent
        items={serviceTypes.map((item) => ({
          value: item.value,
          label: item.label,
        }))}
        inputLabel="Type"
        onValueChange={formik.handleChange('type')}
        value={formik.values.type}
      /> */}
            <SelectBoxComponent
                items={products.map((product) => ({
                    value: product._id,
                    label: product.name,
                }))}
                inputLabel="Category"
                onValueChange={formik.handleChange('productId')}
                value={formik.values.productId}
            />
            

            <SwitchComponent
                onChange={(value) => formik.setFieldValue('inStock', value)}
                checked={formik.values.inStock}
                error={formik.touched.inStock && Boolean(formik.errors.inStock)}
                label="inStock"
            />
            {/* <SwitchComponent
                onChange={(value) => formik.setFieldValue('promoSetting.enbled', value)}
                checked={formik.values.promoSetting.enabled}
                error={formik.touched.inStock && Boolean(formik.errors.promoSetting.enabled)}
                label="enbled"
            /> */}
             <CustomRadioGroup data={radioGroupData}
            error={formik.touched.type && Boolean(formik.errors.promoSetting.type)}
            inputProps={{
                value: formik.values.promoSetting.type,
                onBlur: formik.handleBlur('promoSetting.type'),
                onChange: formik.handleChange('promoSetting.type'),
            }}
            handleClick={handleClick}
            />
            {/* <div style={{display: 'flex',flexDirection:'row'}}> */}
            <div style={{border: '1px solid #000',padding: '10px',borderRadius:'10px'}}>
            <CustomRadioGroup data={secondRadioGroupData}
            error={formik.touched.name && Boolean(formik?.errors?.promoSetting?.discountType)}
            inputProps={{
                value: formik.values.promoSetting.discountType,
                onBlur: formik.handleBlur('promoSetting.discountType'),
                onChange: formik.handleChange('promoSetting.discountType'),
            }}
            handleClick={handleClickDiscountType}
            disabled={formik.values.promoSetting.type!='Discount'}
            />
            <CustomInput
                labelText="value"
                formControlProps={{
                    fullWidth: false,
                }}
                type="text"
                error={formik.touched?.promoSetting?.value && Boolean(formik?.errors?.promoSetting?.value)}
                inputProps={{
                    value: formik.values.promoSetting.value,
                    onBlur: formik.handleBlur('promoSetting.value'),
                    onChange: formik.handleChange('promoSetting.value'),
                }}
                helperText={formik.errors?.promoSetting?.value&&formik.errors?.promoSetting?.value}
                disabled={formik.values.promoSetting.type!='Discount'}
                type={"number"}
                // onChange={(e)=>console.log('e.target.value')}
            />
            {promoValueIcon}
            </div>
            <CustomInput
                labelText="amount"
                formControlProps={{
                    fullWidth: false,
                }}
                type="text"
                error={formik.touched.name && Boolean(formik?.errors?.promoSetting?.minCartPrice)}
                inputProps={{
                    value: formik.values.promoSetting.minCartPrice,
                    onBlur: formik.handleBlur('promoSetting.minCartPrice'),
                    onChange: formik.handleChange('promoSetting.minCartPrice'),
                }}
                disabled={formik.values.promoSetting.type!="OfferedAboveMinCartPrice"&&formik.values.promoSetting.type!="OneBoughtOneOffered"}
                // onChange={(e)=>console.log('e.target.value')}
            />dh
    <Box textAlign="left">
            <SwitchComponent
                onChange={(value) => formik.setFieldValue('promoSetting.enabled', value)}
                checked={formik.values.promoSetting.enabled}
                error={formik.touched?.promoSetting?.enabled && Boolean(formik.errors?.promoSetting?.enabled)}
                label="enabled"
            />
            <SwitchComponent
                onChange={(value) => formik.setFieldValue('isMainPromo', value)}
                checked={formik.values.isMainPromo}
                error={formik.touched.isMainPromo && Boolean(formik.errors.isMainPromo)}
                label="is Main Promo"
            />
            </Box>
            <Box textAlign="center">
                <Button
                    round
                    type="submit"
                    color="success"
                    disabled={!formik.dirty || !formik.isValid || isLoading}
                    loading={isLoading}
                    loaderColor="white"
                    onClick={onCreate}
                >
                    Create
                </Button>
            </Box>
        </div>
    )
}

CreateItem.propTypes = {
    onItemCreated: PropTypes.func.isRequired,
    close: PropTypes.func.isRequired,
};
