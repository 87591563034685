/*eslint-disable*/
import React from "react";
import PropTypes from "prop-types";
// javascript plugin used to create scrollbars on windows
import PerfectScrollbar from "perfect-scrollbar";
import { NavLink, useLocation } from "react-router-dom";
import cx from "classnames";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import Drawer from "@material-ui/core/Drawer";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import Hidden from "@material-ui/core/Hidden";
import Collapse from "@material-ui/core/Collapse";
import Icon from "@material-ui/core/Icon";
import LogoutIcon from '@mui/icons-material/Logout';
import { Close } from '@material-ui/icons';

// core components
import AdminNavbarLinks from "components/material-dashboard-pro-react/Navbars/AdminNavbarLinks.js";

import sidebarStyle from "assets/jss/material-dashboard-pro-react/components/sidebarStyle.js";

import avatar from "assets/img/faces/avatar.jpg";
import { ListItemButton } from "@mui/material";
import { ListItemIcon } from "@material-ui/core";

const useStyles = makeStyles(sidebarStyle);
// const useStyles = makeStyles({
//   paper: {
//     backgroundColor: 'red'
//   }
// })

var ps;

// We've created this component so we can have a ref to the wrapper of the links that appears in our sidebar.
// This was necessary so that we could initialize PerfectScrollbar on the links.
// There might be something with the Hidden component from material-ui, and we didn't have access to
// the links, and couldn't initialize the plugin.
function SidebarWrapper({ className, user, headerLinks, links, storeLinks }) {
  const sidebarWrapper = React.useRef();
  React.useEffect(() => {
    if (navigator.platform.indexOf("Win") > -1) {
      ps = new PerfectScrollbar(sidebarWrapper.current, {
        suppressScrollX: true,
        suppressScrollY: false,
      });
    }
    return function cleanup() {
      if (navigator.platform.indexOf("Win") > -1) {
        ps.destroy();
      }
    };
  });
  return (
    <div 
    className={className}
    ref={sidebarWrapper}
    >
      {/* {user} */}
      {headerLinks}
      {/* {links} */}
      {storeLinks}
    </div>
  );
}

function RightSidebar(props) {
    // console.log(props);
    const classes = useStyles();
    const [miniActive, setMiniActive] = React.useState(true);
    // to check for active links and opened collapses
    let location = useLocation();
    // this is for the user collapse
    const [openAvatar, setOpenAvatar] = React.useState(false);
    // this is for the rest of the collapses
    const [state, setState] = React.useState({});
    const [storeState, setStoreState] = React.useState({});
    React.useEffect(() => {
      console.log('Effect',props);
    //   setState(getCollapseStates(props.routes));
      setStoreState(getCollapseStoreStates(props.storeRoutes));
    }, []);
    const mainPanel = React.useRef();
  
    const getCollapseStoreStates = (storeRoutes) => {
      let initialState = {};
      storeRoutes.map((prop) => {
        // console.log(prop);
        if (prop.collapse) {
          // console.log(prop.collapse);
          initialState = {
            [prop.state]: getCollapseStoreInitialState(prop.views),
            ...getCollapseStoreStates(prop.views),
            ...initialState,
          };
        }
        return null;
      });
      initialState.seconddeliveryInfoCollapse=true
      return initialState;
    }
  
    const getCollapseStoreInitialState = (storeRoutes) => {
      // console.log(storeRoutes);
      for (let i = 0; i < storeRoutes.length; i++) {
        if (storeRoutes[i].collapse && getCollapseStoreInitialState(storeRoutes[i].views)) {
          return true;
        } else if (location.pathname === storeRoutes[i].layout + storeRoutes[i].path) {
          return true;
        }
      }
      return false;
    };
    // verifies if routeName is the one active (in browser input)
    const activeRoute = (routeName) => {
      return location.pathname === routeName ? "active" : "";
    };
    // create store links :
    const createStoreLinks = (storeRoutes) => {
      // console.log(storeRoutes);
      const { color, rtlActive } = props;
      return storeRoutes.map((prop, key) => {
        // console.log(prop);
        // console.log(state);
        if (prop.redirect) {
          return null;
        }
        if (prop.collapse) {
          var st = {};
          st[prop["state"]] = !storeState[prop.state];
          // console.log(st);
          const navLinkClasses =
            classes.itemLink +
            " " +
            cx({
              [" " + classes.collapseActive]: getCollapseStoreInitialState(prop.views),
            });
          const itemText =
            classes.itemText +
            " " +
            cx({
              [classes.itemTextMini]: props.miniActive && miniActive,
              [classes.itemTextMiniRTL]:
                rtlActive && props.miniActive && miniActive,
              [classes.itemTextRTL]: rtlActive,
            });
          const collapseItemText =
            classes.collapseItemText +
            " " +
            cx({
              [classes.collapseItemTextMini]: props.miniActive && miniActive,
              [classes.collapseItemTextMiniRTL]:
                rtlActive && props.miniActive && miniActive,
              [classes.collapseItemTextRTL]: rtlActive,
            });
          const itemIcon =
            classes.itemIcon +
            " " +
            cx({
              [classes.itemIconRTL]: rtlActive,
            });
          const caret =
            classes.caret +
            " " +
            cx({
              [classes.caretRTL]: rtlActive,
            });
          const collapseItemMini =
            classes.collapseItemMini +
            " " +
            cx({
              [classes.collapseItemMiniRTL]: rtlActive,
            });
          return (
            <ListItem
              key={key}
              className={cx(
                { [classes.item]: prop.icon !== undefined },
                { [classes.collapseItem]: prop.icon === undefined }
              )}
            >
             { prop.displayParent &&
             <NavLink
                to={"#"}
                className={navLinkClasses}
                onClick={(e) => {
                  e.preventDefault();
                  // console.log(st);
                  st.seconddeliveryInfoCollapse=true
                  // st.logoutCollapse=true
                  setStoreState(st);
                }}
              >
                {prop.icon !== undefined ? (
                  typeof prop.icon === "string" ? (
                    <Icon className={itemIcon}>{prop.icon}</Icon>
                  ) : (
                    <prop.icon className={itemIcon} />
                  )
                ) : (
                  <span className={collapseItemMini}>
                    {rtlActive ? prop.rtlMini : prop.mini}
                  </span>
                )}
                <ListItemText
                  primary={rtlActive ? prop.rtlName : prop.name}
                  secondary={
                    <b
                      className={
                        caret +
                        " " +
                        (storeState[prop.state] ? classes.caretActive : "")
                      }
                    />
                  }
                  disableTypography={true}
                  className={cx(
                    { [itemText]: prop.icon !== undefined },
                    { [collapseItemText]: prop.icon === undefined }
                  )}
                />
              </NavLink>}
              <Collapse in={storeState[prop.state]} unmountOnExit>
                {/* handle close button here : */}
                <div 
                onClick={props.handleStoreDrawerToggle}
                style={{
                    cursor: 'pointer',
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                    justifyContent: 'start',
                    margin: '10% 6%'
                }}>
                    <Close />
                    <span 
                    style={{
                        fontSize: '24px',
                        marginLeft: '6px'
                    }}
                    >Fermer</span>
                </div>
                <List className={classes.list + " " + classes.collapseList}>
                  {createStoreLinks(prop.views)}
                </List>
                {/* <List
                className={classes.listLogOut + " " + classes.collapseList} 
                // className="logOut"
                >
                  <ListItem disablePadding>
                    <ListItem>
                      <NavLink
                      className={navLinkClasses}
                      to={"#"}
                      onClick={() => {
                        console.log("logOut");
                      }}
                      >
                        <Icon className={itemIcon}>
                          <LogoutIcon />
                        </Icon>
                        <ListItemText
                        className={cx(
                          { [itemText]: prop.icon !== undefined },
                          { [collapseItemText]: prop.icon === undefined }
                        )}
                        >
                          Logout
                        </ListItemText>
                      </NavLink>
                    </ListItem>
                  </ListItem>
                </List> */}
              </Collapse>
            </ListItem>
          );
        }
        const innerNavLinkClasses =
          classes.collapseItemLink +
          " " +
          cx({
            [" " + classes[color]]: activeRoute(prop.layout + prop.path),
          });
        const collapseItemMini =
          classes.collapseItemMini +
          " " +
          cx({
            [classes.collapseItemMiniRTL]: rtlActive,
          });
        const navLinkClasses =
          classes.itemLink +
          " " +
          cx({
            [" " + classes[color]]: activeRoute(prop.layout + prop.path),
          });
        const itemText =
          classes.itemText +
          " " +
          cx({
            [classes.itemTextMini]: props.miniActive && miniActive,
            [classes.itemTextMiniRTL]:
              rtlActive && props.miniActive && miniActive,
            [classes.itemTextRTL]: rtlActive,
          });
        const collapseItemText =
          classes.collapseItemText +
          " " +
          cx({
            [classes.collapseItemTextMini]: props.miniActive && miniActive,
            [classes.collapseItemTextMiniRTL]:
              rtlActive && props.miniActive && miniActive,
            [classes.collapseItemTextRTL]: rtlActive,
          });
        const itemIcon =
          classes.itemIcon +
          " " +
          cx({
            [classes.itemIconRTL]: rtlActive,
          });
        return (
          <ListItem
            key={key}
            className={cx(
              { [classes.item]: prop.icon !== undefined },
              { [classes.collapseItem]: prop.icon === undefined }
            )}
          >
            <NavLink
                style={{
                    padding: '10px 16%'
                }}
              to={prop.layout + prop.path}
              className={cx(
                { [navLinkClasses]: prop.icon !== undefined },
                { [innerNavLinkClasses]: prop.icon === undefined }
              )}
            >
              {prop.icon !== undefined ? (
                typeof prop.icon === "string" ? (
                  <Icon className={itemIcon}>{prop.icon}</Icon>
                ) : (
                  <prop.icon className={itemIcon} />
                )
              ) : (
                <span className={collapseItemMini}>
                  {rtlActive ? prop.rtlMini : prop.mini}
                </span>
              )}
              <ListItemText
                primary={rtlActive ? prop.rtlName : prop.name}
                disableTypography={true}
                className={cx(
                  { [itemText]: prop.icon !== undefined },
                  { [collapseItemText]: prop.icon === undefined }
                )}
              />
            </NavLink>
          </ListItem>
        );
      });
    };
    // this function creates the links and collapses that appear in the sidebar (left menu)
   
    const { logo, image, logoText, routes, bgColor, rtlActive, storeRoutes } = props;
    const itemText =
      classes.itemText +
      " " +
      cx({
        [classes.itemTextMini]: props.miniActive && miniActive,
        [classes.itemTextMiniRTL]: rtlActive && props.miniActive && miniActive,
        [classes.itemTextRTL]: rtlActive,
      });
    const collapseItemText =
      classes.collapseItemText +
      " " +
      cx({
        [classes.collapseItemTextMini]: props.miniActive && miniActive,
        [classes.collapseItemTextMiniRTL]:
          rtlActive && props.miniActive && miniActive,
        [classes.collapseItemTextRTL]: rtlActive,
      });
    const userWrapperClass =
      classes.user +
      " " +
      cx({
        [classes.whiteAfter]: bgColor === "white",
      });
    const caret =
      classes.caret +
      " " +
      cx({
        [classes.caretRTL]: rtlActive,
      });
    const collapseItemMini =
      classes.collapseItemMini +
      " " +
      cx({
        [classes.collapseItemMiniRTL]: rtlActive,
      });
    const photo =
      classes.photo +
      " " +
      cx({
        [classes.photoRTL]: rtlActive,
      });
    var user = (
      <div >
        <div className={photo}>
          <img src={avatar} className={classes.avatarImg} alt="..." />
        </div>
        <List className={classes.list}>
          <ListItem className={classes.item + " " + classes.userItem}>
            <NavLink
              to={"#"}
              className={classes.itemLink + " " + classes.userCollapseButton}
              onClick={() => setOpenAvatar(!openAvatar)}
            >
              <ListItemText
                primary={rtlActive ? "تانيا أندرو" : "Tania Andrew"}
                secondary={
                  <b
                    className={
                      caret +
                      " " +
                      classes.userCaret +
                      " " +
                      (openAvatar ? classes.caretActive : "")
                    }
                  />
                }
                disableTypography={true}
                className={itemText + " " + classes.userItemText}
              />
            </NavLink>
            <Collapse in={openAvatar} unmountOnExit>
              <List className={classes.list + " " + classes.collapseList}>
                <ListItem className={classes.collapseItem}>
                  <NavLink
                    to="#"
                    className={classes.itemLink + " " + classes.userCollapseLinks}
                  >
                    <span className={collapseItemMini}>
                      {rtlActive ? "مع" : "MP"}
                    </span>
                    <ListItemText
                      primary={rtlActive ? "ملفي" : "My Profile"}
                      disableTypography={true}
                      className={collapseItemText}
                    />
                  </NavLink>
                </ListItem>
                <ListItem className={classes.collapseItem}>
                  <NavLink
                    to="#"
                    className={classes.itemLink + " " + classes.userCollapseLinks}
                  >
                    <span className={collapseItemMini}>
                      {rtlActive ? "هوع" : "EP"}
                    </span>
                    <ListItemText
                      primary={rtlActive ? "تعديل الملف الشخصي" : "Edit Profile"}
                      disableTypography={true}
                      className={collapseItemText}
                    />
                  </NavLink>
                </ListItem>
                <ListItem className={classes.collapseItem}>
                  <NavLink
                    to="#"
                    className={classes.itemLink + " " + classes.userCollapseLinks}
                  >
                    <span className={collapseItemMini}>
                      {rtlActive ? "و" : "S"}
                    </span>
                    <ListItemText
                      primary={rtlActive ? "إعدادات" : "Settings"}
                      disableTypography={true}
                      className={collapseItemText}
                    />
                  </NavLink>
                </ListItem>
              </List>
            </Collapse>
          </ListItem>
        </List>
      </div>
    );
    // var links = <List className={classes.list}>{createLinks(routes)}</List>;
    var storeLinks = <List className={classes.list}>{createStoreLinks(storeRoutes)}</List>;
  
    const logoNormal =
      classes.logoNormal +
      " " +
      cx({
        [classes.logoNormalSidebarMini]: props.miniActive && miniActive,
        [classes.logoNormalSidebarMiniRTL]:
          rtlActive && props.miniActive && miniActive,
        [classes.logoNormalRTL]: rtlActive,
      });
    const logoMini =
      classes.logoMini +
      " " +
      cx({
        [classes.logoMiniRTL]: rtlActive,
      });
    const logoClasses =
      classes.logo +
      " " +
      cx({
        [classes.whiteAfter]: bgColor === "white",
      });
    var brand = (
      <div 
      // className={logoClasses}
      className='logoBoxSideBar'
      >
        <span
          // href="#"
          className={logoMini}
        >
          <img src={logo} alt="logo" 
          // className={classes.img} 
          className='logoSideBar'
          />
        </span>
        {/* <a
          href="#"
          className={logoNormal}
        >
          {logoText}
        </a> */}
      </div>
    );
    const drawerPaperStoreStore =
      classes.drawerPaperStore +
      " " +
      cx({
        [classes.drawerPaperStoreMini]: props.miniActive && miniActive,
        [classes.drawerPaperStoreRTL]: rtlActive,
      });
    const sidebarWrapper =
      classes.sidebarWrapper +
      " " +
      cx({
        [classes.drawerPaperStoreMini]: props.miniActive && miniActive,
        [classes.sidebarWrapperWithPerfectScrollbar]:
          navigator.platform.indexOf("Win") > -1,
      });
    return (
      <div ref={mainPanel}>
        <Hidden mdUp implementation="css">
          <Drawer
          variant="temporary"
          anchor="right"
        //   anchor="left"
          open={props.open}
          classes={{
              paper: drawerPaperStoreStore + " " + classes[bgColor + "Background"],
            }}
          onClose={props.handleStoreDrawerToggle}
        //   ModalProps={{
        //     keepMounted: true
        //   }}
          >
            <SidebarWrapper 
              className={sidebarWrapper}
              // user={user}
            //   links={links}
                storeLinks={storeLinks}
            />
          </Drawer>
        </Hidden>
      </div>
    );
  }

RightSidebar.defaultProps = {
  bgColor: "blue",
};

RightSidebar.propTypes = {
  bgColor: PropTypes.oneOf([
    "white", 
    "black", 
    "blue", 
    "green",
    "#28B873", 
    "red",
    "#28B873",
  ]),
  rtlActive: PropTypes.bool,
  color: PropTypes.oneOf([
    "white",
    "red",
    "orange",
    "green",
    "blue",
    "purple",
    "rose",
    "gray",
    "#013220",
  ]),
  logo: PropTypes.string,
  logoText: PropTypes.string,
  image: PropTypes.string,
  routes: PropTypes.arrayOf(PropTypes.object),
  storeRoutes: PropTypes.arrayOf(PropTypes.object),
  miniActive: PropTypes.bool,
  open: PropTypes.bool,
  handleDrawerToggle: PropTypes.func,
  handleStoreDrawerToggle: PropTypes.func,
};

SidebarWrapper.propTypes = {
  className: PropTypes.string,
  // user: PropTypes.object,
  headerLinks: PropTypes.object,
  links: PropTypes.object,
  storeLinks: PropTypes.object,
};

export default RightSidebar;
