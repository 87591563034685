import { GET_ORDER_REQUEST, GET_ORDER_SUCCESS, GET_ORDER_FAIL, CHANGE_ORDER_STATUS, NEW_ORDER, VALIDATE_ORDER, CANCEL_ORDER, FIRST_TIME } from '../constants/order.constants'
import { waitingForStore } from '../../mock'
const initialState = {
  acceptedOrders: [],
  waitingForAccept: [],
  waitingForDeliveryMan: [],
  invalidOrders: [],
  error: "",
  loading: false,
  firstTime: true
};

export default function ordersReducer(state = initialState, action) {

  let waitingForAccept = state.waitingForAccept || [];
  let acceptedOrders = state.acceptedOrders || [];
  let waitingForDeliveryMan = state.waitingForDeliveryMan || [];
  let delivredStutus = ['STARTED_DELIVERY','ARRIVED_TO_DESTINATION','DELIVERED']

  switch (action.type) {
    case CHANGE_ORDER_STATUS:
      if (action.to == 'WAITING_FOR_ACCEPT') {

        const findIndexWaitingOrders = (waitingForAccept || []).findIndex(x => x._id == action.order._id)
        findIndexWaitingOrders < 0 ? waitingForAccept.push(action.order) : waitingForAccept[findIndexWaitingOrders] = action.order
      }
      else if (action.to == 'ACCEPTED' || action.to == 'START_PREPARATION') {
        console.log('starttt');

        const findIndexWaitingOrders = (waitingForAccept || []).findIndex(x => x._id == action.order._id)
        findIndexWaitingOrders != -1 && waitingForAccept.splice(findIndexWaitingOrders, 1)
        // console.log('cond 2 index', findIndexWaitingOrders);
        const findIndexAcceptedOrders = (acceptedOrders || []).findIndex(x => x._id == action.order._id)
        findIndexAcceptedOrders < 0 ? acceptedOrders.push(action.order) : acceptedOrders[findIndexAcceptedOrders] = action.order
      }
      else if (action.to == 'END_PREPARATION') {
        const findIndexAcceptedOrders = acceptedOrders.findIndex(x => x._id == action.order._id)
        // console.log('cond 3 index', findIndexAcceptedOrders);
        findIndexAcceptedOrders != -1 && acceptedOrders.splice(findIndexAcceptedOrders, 1)
        const findIndexWaitingForDeliveryManOrders = (waitingForDeliveryMan || []).findIndex(x => x._id == action.order._id)
        console.log('in Prepration');

        if (findIndexWaitingForDeliveryManOrders < 0 && !delivredStutus.includes(action.order.deliveryManStatus)) {
          console.log('pusssh in waitingForDeliveryMan ');

          waitingForDeliveryMan.push(action.order)
        }
        else {
          if (delivredStutus.includes(action.order.deliveryManStatus) ) {
               console.log('dooooonne');

            waitingForDeliveryMan.splice(findIndexWaitingForDeliveryManOrders, 1)
          } else {
            waitingForDeliveryMan[findIndexAcceptedOrders] = action.order
          }
        }
      }
      break;
    case GET_ORDER_SUCCESS:
      (action.orders || []).forEach(order => {
        if (order.storeStatus == 'WAITING_FOR_ACCEPT') {
          waitingForAccept.push(order);
        }
        else if (order.storeStatus == 'ACCEPTED' || order.storeStatus == 'START_PREPARATION') {
          acceptedOrders.push(order);
        }
        else if (order.storeStatus == 'END_PREPARATION' && !delivredStutus.includes(order.deliveryManStatus) ) {
          waitingForDeliveryMan.push(order);

        }
      })
      break;
    case CANCEL_ORDER:
      const findIndexInOrderForCancel = waitingForAccept.findIndex(x => x._id == action.orderId)
      if (findIndexInOrderForCancel >= 0) {
        waitingForAccept.splice(findIndexInOrderForCancel, 1)
      } else {
        const findIndexAcceptedOrders = (acceptedOrders || []).findIndex(x => x._id == action.orderId)
        if (findIndexAcceptedOrders >= 0) {

          acceptedOrders.splice(findIndexAcceptedOrders, 1)
        } else {
          const findIndexEndPreparationdOrders = (waitingForDeliveryMan || []).findIndex(x => x._id == action.orderId)
          findIndexEndPreparationdOrders != -1 && waitingForDeliveryMan.splice(findIndexEndPreparationdOrders, 1)
        }
      }
      break;
    default:
      break;
  }

  switch (action.type) {
    case GET_ORDER_REQUEST:
      return {
        loading: true,
        firstTime: state?.firstTime,
      }

    case GET_ORDER_SUCCESS:
      return {
        ...state,
        loading: false,
        waitingForAccept: waitingForAccept,
        acceptedOrders: acceptedOrders,
        waitingForDeliveryMan: waitingForDeliveryMan,
        firstTime: state?.firstTime,
      };
    case GET_ORDER_FAIL:
      return {
        ...state,
        loading: false,
        error: action.error?.message || (action?.error?.errors || [{ message: "" }])[0].message,
        firstTime: state?.firstTime,
      };
    case CHANGE_ORDER_STATUS:
      return {
        ...state,
        loading: false,
        waitingForAccept: waitingForAccept,
        acceptedOrders: acceptedOrders,
        waitingForDeliveryMan: waitingForDeliveryMan,
        firstTime: state?.firstTime,

      };
    // // case NEW_ORDER:
    // //   return {
    // //     ...state,
    // //     loading: false,
    // //     waitingForAccept: waitingForAccept,
    // //     invalidOrders: invalidOrders,
    // //     firstTime: state?.firstTime,

    // //   };
    // // case VALIDATE_ORDER:
    //   return {
    //     ...state,
    //     loading: false,
    //     waitingForAccept: waitingForAccept,
    //     firstTime: state?.firstTime,

    //   };
    case CANCEL_ORDER:
      return {
        ...state,
        loading: false,
        waitingForAccept: waitingForAccept,
        firstTime: state?.firstTime,

      };
    case FIRST_TIME:
      return {
        ...state,
        loading: false,
        waitingForAccept: state?.waitingForAccept || [],
        acceptedOrders: state?.acceptedOrders || [],
        waitingForDeliveryMan: state?.waitingForDeliveryMan || [],
        firstTime: false,

      };

    default:
      return state;
  }
}