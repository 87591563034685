import axios from 'axios';
import {
  backendServerConfig,
  MICROSERVICE_BASE_URL,
} from '../../../config/backendServer.config';

export function loginApi({ email, password }) {
  const params = new URLSearchParams();
  params.append('username', email);
  params.append('password', password);
  params.append('grant_type', 'password');

  const config = {
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
      Authorization: `Basic ${backendServerConfig.grant}`,
    },
  };

  return axios.post(
    `${MICROSERVICE_BASE_URL.AUTH_SERV}/oauth/token`,
    params,
    config,
  );
}

export function registerApi({ email, password, username }) {
  const params = new URLSearchParams();
  params.append('email', email);
  params.append('password', password);
  params.append('username', username);
  params.append('entityType', 'admin');

  const config = {
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
    },
  };

  return axios.post(
    `${MICROSERVICE_BASE_URL.AUTH_SERV}/auth/register`,
    params,
    config,
  );
}

export function refreshAccessTokenApi(refreshToken) {
  const params = new URLSearchParams();
  params.append('grant_type', 'refresh_token');
  params.append('refresh_token', refreshToken);

  return axios.post(`${MICROSERVICE_BASE_URL.AUTH_SERV}/oauth/token/`, params, {
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
      Authorization: `Basic ${backendServerConfig.grant}`,
    },
  });
}
export function getStoreById(storeId){
  return axios.get(`${MICROSERVICE_BASE_URL.STORES}/store/${storeId}`)
}
