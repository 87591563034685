import {takeEvery,takeLatest,put,all,fork} from 'redux-saga/effects'
import axios from 'axios'
import {getOrderSuccessAction,getOrderFailAction} from './actions/order.actions'
import {GET_ORDER_REQUEST,GET_ORDER_SUCCESS,GET_ORDER_FAIL} from './constants/order.constants'
import {MICROSERVICE_BASE_URL} from '../../../config/backendServer.config'

const getOrdersFromAPI = ({cityId,storeId}) =>{
    const data = axios.get(
        `${MICROSERVICE_BASE_URL.DISPATCHING}/order/city/${cityId}/store/${storeId}/current-orders`
      );
      return data
}

function* getOrders(payload){
    // console.log(payload);
      try {
          const {data}=   yield getOrdersFromAPI(payload)
          // console.log(data);
          yield put(getOrderSuccessAction(data))
          
        } catch (error) {
          //   console.log('error.response.data');
          yield put(getOrderFailAction(error?.response?.data))
          
      
        }
  }

  
  export default function* watcherSage(){
      yield takeLatest(GET_ORDER_REQUEST,getOrders)
      
        
  }