import React, { useEffect, useState } from 'react';
import { Box } from '@material-ui/core';
import PropTypes from 'prop-types';
import { useAlert } from 'react-alert';
import { makeStyles } from '@material-ui/core/styles';
import styles from '../../../assets/jss/material-dashboard-pro-react/views/regularFormsStyle';

import Button from '@material-ui/core/Button';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import NativeSelect from '@material-ui/core/NativeSelect';
import {updateStore} from '../services/serverAPI.service'

const useStyles = makeStyles(styles);

export default function StoreEditPreparationComponent({ store, close }) {
  const classes = useStyles();
  const alert = useAlert();
  const [isLoading, setIsLoading] = useState(false);
  const [preparation_time, setpreparation_time] = useState([]);
  const [selected_preparation_time_min, set_selected_preparation_time_min] = useState(
    store.preparationTimeMin,
  );
  const [selected_preparation_time_max, set_selected_preparation_time_max] = useState(
    store.preparationTimeMax,
  );

  const getPrepareTimeList = () => {
    let tmp = [];
    let i = 0;
    for (i = 5; i <= 60; i += 5) {
      tmp.push(i);
    }
    return tmp;
  };
  useEffect(() => {
    setpreparation_time(getPrepareTimeList());
  }, [store]);
  const onEdit = async () => {
    //   console.log(selected_preparation_time_min);
    //   console.log(selected_preparation_time_max);
    if (parseInt(selected_preparation_time_min) <= parseInt(selected_preparation_time_max)) {
      store.preparationTimeMin = selected_preparation_time_min;
      store.preparationTimeMax = selected_preparation_time_max;
      let { _id, ...newStore } = store;
    //   console.log('ok',store );
      updateStore(_id, newStore)
        .then(({ data }) => {
            alert.show('saved', {
                type: 'success',
              });
        })
        .catch((error) => {
          console.log(error.message, {
            type: 'error',
          });
        })
        .finally(() => {
          setIsLoading(false);
          close();
        });
    }
  };

  return (
    <div>
      <Box sx={{ display: 'flex' }}>
        <FormControl className={classes.formControl + ' p-2 m-2'} fullWidth>
          <InputLabel shrink>Preparation Time Min</InputLabel>
          <NativeSelect
            value={selected_preparation_time_min}
            onChange={($event) => {
              set_selected_preparation_time_min($event.target.value);
            }}
          >
            {preparation_time.map((item) => (
              <option value={item}>{item}</option>
            ))}
          </NativeSelect>
        </FormControl>
        <FormControl className={classes.formControl + ' p-2 m-2'} fullWidth>
          <InputLabel shrink>Preparation Time Max</InputLabel>
          <NativeSelect
            value={selected_preparation_time_max}
            onChange={($event) => {
              set_selected_preparation_time_max($event.target.value);
            }}
          >
            {preparation_time.map((item) => (
              <option value={item}>{item}</option>
            ))}
          </NativeSelect>
        </FormControl>
      </Box>
      <Box textAlign="center">
        <Button variant="contained" color="success" onClick={onEdit}>
          Edit
        </Button>
      </Box>
    </div>
  );
}

StoreEditPreparationComponent.propTypes = {
  store: PropTypes.any.isRequired,
  close: PropTypes.func,
};
