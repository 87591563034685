import React, { useState, useEffect, useRef } from 'react';
import { Grid, Box, TextField, Paper, Divider } from '@material-ui/core';
import GridContainer from '../../../components/material-dashboard-pro-react/Grid/GridContainer';
import Card from '../../../components/material-dashboard-pro-react/Card/Card';
import CardHeader from '../../../components/material-dashboard-pro-react/Card/CardHeader';
import CardBody from '../../../components/material-dashboard-pro-react/Card/CardBody';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Menu from '@mui/material/Menu';
import MenuIcon from '@mui/icons-material/Menu';
import Container from '@mui/material/Container';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import Tooltip from '@mui/material/Tooltip';
import MenuItem from '@mui/material/MenuItem';
import AdbIcon from '@mui/icons-material/Adb';
import Notifications from '@material-ui/icons/Notifications';
import CardBoard from 'components/material-dashboard-pro-react/Card/CardBoard';
import { styled } from '@mui/material/styles';
// import { Discount } from '@mui/icons-material';
import { SignalCellularAlt } from '@material-ui/icons';
import PaidIcon from '@mui/icons-material/Paid';
import { BusinessCenter } from '@material-ui/icons';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { getHistoryOrders } from '../services/serverApi.service';
import { useAlert } from 'react-alert';
import { statusCode } from '../mock';
import {
  formatTimeForOrder,
  formatTime,
  calcDuration,
} from '../../../helpers/index';
import OrdersWaitForAccept from './OrdersWaitForAccept.page';
import OrderInProgress from './orderInProgress.page';
import OrderWaitingForDelivery from './orderWaitingDelivery.page';
import CircularProgress from '@mui/material/CircularProgress';


const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
  ...theme.typography.body2,
  padding: theme.spacing(2),
  // textAlign: 'center',
  color: theme.palette.text.secondary,
}));

export default function BoardPage() {
  const alert = useAlert();
  const storeId = localStorage.getItem('storeId');
  const [resultsData, setResultsData] = useState({
    items: [],
  });
  const [page, setPage] = useState(0);
  const [rowPerPage, setRowPerPage] = useState(20);
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');
  const [shortId, setShortId] = useState('');
  const [total, setTotal] = useState(0);
  const [useDate, setUseDate] = useState(false);
  // const calcPrice = (item) => {
  //   return item.reduce((accumulator, object) => {
  //     return accumulator + object.totalPrice;
  //   }, 0);
  // };

// useEffect(() => {
//   getOrdersCompleted(
//     page,
//     rowPerPage,
//     true,
//     startDate,
//     endDate,
//     useDate,
//     shortId,
//   );
// }, [])
// const getOrdersCompleted = (skip, limit, paged, start, end, useTime, id) => {
//   getHistoryOrders(skip, limit, paged, storeId, start, end, useTime, id)
//     .then(({ data }) => {
//       setTotal(data.total);
//       setResultsData({
//         // total: data.total,
//         items:
//           data?.orders &&
//           data.orders.map((item, index) => {
//             return {
//               ...item,
//               time: (
//                 <div>
//                   <span>{formatTimeForOrder(item.createdAt)}</span>
//                   <br />
//                   <span>{calcDuration(item.createdAt)} min</span>
//                 </div>
//               ),
//             };
//           }),
//       });
//     })
//     .catch((error) => {
//       error.response.status == statusCode.NOT_FOUND &&
//         alert.show('Aucune store trouvée', {
//           type: 'error',
//         });
//       setResultsData({ items: [] });
//      });
// };
  return (
    <GridContainer className="bordGlobalContainer">
      {/* <Grid container className="bordHeader">
        <span className="bordPageTitle">Tableau de Bord</span>
        <Notifications className="bordNotificationIcon" />
      </Grid>
      <Box sx={{ width: '100%' }} style={{ marginTop: '30px' }}>
        <Grid container spacing={4}>
          <Grid item xs={4}>
            <Item className="bordItemCard">
              <div className="bordInCardContainer">
                <span className="bordCardTitle">Total des Commandes</span>
                <BusinessCenter className="bordCardIcon" />
              </div>
              <div className="bordCardContainerNumber">
                <span className="bordCardNumber">{total}</span>
              </div>
            </Item>
          </Grid>
          <Grid item xs={4}>
            <Item className="bordItemCard">
              <div className="bordInCardContainer">
                <span className="bordCardTitle">Prix Total des Commandes</span>
                <PaidIcon className="bordCardIcon" />
              </div>
              <div className="bordCardContainerNumber">
                <span className="bordCardNumber">460,50 DH</span>
              </div>
            </Item>
          </Grid>
          <Grid item xs={4}>
            <Item className="bordItemCard">
              <div className="bordInCardContainer">
                <span className="bordCardTitle">Panier moyen</span>
                <ShoppingCartIcon className="bordCardIcon" /> 
              </div>
              <div className="bordCardContainerNumber">
                <span className="bordCardNumber">65 DH</span>
              </div>
            </Item>
          </Grid>
        </Grid>
      </Box> */}

      <Box sx={{ width: '100%' }} style={{ marginTop: '100px' }}>
         <Grid container spacing={4}>
          <Grid item xs={12} style={{ marginTop: '30px' }}>
           <OrdersWaitForAccept />
           </Grid>
        </Grid> 
         <Grid container spacing={4} style={{ marginTop: '50px' }}>
          <Grid item xs={12}>
           <OrderInProgress />
           </Grid>
        </Grid>  
          <Grid container spacing={4} style={{ marginTop: '50px' }}>
          <Grid item xs={12}>
           <OrderWaitingForDelivery />
           </Grid>
        </Grid>  
      </Box> 
      {/* <Card>
        <CardHeader className="bordCardTableHeader">
          <div className="bordTableTitleDivider">
            <span className="bordTableTitle">Les dernières commandes</span>
            <Divider className="lineTitle" />
           
          </div>
        </CardHeader>
        <CardBody className="bordCardBody">
          
          <Table
            sx={{ minWidth: 650 }}
            aria-label="simple table"
            style={{
              border: 'none',
              borderCollapse: 'separate',
              borderSpacing: '0px 10px',
            }}
          >
            <TableHead>
              <TableRow>
                <TableCell className="bordTableCell" align="center" style={{width:200}}>
                  Date
                </TableCell>
                <TableCell
                
                  className="bordTableCell"
                  style={
                    {
                      width:200,
                      // borderBottom: 'none',
                    }
                  }
                  align="center"
                >
                  Transaction ID
                </TableCell>
                <TableCell className="bordTableCell" align="center" style={{width:200}}>
                  Paiement
                </TableCell>
                <TableCell className="bordTableCell" align="center" style={{width:200}}>
                  Valeur
                </TableCell>
                <TableCell className="bordTableCell" align="center" style={{width:200}}>
                  Status
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
            {resultsData.items &&
                resultsData.items.length > 0 &&
                resultsData.items.map((order, index) => (
                  <TableRow
                    className="rowTableCellWait"
                    key={order.shortId}
                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                    style={{
                      backgroundColor:
                        order.customerStatus == 'CANCELED'
                          ? '#ffefea'
                          : '#d2f8d2',
                      height: '80px',
                    }}
                  >
                    <TableCell align="center" className="bordTableCell">
                    {formatTime(order.createdAt)}
                    </TableCell>
                    <TableCell align="center" className="bordTableCell">
                    {order.shortId}
                    </TableCell>
                    <TableCell align="center" className="bordTableCell">
                    {order.payment.paymentGateway == 'Cash'
                        ? 'Cash'
                        : 'Carte'}
                    </TableCell>
                    <TableCell align="center" className="bordTableCell">
                    {order.payment.orderPrice}
                     
                    </TableCell>
                    <TableCell
                      align="center"
                      className="bordTableCellStat"
                      style={{
                        color:
                          order.customerStatus == 'CANCELED' ? 'red' : 'green',
                        fontWeight: 'bold',
                      }}
                    >
                      {order.customerStatus == 'CANCELED' ? 'Annulée' : 'Livré'}
                    </TableCell>
                  </TableRow>
                   ))}
            </TableBody>
          </Table>
        </CardBody>
      </Card> */}
    </GridContainer>
  );
}
