import { GET_ORDER_REQUEST, GET_ORDER_SUCCESS, GET_ORDER_FAIL, CHANGE_ORDER_STATUS, NEW_ORDER, VALIDATE_ORDER, CANCEL_ORDER, FIRST_TIME } from '../constants/order.constants'

export const getOrderRequestAction = (cityId, storeId) => ({
    type: GET_ORDER_REQUEST,
    cityId,
    storeId
})
export const getOrderSuccessAction = (orders) => ({
    type: GET_ORDER_SUCCESS,
    orders
})
export const getOrderFailAction = (error) => ({
    type: GET_ORDER_FAIL,
    error
})
export const changeOrderStatusAction = (order, to) => ({
    type: CHANGE_ORDER_STATUS,
    order,
    to
})
export const newOrderAction = (order) => ({
    type: NEW_ORDER,
    order,
})
export const validateOrder = (order) => ({
    type: VALIDATE_ORDER,
    order,
})
export const cancelOrder = (orderId) => ({
    type: CANCEL_ORDER,
    orderId,
})
export const firstTimeAction = () => ({
    type: FIRST_TIME,
})