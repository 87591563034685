import React,{useState,useEffect} from 'react'
import PropTypes from 'prop-types'
import { useSelector, useDispatch } from 'react-redux';
import {useAlert} from 'react-alert'
import {useFormik} from 'formik'
import * as yup from 'yup'
import {Box} from '@material-ui/core'
import Switch from '@material-ui/core/Switch';
import Button from '../../../components/material-dashboard-pro-react/CustomButtons/Button'
import CustomInput from '../../../components/material-dashboard-pro-react/CustomInput/CustomInput'
import {defaultImagesAction} from '../../../redux/uploadRedux/upload.action'
import SwitchComponent from '../../../components/Switch.component'
import ImageUpload from '../../../components/material-dashboard-pro-react/CustomUpload/ImageUpload';
import {updateStockInCategoryList} from '../services/serverAPI.service'

const validationSchema = yup.object({
    name: yup.string().required(),
    description: yup.string().required(),
    storeId: yup.string().required(),
    inStock: yup.bool().required(),
    imageUrl: yup.string(),
  });

export default function EditeCategoryListComponent({data,close,onItemEdited}) {
    const [isLoading, setIsLoading] = useState(false);
    const [displayImage, setDisplayImage] = useState(false);
    const dispatch = useDispatch();
    const alert = useAlert();
    const upload = useSelector((state) => state.uploadReducer)
  const { iconUrl, error, loading } = upload
    const storeId = localStorage.getItem('storeId')
    // console.log(data);
    // console.log(data?.name?.props?.children);
    const formik = useFormik({
        initialValues: {
            name: '',
            description: '',
            storeId: '',
            inStock: '',
            imageUrl: '',
            _id:''
        },
        validationSchema,
      });
      const onCreate = ()=>{
        setIsLoading(true)
        updateStockInCategoryList(formik.values)
        .then(() => {
            alert.show('category list updated', {
                type: 'success',
            });
            formik.resetForm();
            setDisplayImage(false);
            close()
            onItemEdited();
        })
        .catch((error) => {
            alert.show(error.message, {
                type: 'error',
            });
        })
        .finally(() => {
            setIsLoading(false)
          })
      }
      const setValue=()=>{
        if (data) {
    
          !iconUrl&& dispatch(defaultImagesAction(data.imageUrl.props.src))
          
            formik.setValues({
            name: data.name,
            _id: data._id,
            description: data.description,
            imageUrl:iconUrl,
            inStock:data.inStock.props.checked,
            storeId
          })}
        
      }
      useEffect(() => {
        setValue()
      },[data,iconUrl])
    return (
        <div>
            <div>{!displayImage && <span>you need to upload a new image</span>}
          <Switch
            color='primary'
            checked={displayImage}
            value={displayImage}
            onChange={e => { displayImage ? setDisplayImage(false) : setDisplayImage(true) }}
          />
        </div>
      <Box display="flex" justifyContent="center">
        {Boolean(data) && (
          !displayImage ?

            <img src={data.imageUrl.props.src} alt="dqs" height="100" width="120" />

            : <ImageUpload
              // defaultImage={data.iconUrl}
              // onSubmit={onUploadMedia}
              // onRemove={onRemoveImage}
              label="Select icon"
              avatar
              folder="service"
            />
        )}
      </Box>
            <CustomInput
            labelText="Name"
            formControlProps={{
              fullWidth: true,
            }}
            type="text"
            error={formik.touched.name && Boolean(formik.errors.name)}
            inputProps={{
              value: formik.values.name,
              onBlur: formik.handleBlur('name'),
              onChange: formik.handleChange('name'),
            }}
          />
            <CustomInput
            labelText="Description"
            formControlProps={{
              fullWidth: true,
            }}
            type="text"
            error={formik.touched.description && Boolean(formik.errors.description)}
            inputProps={{
              value: formik.values.description,
              onBlur: formik.handleBlur('description'),
              onChange: formik.handleChange('description'),
            }}
          />
          <SwitchComponent
        onChange={(value) => formik.setFieldValue('inStock', value)}
        checked={formik.values.inStock}
        error={formik.touched.inStock && Boolean(formik.errors.inStock)}
        label="inStock"
      />
      <Box textAlign="center">
        <Button
          round
          type="submit"
          color="success"
          disabled={!formik.dirty || !formik.isValid || isLoading}
          loading={isLoading}
          loaderColor="white"
          onClick={onCreate}
        >
          Edit
        </Button>
      </Box>
        </div>
    )
}

EditeCategoryListComponent.propTypes = {
    data: PropTypes.any,
    close: PropTypes.any,
    onItemEdited: PropTypes.func.isRequired,
}
