import {takeLatest,put} from 'redux-saga/effects'
import {getStoreById} from '../../../features/auth/services/serverApi.service';
import { GET_STORE_REQUEST } from './Constants/store.config.constants'
import { getStoreSuccessAction,getStoreFailAction } from './Actions/store.config.actions'

function* getStore({storeId}){
    console.log(storeId);
    try {
        const {data} = yield getStoreById(storeId)
        console.log(data);
        yield put(getStoreSuccessAction(data))
    } catch (error) {
        console.log(error);
        yield put(getStoreFailAction(error?.response?.data))
    }
}

export default function* watcherSage(){
    yield takeLatest(GET_STORE_REQUEST,getStore)
}